import {Component, OnInit} from '@angular/core';
import {SessionManagerService} from "../../core/services/session-manager.service";
import {AccountService} from "../../core/services/account.service";
import {Account} from "../../core/models/account.model";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {LocationsService} from "../../core/services/locations.service";
import {Subscription} from "rxjs";
import {State} from "../../core/models/state.model";
import {City} from "../../core/models/city.model";
import {Neighborhood} from "../../core/models/neighborhood.model";
import {CompanyService} from "../../core/services/company.service";
import {ProductType} from "../../core/models/product-type.model";

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit {
  formGroup: FormGroup = this.fb.group({
    id: [],
    accepted_property_types: [[]],
    average_architect_ticket: [],
    average_customer_ticket: [],
    customer_required_quality_standard: [[]],
    accepts_external_technical_retention: [false],
    maximum_external_technical_retention_percentage: [],
    countries: this.fb.array([]),
    states: this.fb.array([]),
    cities: this.fb.array([]),
    neighborhoods: this.fb.array([]),
  });

  locationFormGroup: FormGroup = this.fb.group({
    country: [1],
    state: [],
    city: [],
    neighborhood: [],
  });

  account?: Account;

  fetchingAccount: boolean = false;
  fetchingStates: boolean = false;
  fetchingCities: boolean = false;
  fetchingNeighborhoods: boolean = false;
  fetchingCategories: boolean = false;
  submittingAccount: boolean = false;
  submittingCategories: boolean[] = [];

  countries = [{id: 1, name: 'Brasil'}];
  states: State[] = [];
  cities: City[] = [];
  neighborhoods: Neighborhood[] = [];
  productTypes: ProductType[] = [];

  constructor(private fb: FormBuilder, protected sessionManagerService: SessionManagerService,
              private accountService: AccountService, private locationsService: LocationsService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this.account = this.sessionManagerService.getSessionData();
    this.fetchAccount();
    this.fetchStates();
    this.fetchCategories();
  }

  get acceptsTechnicalRetention(): boolean {
    return this.formGroup.get('accepts_external_technical_retention')?.value as boolean;
  }

  get countriesFormArray(): FormArray {
    return this.formGroup.get('countries') as FormArray;
  }

  get statesFormArray(): FormArray {
    return this.formGroup.get('states') as FormArray;
  }

  get citiesFormArray(): FormArray {
    return this.formGroup.get('cities') as FormArray;
  }

  get neighborhoodsFormArray(): FormArray {
    return this.formGroup.get('neighborhoods') as FormArray;
  }

  fetchAccount() {
    this.fetchingAccount = true;

    this.accountService.get(this.account!.id!).subscribe({
      next: response => {
        this.account = response;
        this.sessionManagerService.setSessionData(this.account);
        if (this.account.company) {
          this.formGroup.patchValue(this.account.company);
        }
        this.fillLocations();
        this.fetchingAccount = false;
      }, error: error => {
        console.error(error);
        this.fetchingAccount = false;
      }
    });
  }

  fetchStates(): void {
    this.fetchingStates = true;
    this.locationsService.listStates().subscribe({
      next: response => {
        this.states = response;
        this.fetchingStates = false;
      }, error: error => {
        console.error(error);
        this.fetchingStates = false;
      }
    });
  }

  fetchCities(): void {
    this.cities = [];
    this.neighborhoods = [];
    this.locationFormGroup.patchValue({city: null, neighborhood: null});
    this.fetchingCities = true;
    if (!this.locationFormGroup.get('state')?.value) {
      this.cities = [];
      this.neighborhoods = [];
      return;
    }
    this.locationsService.listCities(this.locationFormGroup.get('state')?.value).subscribe({
      next: response => {
        this.cities = response;
        this.neighborhoods = [];
        this.fetchingCities = false;
      }, error: error => {
        console.error(error);
        this.fetchingCities = false;
      }
    });
  }

  fetchNeighborhoods(): void {
    this.neighborhoods = [];
    this.locationFormGroup.patchValue({neighborhood: null});
    this.fetchingNeighborhoods = true;
    if (!this.locationFormGroup.get('city')?.value) {
      this.neighborhoods = [];
      return;
    }
    this.locationsService.listNeighborhoods(this.locationFormGroup.get('city')?.value).subscribe({
      next: response => {
        this.neighborhoods = response;
        this.fetchingNeighborhoods = false;
      }, error: error => {
        console.error(error);
        this.fetchingNeighborhoods = false;
      }
    });
  }

  fetchCategories(): void {
    this.fetchingCategories = true;
    this.companyService.listProductTypes(this.account!.company!.id!).subscribe({
      next: response => {
        this.fetchingCategories = false;
        this.productTypes = response.map(_ => {
          if (!_.minimum_value_estimated) {
            _.minimum_value_estimated = 0;
          }
          if (!_.maximum_value_estimated) {
            _.maximum_value_estimated = 0;
          }

          return _;
        });
      }, error: error => {
        this.fetchingCategories = false;
      }
    });
  }

  fillLocations(): void {
    this.account?.company?.countries?.forEach(country => this.addCountry());
    this.account?.company?.states?.forEach(state => this.addState(state));
    this.account?.company?.cities?.forEach(city => this.addCity(city));
    this.account?.company?.neighborhoods?.forEach(neighborhood => this.addNeighborhood(neighborhood));
  }

  isCheckboxSelected(formControlName: string, value: string) {
    let control = this.formGroup.get(formControlName);
    let selected = control?.value as string[] ?? [];
    return selected.includes(value);
  }

  onSelectCheckbox(formControlName: string, value: string) {
    let control = this.formGroup.get(formControlName);
    let selected = control?.value as string[] ?? [];

    if (selected.includes(value)) {
      selected = selected.filter(_ => _ != value);
    } else {
      selected.push(value);
    }

    control?.setValue(selected);
  }

  submit() {
    if (this.submittingAccount || !this.formGroup.valid) {
      return;
    }

    this.submittingAccount = true;

    this.account!.company = this.formGroup.getRawValue();
    let data = {id: this.account!.id, company: this.formGroup.getRawValue()};

    this.accountService.update(data).subscribe({
      next: response => {
        this.submittingAccount = false;
      }, error: error => {
        this.submittingAccount = false;
        console.error(error);
      }
    });
  }

  submitCategories(): void {
    for (let productType of this.productTypes) {
      this.submittingCategories.push(true);
      this.companyService.updateProductTypes(this.account!.company!.id!, productType).subscribe({
        next: response => {
          let index = this.submittingCategories.indexOf(true);
          this.submittingCategories.splice(index, 1);
        }, error: error => {
          let index = this.submittingCategories.indexOf(true);
          this.submittingCategories.splice(index, 1);
        }
      });
    }
  }

  addLocation(): void {
    let data = this.locationFormGroup.getRawValue();

    let neighborhood = this.neighborhoods.find(_ => _.id == data.neighborhood);
    let city = this.cities.find(_ => _.id == data.city);
    let state = this.states.find(_ => _.id == data.state);

    if (neighborhood) {
      neighborhood!.city_name = city!.name
      neighborhood!.city_id = city!.id;
      neighborhood!.state_id = state!.id;
      neighborhood!.state_name = state!.name;
      neighborhood!.state_federative_unit = state!.federative_unit;
      this.addNeighborhood(neighborhood);
    } else if (city) {
      console.log(state);
      city!.state_id = state!.id;
      city!.state_name = state!.name;
      city!.state_federative_unit = state!.federative_unit;
      this.addCity(city);
    } else if (state) {
      this.addState(state);
    } else {
      this.addCountry();
    }
  }

  addCountry(): void {
    let control = this.fb.group({
      name: ['Brasil'],
      id: [1]
    });
    this.countriesFormArray.push(control);
  }

  removeCountry(index: number): void {
    this.countriesFormArray.removeAt(index);
  }

  addState(state: State): void {
    if (!this.canAddState(state)) {
      return;
    }
    let control = this.fb.group({
      id: [state.id],
      name: [state.name],
      federative_unit: [state.federative_unit],
    });
    this.statesFormArray.push(control);
    this.rearrangeLocations();
  }

  removeState(index: number): void {
    this.statesFormArray.removeAt(index);
  }

  addCity(city: City): void {
    if (!this.canAddCity(city)) {
      return;
    }
    let control = this.fb.group({
      id: [city.id],
      name: [city.name],
      state_id: [city.state_id],
      state_name: [city.state_name],
      state_federative_unit: [city.state_federative_unit],
    });
    this.citiesFormArray.push(control);
    this.rearrangeLocations();
  }

  removeCity(index: number): void {
    this.citiesFormArray.removeAt(index);
  }

  canAddState(state: State) {
    let data = this.formGroup.getRawValue();
    let hasCountry = data.countries.length > 0;
    let hasState = data.states.some((_: State) => _.id == state.id);

    return !hasCountry && !hasState;
  }

  canAddCity(city: City) {
    let data = this.formGroup.getRawValue();
    let hasCountry = data.countries.length > 0;
    let hasState = data.states.some((_: State) => _.id == city.state_id);
    let hasCity = data.cities.some((_: City) => _.id == city.id);

    return !hasCountry && !hasState && !hasCity;
  }

  canAddNeighborhood(neighborhood: Neighborhood) {
    let data = this.formGroup.getRawValue();
    let hasCountry = data.countries.length > 0;
    let hasState = data.states.some((_: State) => _.id == neighborhood.state_id);
    let hasCity = data.cities.some((_: City) => _.id == neighborhood.city_id);
    let hasNeighborhood = data.neighborhoods.some((_: Neighborhood) => _.id == neighborhood.id);

    return !hasCountry && !hasState && !hasCity && !hasNeighborhood;
  }

  addNeighborhood(neighborhood: Neighborhood): void {
    if (!this.canAddNeighborhood(neighborhood)) {
      return;
    }
    let control = this.fb.group({
      id: [neighborhood.id],
      name: [neighborhood.name],
      city_name: [neighborhood.city_name],
      city_id: [neighborhood.city_id],
      state_id: [neighborhood.state_id],
      state_name: [neighborhood.state_name],
      state_federative_unit: [neighborhood.state_federative_unit]
    });
    this.neighborhoodsFormArray.push(control);
    this.rearrangeLocations();
  }

  removeNeighborhood(index: number): void {
    this.neighborhoodsFormArray.removeAt(index);
  }

  rearrangeLocations(): void {
    let data = this.formGroup.getRawValue();
    let countryIds = data.countries.map((country: { id: number }) => country.id);
    let stateIds = data.states.map((state: State) => state.id);
    let cityIds = data.cities.map((city: City) => city.id);

    let indexesToBeRemoved: number[] = [];

    data.neighborhoods.forEach((neighborhood: Neighborhood, index: number) => {
      if (cityIds.includes(neighborhood.city_id)) {
        indexesToBeRemoved.push(index);
      }
      if (stateIds.includes(neighborhood.state_id)) {
        indexesToBeRemoved.push(index);
      }
      if (countryIds.length > 0) {
        indexesToBeRemoved.push(index);
      }
    });

    indexesToBeRemoved = indexesToBeRemoved.sort((a, b) => b - a);
    indexesToBeRemoved.forEach(index => this.removeNeighborhood(index));

    indexesToBeRemoved = [];

    data.cities.forEach((city: City, index: number) => {
      if (stateIds.includes(city.state_id)) {
        indexesToBeRemoved.push(index);
      }

      if (countryIds.length > 0) {
        indexesToBeRemoved.push(index);
      }
    });

    indexesToBeRemoved = indexesToBeRemoved.sort((a, b) => b - a);
    indexesToBeRemoved.forEach(index => this.removeCity(index));

    indexesToBeRemoved = [];

    data.states.forEach((state: State, index: number) => {
      if (countryIds.length > 0) {
        indexesToBeRemoved.push(index);
      }
    });

    indexesToBeRemoved = indexesToBeRemoved.sort((a, b) => b - a);
    indexesToBeRemoved.forEach(index => this.removeState(index));
  }

  setProductTypeMinimumValue(productType: ProductType, ev: any) {
    productType.minimum_value_estimated = ev.target.value;
  }

  setProductTypeMaximumValue(productType: ProductType, ev: any) {
    productType.maximum_value_estimated = ev.target.value;
  }

  createSliderOptions(productType: ProductType) {
    return {
      floor: productType.minimum_value,
      ceil: productType.maximum_value,
      step: 500,
      translate: (value: number) => value.toLocaleString(),
    };
  }
}
