import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Account } from '../../core/models/account.model';
import { AccountService } from '../../core/services/account.service';
import { SessionManagerService } from '../../core/services/session-manager.service';
import { BottomSheetComponent } from '../../components/bottom-sheet/bottom-sheet.component';
import { NgToastService } from 'ng-angular-popup';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  @Input() appNavigation?: NavigationComponent;
  @Input() loginComponent?: BottomSheetComponent;
  @Input() registerComponent?: BottomSheetComponent;

  architectFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: [''],
  });
  companyFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: ['', [Validators.required]],
    document_number: ['', [Validators.required]],
  });
  registerFormGroup: FormGroup = this.fb.group({
    role: ['USER', [Validators.required]],
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone_number: ['', [Validators.required, Validators.minLength(13)]],
    password: ['', [Validators.required]],
    confirm_password: ['', [Validators.required]],
  });

  loading = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastService: NgToastService,
    private sessionManager: SessionManagerService,
    private bottomSheet: BottomSheetComponent,
    private router: Router
  ) {}

  get isArchitect(): boolean {
    return this.registerFormGroup.get('role')?.value == 'ARCHITECT';
  }

  get isCompany(): boolean {
    return this.registerFormGroup.get('role')?.value == 'COMPANY';
  }

  get isCustomer(): boolean {
    return this.registerFormGroup.get('role')?.value == 'CUSTOMER';
  }

  checkPassword() {
    let passwordControl = this.registerFormGroup.get('password');
    let passwordConfirmControl = this.registerFormGroup.get('confirm_password');

    if (passwordConfirmControl?.value != passwordControl?.value)
      passwordConfirmControl?.setErrors({ differentPassword: true });
  }

  submit() {
    this.companyFormGroup.clearValidators();
    this.architectFormGroup.clearValidators();
    this.registerFormGroup.clearValidators();
    this.checkPassword();
    this.registerFormGroup.markAllAsTouched();
    if (this.isArchitect) this.architectFormGroup.markAllAsTouched();
    if (this.isCompany) this.companyFormGroup.markAllAsTouched();
    if (this.registerFormGroup.invalid) return;
    if (this.isArchitect && this.architectFormGroup.invalid) return;
    if (this.isCompany && this.companyFormGroup.invalid) return;

    let data: Account = this.registerFormGroup.getRawValue();
    if (this.isArchitect)
      data.architect = this.architectFormGroup.getRawValue();
    if (this.isCompany) data.company = this.companyFormGroup.getRawValue();
    this.loading = true;
    this.accountService.register(data).subscribe({
      next: (response) => {
        this.loading = false;
        this.sessionManager.setSessionData(response);
        this.registerFormGroup.reset();
        this.bottomSheet.close();

        if (this.sessionManager.isArchitect || this.sessionManager.isCustomer) {
          this.router.navigate(['/d/criar-orcamento/tipo-de-execucao']);
        }
      },
      error: (error) => {
        this.loading = false;
        if ('detail' in error.error) {
          this.toastService.error({
            detail: 'Erro!',
            summary: error.error.detail,
          });
        } else {
          for (let key of Object.keys(error.error)) {
            this.registerFormGroup
              .get(key)
              ?.setErrors({ response: error.error[key] });
          }
        }
      },
    });
  }

  closeAndGoToLogin() {
    this.appNavigation?.registerComponent.close();
    this.registerComponent?.close();
    this.appNavigation?.loginComponent.open();
    this.loginComponent?.open();
  }
}
