import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductImport } from '../models/product-import.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  importCsv(file: File): Observable<{ id: number }> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<{ id: number }>(
      `${environment.API_URL}/products_import/`,
      formData
    );
  }

  getImportResult(id: number): Observable<{ id: number; status: string }> {
    return this.http.get<{ id: number; status: string }>(
      `${environment.API_URL}/products_import/${id}/`
    );
  }

  getImportErrors(companyId: number): Observable<ProductImport[]> {
    return this.http.get<ProductImport[]>(
      `${environment.API_URL}/companies/${companyId}/product_imports/`
    );
  }
}
