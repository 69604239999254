import { Component } from '@angular/core';

@Component({
  selector: 'app-bmg',
  templateUrl: './bmg.component.html',
  styleUrls: ['./bmg.component.scss']
})
export class BmgComponent {

}
