<ng-container>
  <div class="overlay">
    <div class="wrapper">
      <div
        class="flex opacity-0 md:opacity-100 justify-center items-center text-white gap-6">
        <!--        <button class="rounded-icon bg-raven" [disabled]="false">-->
        <!--          <i class="icon icon-caret-left"></i>-->
        <!--        </button>-->

        <!--        <p class="text-[2.4rem]">1 de 7</p>-->

        <!--        <button class="rounded-icon bg-raven">-->
        <!--          <i class="icon icon-caret-right"></i>-->
        <!--        </button>-->
      </div>

      <div class="budget flex flex-col bg-white h-full">
        <div class="border-b border-athens-gray">
          <div class="container mx-auto flex flex-col gap-3">
            <div class="flex justify-around md:justify-between">
              <h2 class="budget__title" *ngIf="activeTab == 'products'">
                Configurar produtos
              </h2>
              <h2 class="budget__title" *ngIf="activeTab == 'rooms'">
                Configurar execuções dos ambientes
              </h2>

              <div
                class="max-md:fixed max-md:bottom-0 max-md:left-0 max-md:w-full max-md:h-40 max-md:z-10 max-md:bg-white max-md:p-10 max-md:shadow flex gap-3">
                <button
                  class="max-md:w-full button border border-cadet-blue text-raven"
                  (click)="closeModal()">
                  Fechar
                </button>
                <button
                  class="max-md:w-full button bg-prairie-sand text-white"
                  (click)="goAhead()">
                  <span *ngIf="activeTab == 'rooms'">Ir para produtos</span>
                  <span *ngIf="activeTab == 'products'"
                    >Finalizar ambiente</span
                  >
                </button>
              </div>
            </div>

            <div class="flex gap-3 flex-wrap justify-center md:justify-start">
              <ng-container *ngFor="let budgetRoom of budgetRooms">
                <span
                  class="tag"
                  [class.active]="isBudgetRoomSelected(budgetRoom)"
                  [class.error]="!budgetRoom.square_meter"
                  (click)="scrollToBudgetRoom(budgetRoom)">
                  <i
                    class="icon icon-alert-rounded"
                    *ngIf="!budgetRoom.square_meter"></i>
                  {{ budgetRoom.name }}
                </span>
              </ng-container>
            </div>

            <div class="tabs flex justify-center">
              <button
                class="tabs__tab"
                [class.active]="activeTab == 'rooms'"
                (click)="activeTab = 'rooms'">
                Ambientes
              </button>
              <button
                class="tabs__tab"
                [class.active]="activeTab == 'products'"
                (click)="activeTab = 'products'">
                Produtos
              </button>
            </div>
          </div>
        </div>

        <div
          class="bg-alabaster h-full overflow-y-scroll pb-40 md:pb-20"
          #budgetRoomsContainer
          id="budget-room-container"
          (scroll)="onScroll($event)">
          <div
            class="container mx-auto flex flex-col gap-10"
            *ngIf="activeTab == 'rooms'">
            <div
              class="budget-room"
              *ngFor="let budgetRoom of budgetRooms"
              [id]="'budget-room-' + budgetRoom.id">
              <div class="flex gap-5 items-center">
                <h2 class="budget-room__title">{{ budgetRoom.name }}</h2>

                <button class="bullet" (click)="openModalRoomName(budgetRoom)">
                  <i class="icon icon-edit"></i>
                </button>

                <button class="bullet" (click)="confirmRemoval(budgetRoom)">
                  <i class="icon icon-trash"></i>
                </button>
              </div>

              <div class="flex flex-col gap-3">
                <div class="form">
                  <div
                    class="form-group error"
                    [class.error]="!budgetRoom.square_meter">
                    <label for=""
                      >Qual a metragem aproximada deste ambiente? (m²)</label
                    >
                    <input
                      class="min-content"
                      type="text"
                      [(ngModel)]="budgetRoom!.square_meter"
                      mask="separator.2"
                      thousandSeparator="."
                      decimalMarker=","
                      (change)="editRoomSquareMeter(budgetRoom)" />
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div
                  class="budget-room-item"
                  *ngFor="let budgetRoomItem of budgetRoom.items">
                  <h3 class="budget-room-item__title">
                    {{ budgetRoomItem.name }}
                  </h3>

                  <div class="flex justify-between gap-5">
                    <div
                      class="budget-room-item__action"
                      (click)="toggleRemoval(budgetRoom, budgetRoomItem)">
                      <p>Remover</p>
                      <span
                        class="toggle-switch"
                        [class.active]="hasRemoval(budgetRoomItem)"></span>
                    </div>

                    <div
                      class="budget-room-item__action"
                      (click)="toggleInstall(budgetRoom, budgetRoomItem)">
                      <p>Instalar</p>
                      <span
                        class="toggle-switch"
                        [class.active]="hasInstall(budgetRoomItem)"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="container-fluid flex flex-col gap-10"
            *ngIf="activeTab == 'products'">
            <div class="budget-room" *ngIf="!isLoading; else loadingTemplate">
              <div class="flex justify-end">
                <div
                  class="w-full md:w-5/12 lg:w-5/12 flex flex-col gap-10 px-10 pt-10">
                  <div
                    class="flex flex-col gap-10"
                    *ngFor="let budgetRoom of budgetRooms"
                    [id]="'budget-room-' + budgetRoom.id">
                    <div class="flex">
                      <h2 class="budget-room__title">{{ budgetRoom.name }}</h2>
                    </div>

                    <div
                      class="budget-room-item"
                      *ngFor="
                        let productType of budgetRoom.product_types;
                        let index = index
                      "
                      id="budget-room-item-{{ budgetRoom.id }}-{{ index }}">
                      <div
                        class="cursor-pointer flex items-center justify-between"
                        (click)="
                          setSelectedProductType(budgetRoom, productType, index)
                        ">
                        <div class="flex flex-col">
                          <h3 class="budget-room-item__title">
                            {{ productType.name }}
                          </h3>
                          <!--                          <h4 class="budget-room-item__subtitle" *ngIf="budgetRoom.room__name == 'Sala'">Móveis e painéis em granito, mármore etc</h4>-->
                          <h4 class="budget-room-item__subtitle">
                            {{ productType.description }}
                          </h4>
                        </div>

                        <i
                          class="icon icon-chevron-up text-[2.4rem] transition-all"
                          [class.rotate-180]="
                            !isSelectedProductType(budgetRoom, productType)
                          "></i>
                      </div>

                      <div
                        class="flex flex-col gap-6 overflow-hidden transition-all"
                        [class.max-h-[1000vh]]="
                          isSelectedProductType(budgetRoom, productType)
                        "
                        [class.max-h-[0vh]]="
                          !isSelectedProductType(budgetRoom, productType)
                        ">
                        <div
                          class="budget-room-item__box"
                          [class.budget-room-item__box--filled]="
                            product.minimum_value_estimated ||
                            product.maximum_value_estimated
                          "
                          *ngFor="let product of productType.products">
                          <div class="flex flex-col w-7/12">
                            <p class="uppercase w-full">{{ product.name }}</p>
                            <p
                              *ngIf="product.minimum_value_suggested"
                              class="w-full text-[1rem]">
                              Mínimo sugerido
                              <span class="whitespace-nowrap">
                                {{
                                  product.minimum_value_suggested
                                    | currency : "R$"
                                }}
                                <span *ngIf="product.unit_of_measurement">
                                  /
                                  {{
                                    convertUnitOfMeasurement(
                                      product.unit_of_measurement
                                    )
                                  }}
                                </span>
                              </span>
                            </p>
                          </div>

                          <div class="flex form gap-3 w-5/12">
                            <div class="form-group form-group--small">
                              <label for="" class="text-[1.2rem]">Mínimo</label>
                              <input
                                type="text"
                                [(ngModel)]="product.minimum_value_estimated"
                                mask="separator.2"
                                thousandSeparator="."
                                decimalMarker=","
                                (change)="
                                  updateProductEstimatedPrice(
                                    budgetRoom,
                                    product
                                  )
                                " />
                            </div>

                            <div class="form-group form-group--small">
                              <label for="">Máximo</label>
                              <input
                                type="text"
                                [(ngModel)]="product.maximum_value_estimated"
                                mask="separator.2"
                                thousandSeparator="."
                                decimalMarker=","
                                (change)="
                                  updateProductEstimatedPrice(
                                    budgetRoom,
                                    product
                                  )
                                " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="products-bar">
                  <ng-container
                    *ngFor="let product of selectedProductType?.products">
                    <div
                      class="flex flex-col gap-3"
                      *ngIf="nIsVisible(product)">
                      <h3 class="budget-room-item__title">
                        {{ product.name }}
                      </h3>

                      <h4 class="budget-room-item__subtitle">
                        <span
                          *ngIf="
                            product.minimum_value_estimated &&
                            !product.maximum_value_estimated
                          "
                          >A partir de R$
                          {{ product.minimum_value_estimated }}</span
                        >
                        <span
                          *ngIf="
                            !product.minimum_value_estimated &&
                            product.maximum_value_estimated
                          "
                          >Até R$ {{ product.maximum_value_estimated }}</span
                        >
                        <span
                          *ngIf="
                            product.minimum_value_estimated &&
                            product.maximum_value_estimated
                          ">
                          {{
                            product.minimum_value_estimated | currency : "R$"
                          }}
                          -
                          {{
                            product.maximum_value_estimated | currency : "R$"
                          }}
                        </span>
                      </h4>

                      <div class="w-full overflow-x-scroll">
                        <div class="w-fit flex flex-nowrap gap-3">
                          <p
                            *ngIf="product.options?.length == 0"
                            class="text-[1.4rem] text-raven font-light">
                            Não encontramos produtos nessa faixa de preço
                          </p>

                          <div
                            class="product"
                            *ngFor="let option of product.options">
                            <a
                              *ngIf="option.origin_url"
                              [href]="option.origin_url"
                              target="_blank">
                              <img
                                class="w-full"
                                [src]="option.file_url"
                                [alt]="option.name" />
                            </a>

                            <img
                              *ngIf="!option.origin_url"
                              class="w-full"
                              [src]="option.file_url"
                              [alt]="option.name" />

                            <p class="product__name">{{ option.name }}</p>

                            <div class="flex justify-between items-center">
                              <a
                                [href]="option.origin_url"
                                target="_blank"
                                *ngIf="option.origin_url"
                                ><p class="product__store-name">
                                  {{ option.store_name }}
                                </p></a
                              >
                              <p
                                *ngIf="option.store_name && !option.origin_url"
                                class="product__store-name">
                                {{ option.store_name }}
                              </p>
                              <p class="product__store-price">
                                {{ option.value | currency : "R$" }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="border-b border-porcelain"></div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-confirmation-modal #confirmationModal>
  <h4 class="modal__title">Atenção</h4>
  <p class="modal__content text-left">
    Tem certeza que deseja remover este ambiente da estimativa?
  </p>

  <div class="modal__controls">
    <button
      class="button bg-schooner text-white text-[1.4rem]"
      (click)="confirmationModal.close()">
      Não
    </button>
    <button
      class="button bg-prairie-sand text-white text-[1.4rem]"
      (click)="removeBudgetItem()">
      Sim
    </button>
  </div>
</app-confirmation-modal>

<app-confirmation-modal #editRoomNameModal>
  <div class="flex flex-col items-start gap-10 w-full" *ngIf="selectedRoom">
    <h3 class="text-[2rem] font-semibold">Nomear ambiente</h3>

    <div class="form w-full">
      <div class="form-group" [class.error]="!budgetRoomNameInput.value">
        <label for="name" class="font-medium text-left">Nome do projeto</label>
        <input
          type="text"
          id="name"
          #budgetRoomNameInput
          placeholder="ex: Casa 01"
          [(ngModel)]="selectedRoom.name" />
      </div>
    </div>

    <button
      class="button bg-prairie-sand text-white text-[1.6rem] w-full"
      (click)="editRoomName(selectedRoom!)"
      [disabled]="!budgetRoomNameInput.value">
      Confirmar
    </button>
  </div>
</app-confirmation-modal>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
