<div class="settings">
  <h2 class="text-4xl font-medium mb-2.5">
    Configurações de recebimento de leads
  </h2>
  <p class="text-2xl text-raven">
    Precisamos que você preencha os dados abaixo conforme seu interesse nos
    Leads que serão enviados no seu e-mail
  </p>

  <div class="form flex flex-col mt-10 gap-8" [formGroup]="formGroup">
    <div class="form-group">
      <label for="">Tipos de projeto</label>
      <div class="flex flex-col md:flex-row gap-4">
        <label
          for="accepted_property_types_residential"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="accepted_property_types_residential"
            type="checkbox"
            value="RESIDENTIAL"
            (change)="
              onSelectCheckbox('accepted_property_types', 'RESIDENTIAL')
            "
            [checked]="
              isCheckboxSelected('accepted_property_types', 'RESIDENTIAL')
            " />
          Residencial
        </label>

        <label
          for="accepted_property_types_corporate"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="accepted_property_types_corporate"
            type="checkbox"
            value="CORPORATE"
            (change)="onSelectCheckbox('accepted_property_types', 'CORPORATE')"
            [checked]="
              isCheckboxSelected('accepted_property_types', 'CORPORATE')
            " />
          Corporativo
        </label>

        <label
          for="accepted_property_types_retail"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="accepted_property_types_retail"
            type="checkbox"
            value="RETAIL"
            (change)="onSelectCheckbox('accepted_property_types', 'RETAIL')"
            [checked]="
              isCheckboxSelected('accepted_property_types', 'RETAIL')
            " />
          Varejo
        </label>

        <label
          for="accepted_property_types_condominium"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="accepted_property_types_condominium"
            type="checkbox"
            value="CONDOMINIUM"
            (change)="
              onSelectCheckbox('accepted_property_types', 'CONDOMINIUM')
            "
            [checked]="
              isCheckboxSelected('accepted_property_types', 'CONDOMINIUM')
            " />
          Condominial
        </label>
      </div>
    </div>

    <div class="form-group">
      <label for="">Ticket médio de vendas intermediadas por arquitetos</label>
      <input
        type="text"
        mask="separator.2"
        thousandSeparator="."
        decimalMarker=","
        prefix="R$ "
        [dropSpecialCharacters]="true"
        formControlName="average_architect_ticket" />
    </div>

    <div class="form-group">
      <label for="">Ticket médio de vendas diretas para cliente final</label>
      <input
        type="text"
        mask="separator.2"
        thousandSeparator="."
        decimalMarker=","
        prefix="R$"
        [dropSpecialCharacters]="true"
        formControlName="average_customer_ticket" />
    </div>

    <div class="form-group">
      <label for="">Padrão de acabamento exigido por cliente final</label>
      <div class="flex flex-col md:flex-row gap-4">
        <label
          for="customer_required_quality_standard_a_plus"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_a_plus"
            type="checkbox"
            value="A_PLUS"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'A_PLUS')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'A_PLUS')
            " />
          A+
        </label>

        <label
          for="customer_required_quality_standard_a"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_a"
            type="checkbox"
            value="A"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'A')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'A')
            " />
          A
        </label>

        <label
          for="customer_required_quality_standard_b_plus"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_b_plus"
            type="checkbox"
            value="B_PLUS"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'B_PLUS')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'B_PLUS')
            " />
          B+
        </label>

        <label
          for="customer_required_quality_standard_b"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_b"
            type="checkbox"
            value="B"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'B')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'B')
            " />
          B
        </label>

        <label
          for="customer_required_quality_standard_c_plus"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_c_plus"
            type="checkbox"
            value="C_PLUS"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'C_PLUS')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'C_PLUS')
            " />
          C+
        </label>

        <label
          for="customer_required_quality_standard_c"
          class="flex items-center gap-2 w-full !font-normal">
          <input
            class="!w-min"
            id="customer_required_quality_standard_c"
            type="checkbox"
            value="C"
            (change)="
              onSelectCheckbox('customer_required_quality_standard', 'C')
            "
            [checked]="
              isCheckboxSelected('customer_required_quality_standard', 'C')
            " />
          C
        </label>
      </div>
    </div>

    <div class="form-group">
      <label for="" class="flex gap-2">
        <input
          type="checkbox"
          class="!w-min"
          formControlName="accepts_external_technical_retention" />
        <span>Adepta a comissionamento externo (RT)</span>
      </label>
    </div>

    <div class="form-group" *ngIf="acceptsTechnicalRetention">
      <label for="">Percentual máximo para RT</label>
      <input
        type="number"
        max="100"
        min="0"
        formControlName="maximum_external_technical_retention_percentage" />
    </div>

    <div class="form-group w-full">
      <label for="">Localidades</label>
      <p class="text-xl text-raven">
        Informe os locais das obras que você tem interesse
      </p>

      <div
        class="flex flex-col md:flex-row gap-4"
        [formGroup]="locationFormGroup">
        <div class="form-group w-full">
          <label for="add_country">País</label>
          <ng-select
            id="add_country"
            bindLabel="name"
            bindValue="id"
            [items]="countries"
            formControlName="country"></ng-select>
        </div>

        <div class="form-group w-full">
          <label for="add_state">Estado</label>
          <ng-select
            id="add_state"
            [items]="states"
            bindLabel="name"
            bindValue="id"
            formControlName="state"
            (change)="fetchCities()"
            [readonly]="!locationFormGroup.get('country')?.value"
            [placeholder]="
              fetchingStates ? 'Carregando...' : 'Todos os estados'
            "></ng-select>
        </div>

        <div class="form-group w-full">
          <label for="add_city">Cidade</label>
          <ng-select
            id="add_city"
            [readonly]="!locationFormGroup.get('state')?.value"
            (change)="fetchNeighborhoods()"
            formControlName="city"
            [items]="cities"
            bindValue="id"
            bindLabel="name"
            [placeholder]="
              fetchingCities ? 'Carregando...' : 'Todas as cidades'
            "></ng-select>
        </div>

        <div class="form-group w-full">
          <label for="add_neighborhood">Bairro</label>
          <ng-select
            id="add_neighborhood"
            [readonly]="!locationFormGroup.get('city')?.value"
            formControlName="neighborhood"
            [items]="neighborhoods"
            bindValue="id"
            bindLabel="name"
            [placeholder]="
              fetchingNeighborhoods ? 'Carregando...' : 'Todos os bairros'
            "
            [hideSelected]="true"></ng-select>
        </div>

        <div class="flex self-end w-full md:w-min">
          <button
            (click)="addLocation()"
            class="button w-full border border-prairie-sand text-xl text-prairie-sand md:h-[5.8rem] !font-medium">
            Adicionar
          </button>
        </div>
      </div>

      <h3 class="text-woodsmoke font-medium text-2xl">Locais selecionados</h3>

      <div class="flex flex-col md:flex-row gap-4">
        <div
          *ngFor="let control of countriesFormArray.controls; let i = index"
          class="text-nowrap">
          <span
            class="bg-dawn-pink text-prairie-sand py-2 px-4 rounded-full text-xl">
            <button class="me-2" (click)="removeCountry(i)">
              <i class="icon icon-close text-base font-bold"></i>
            </button>
            País: {{ countriesFormArray?.value.at(i).name }}
          </span>
        </div>

        <div
          *ngFor="let control of statesFormArray.controls; let i = index"
          class="text-nowrap">
          <span
            class="bg-dawn-pink text-prairie-sand py-2 px-4 rounded-full text-xl">
            <button class="me-2" (click)="removeState(i)">
              <i class="icon icon-close text-base font-bold"></i>
            </button>
            UF: {{ statesFormArray?.value.at(i).name }}
          </span>
        </div>

        <div
          *ngFor="let control of citiesFormArray.controls; let i = index"
          class="text-nowrap">
          <span
            class="bg-dawn-pink text-prairie-sand py-2 px-4 rounded-full text-xl">
            <button class="me-2" (click)="removeCity(i)">
              <i class="icon icon-close text-base font-bold"></i>
            </button>
            Cidade: {{ citiesFormArray?.value.at(i).name }} -
            {{ citiesFormArray?.value.at(i).state_federative_unit }}
          </span>
        </div>

        <div
          *ngFor="let control of neighborhoodsFormArray.controls; let i = index"
          class="text-nowrap">
          <span
            class="bg-dawn-pink text-prairie-sand py-2 px-4 rounded-full text-xl">
            <button class="me-2" (click)="removeNeighborhood(i)">
              <i class="icon icon-close text-base font-bold"></i>
            </button>
            Bairro: {{ neighborhoodsFormArray?.value.at(i).name }},
            {{ neighborhoodsFormArray?.value.at(i).city_name }} -
            {{ neighborhoodsFormArray?.value.at(i).state_federative_unit }}
          </span>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-end">
      <button class="button bg-prairie-sand text-white" (click)="submit()">
        <ng-container *ngIf="!submittingAccount">Salvar</ng-container>
        <ng-container *ngIf="submittingAccount">Carregando...</ng-container>
      </button>
    </div>
  </div>

  <div
    class="form flex flex-col mt-10 gap-8"
    *ngIf="!fetchingCategories; else loadingTemplate">
    <div class="flex flex-col gap-8">
      <ng-container *ngFor="let productType of productTypes">
        <div
          class="box flex flex-col ring-1 ring-athens-gray text-raven rounded-2xl items-start mb-5"
          [class.ring-raven]="
            productType.minimum_value_estimated ||
            productType.maximum_value_estimated
          ">
          <div class="flex w-full justify-between items-center">
            <div class="flex flex-col">
              <h3 class="text-woodsmoke text-[1.8rem] font-semibold text-start">
                {{ productType.name }}
              </h3>
              <p class="text-[1.2rem] mb-3">{{ productType.description }}</p>
            </div>
          </div>

          <div class="transition-all overflow-hidden w-full">
            <ngx-slider
              [options]="createSliderOptions(productType)"
              [(value)]="productType.minimum_value_estimated"
              [(highValue)]="productType.maximum_value_estimated">
            </ngx-slider>

            <div class="form flex flex-row flex-wrap gap-8 w-full">
              <div class="form-group form-group--inner-label">
                <label for="">Mínimo desejado</label>
                <div class="flex">
                  <span class="text-[1.6rem] mr-1">R$</span>
                  <input
                    type="number"
                    [valueAsNumber]="productType.minimum_value_estimated"
                    (change)="setProductTypeMinimumValue(productType, $event)"
                    step="500" />
                </div>
              </div>

              <div class="form-group form-group--inner-label">
                <label for="">Máximo desejado</label>
                <div class="flex">
                  <span class="text-[1.6rem] mr-1">R$</span>
                  <input
                    type="number"
                    [valueAsNumber]="productType.maximum_value_estimated"
                    (change)="setProductTypeMaximumValue(productType, $event)"
                    step="500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-row justify-end">
      <button
        class="button bg-prairie-sand text-white"
        (click)="submitCategories()">
        <ng-container *ngIf="submittingCategories.length == 0"
          >Salvar</ng-container
        >
        <ng-container *ngIf="submittingCategories.length > 0"
          >Carregando...</ng-container
        >
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
