import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CepAbertoService {
  constructor(private http: HttpClient) {}

  getAddressByZipcode(zipcode: string): Observable<{}> {
    return this.http.get<{}>(
      `${environment.API_URL}/zipcode/?zipcode=${zipcode}`
    );
  }
}
