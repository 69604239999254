import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Account} from "../models/account.model";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import { Pagination } from '../models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) {
  }

  login(data: Account): Observable<Account> {
    return this.http.post<Account>(`${environment.API_URL}/accounts/login/`, data);
  }

  register(data: Account): Observable<Account> {
    return this.http.post<Account>(`${environment.API_URL}/accounts/`, data);
  }

  get(id: number): Observable<Account> {
    return this.http.get<Account>(`${environment.API_URL}/accounts/${id}/`);
  }

  update(data: Account) {
    return this.http.patch(`${environment.API_URL}/accounts/${data.id}/`, data);
  }

  list(page: number, role: string): Observable<Pagination<Account>> {
    return this.http.get<Pagination<Account>>(`${environment.API_URL}/accounts/?page=${page}&role=${role}`);
  }
}
