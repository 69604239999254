<app-navigation #appNavigation></app-navigation>

<div class="presentation">
  <span>Orçamentaria para fornecedores</span>

  <div class="wrapper-text">
    <h3>
      Conectamos arquitetos e clientes finais diretamente com os mais variados
      fornecedores
    </h3>

    <p>
      De forma personalizada, em um só lugar, facilitando a pesquisa e aquisição
      de produtos e serviços para execuções de projetos arquitetônicos
    </p>
  </div>
</div>

<div class="partner-info">
  <div class="box-wrapper">
    <div class="img-container">
      <img
        class="block lg:hidden"
        src="../../../../assets/images/rocket_mobile.png" />
      <img
        class="hidden lg:block"
        src="../../../../assets/images/rocket_desk.png" />
    </div>

    <div class="lg:order-first text-container">
      <i class="icon icon-lucide_rocket"></i>

      <h4>Voa, parceiro!</h4>

      <p>
        DUPLIQUE suas vendas online com uma presença destacada e personalizada
        na plataforma e aumente sua taxa de conversão usufruindo da conveniência
        e eficiência que só a ORÇAMENTARIA pode proporcionar a você e aos seus
        clientes.
      </p>
    </div>
  </div>

  <div class="box-wrapper">
    <div class="img-container">
      <img
        class="block lg:hidden"
        src="../../../../assets/images/wood_blocks_mobile.png" />
      <img
        class="hidden lg:block"
        src="../../../../assets/images/wood_blocks_desk.png" />
    </div>

    <div class="text-container">
      <span class="text-prairie-sand text-[1.6rem]/[1.5] font-semibold">
        Vitrine para o seu canal de vendas
      </span>

      <h4>Plano de fidelidade para conectar o seu e-commerce!</h4>

      <p>
        Sabemos que a robustez e estrutura de um e-commerce diz muito sobre o
        seu negócio, portanto, algumas análises serão feitas pela nossa equipe,
        para oferecer o plano de negócios que mais se adequa as suas
        necessidades, para então impulsionar as suas vendas.
      </p>

      <ul>
        <li>Volume de transações</li>
        <li>Complexidade do Catálogo de Produtos</li>
        <li>Nível de Personalização</li>
        <li>Integração de Sistemas</li>
        <li>Recursos de Marketing e SEO</li>
        <li>Capacidade de Suporte e Atendimento ao Cliente</li>
        <li>Segurança e Compliance</li>
      </ul>
    </div>
  </div>
</div>

<div class="packages-wrapper">
  <div class="title">
    <h3>
      Para atendermos nossos parceiros de forma justa e eficiente, criamos 3
      diferentes planos
    </h3>

    <p>para 3 diferentes perfis de canais de venda online, sendo eles:</p>
  </div>

  <div
    class="lg:px-[112px] flex flex-col lg:flex-row items-center gap-[3.2rem]">
    <div class="package">
      <div class="icon-container">
        <i class="icon-hollow_star"></i>
      </div>

      <h4>Boutique</h4>

      <p>
        Se suas lojas online geralmente atendem a clientes específicos com um
        número limitado de produtos e focam em um atendimento personalizado e
        uma experiência de compra única, você se enquadra neste perfil de
        e-commerce.
      </p>
    </div>

    <div class="package">
      <div class="icon-container">
        <i class="icon-graph text-[1.8rem]"></i>
      </div>

      <h4>Crescimento</h4>

      <p>
        Se suas lojas são de médio porte e estão em fase de expansão, atendendo
        a uma base de clientes crescente, com um catálogo variado de produtos e
        desejam investir em tecnologias e estratégias de marketing para escalar
        suas operações, você se enquadra neste perfil de e-commerce.
      </p>
    </div>

    <div class="package">
      <div class="icon-container">
        <i class="icon-build_2 text-[2.6rem]"></i>
      </div>

      <h4>Enterprise</h4>

      <p>
        Agora, se estamos falando de grandes lojas online com operações
        complexas, que já atendem uma ampla base de clientes com um extenso
        catálogo de produtos e utilizam tecnologias avançadas e estratégias
        sofisticadas de marketing e logística para otimizar a experiência de
        seus clientes, você se enquadra deste perfil de e-commerce.
      </p>
    </div>
  </div>

  <div class="identify">
    <div>
      <h3>Se identificou com alguns destes perfis?</h3>

      <span> Entre em contato agora mesmo! </span>
    </div>

    <div class="">
      <button (click)="openRegister()">Quero ser parceiro</button>
    </div>
  </div>
</div>

<div class="conclusion">
  <div class="lg:pt-[5.6rem] flex flex-col lg:flex-row">
    <div class="title lg:ms-[6.4rem] lg:w-[50%]">
      <span> Ficou muito mais fácil </span>

      <h4>O cliente perfeito no seu email!</h4>

      <p>
        Já imaginou receber um e-mail informando que aquele cliente dos sonhos,
        com o perfil ideal, está a espera do seu contato?
      </p>
    </div>

    <img
      class="block lg:hidden lg:order-first"
      src="../../../../assets/images/conclusion_company_mobile.png" />
    <img
      class="lg:w-[50%] hidden lg:block lg:order-first"
      src="../../../../assets/images/conclusion_company_desk.png" />
  </div>

  <div class="lg:mt-[9.6rem] flex flex-col lg:flex-row lg:max-h-[30rem]">
    <div class="title-price lg:w-[50%]">
      <i class="icon-tend"></i>

      <h3>Impulsionamento de lojas físicas</h3>

      <p>
        Se você ainda não possui um ecommerce (canal de vendas online), não se
        preocupe pois nós temos a solução!
      </p>
      <p>
        Aumente a visibilidade de sua loja e <b>DUPLIQUE</b> suas vendas por
        apenas <b>R$ 17,00 POR MÊS ou por apenas R$ 120,00 POR ANO</b>, podendo
        cancelar quando quiser.
      </p>
    </div>

    <div
      class="price-baner lg:w-[100%] mx-auto flex relative w-[36rem] lg:max-w-[52rem] min-h-[30rem]">
      <!-- <div class="me-[] gap-[4.5rem] flex flex-col relative"> -->
      <div class="container-icon cocoa absolute lg:top-[5rem] lg:left-[8rem]">
        <i class="icon-sparks"></i>

        <p>Duplique suas vendas</p>
      </div>

      <div
        class="container-icon sand absolute top-[8rem] right-[1rem] lg:top-[10.5rem] lg:right-[0]">
        <p>R$ 17,00 p/mês</p>

        <i class="icon-sparks"></i>
      </div>

      <!-- </div> -->

      <!-- <div class="gap-[4rem] pt-[6rem] ms-[] flex flex-col items-start"> -->

      <div
        class="container-icon sand absolute top-[18.5rem] right-[1rem] lg:top-[21.6rem] lg:right-[-1.6rem]">
        <p>R$ 120,00 p/ano</p>

        <i class="icon-sparks"></i>
      </div>

      <div
        class="container-icon cocoa absolute top-[13rem] lg:top-[16rem] lg:left-[-4rem]">
        <i class="icon-sparks"></i>

        <p class="">Aumente a visibilidade de sua loja</p>
      </div>
      <!-- </div> -->
    </div>
  </div>

  <div class="cards-wrapper">
    <div class="card-format negative">
      <i class="icon-sad"></i>

      <h3>SEM a Orçamentaria</h3>

      <p>O cliente procura a loja mais próxima a ele.</p>

      <p>
        O resultado disso é que a cada 100 clientes que visitam o seu
        estabelecimento, apenas 25 efetuam compras.
      </p>
    </div>

    <div class="card-format positive">
      <i class="icon-happy"></i>

      <h3>COM a Orçamentaria</h3>

      <p>A ORÇAMENTARIA procura o cliente mais próximo a sua loja.</p>

      <p>
        O resultado disso é que a cada 100 clientes que visitam o seu
        estabelecimento , 45 efetuam compras.
      </p>

      <p>Ou seja, quase o DOBRO!</p>
    </div>
  </div>
</div>
