import { BmgComponent } from './public/landing-page/bmg/bmg.component';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {LandingPageComponent} from './public/landing-page/landing-page.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {LoginComponent} from './public/login/login.component';
import {BottomSheetComponent} from './components/bottom-sheet/bottom-sheet.component';
import {RegisterComponent} from './public/register/register.component';
import {HistoryComponent} from './logged/history/history.component';
import {LoggedComponent} from './logged/logged.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputErrorComponent} from './input-error/input-error.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {InterceptorsModule} from "./core/interceptors/interceptors.module";
import {NgToastModule} from "ng-angular-popup";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {ExecutionTypeComponent} from './logged/budget/execution-type/execution-type.component';
import {BudgetComponent} from './logged/budget/budget.component';
import {PropertyTypeComponent} from './logged/budget/property-type/property-type.component';
import {EstimatedPriceComponent} from './logged/budget/estimated-price/estimated-price.component';
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {RoomsComponent} from './logged/budget/rooms/rooms.component';
import {RoomSettingsComponent} from './logged/budget/room-settings/room-settings.component';
import {SummaryComponent} from './logged/budget/summary/summary.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {LoadingComponent} from './components/loading/loading.component';
import {BudgetDetailComponent} from './logged/budget/budget-detail/budget-detail.component';
import ptBr from '@angular/common/locales/pt';
import {registerLocaleData} from "@angular/common";
import { ProductsComponent } from './logged/budget/products/products.component';
import { RoomComponent } from './logged/budget/room/room.component';
import { ArchitectComponent } from './public/landing-page/architect/architect.component';
import { SiteComponent } from './public/landing-page/site/site.component';
import { CompanyComponent } from './public/landing-page/company/company.component';
import { CustomerComponent } from './public/landing-page/customer/customer.component';
import {CompanyRegisterComponent} from './public/company-register/company-register.component';
import {CompanyProfileComponent} from './app/logged/company-profile/company-profile.component';
import {CompanySettingsComponent} from './logged/company-settings/company-settings.component';
import { CompaniesComponent } from './logged/staff/companies/companies.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { ImportProductsComponent } from './logged/import-products/import-products.component';

registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    NavigationComponent,
    LoginComponent,
    BottomSheetComponent,
    RegisterComponent,
    HistoryComponent,
    LoggedComponent,
    InputErrorComponent,
    ExecutionTypeComponent,
    BudgetComponent,
    PropertyTypeComponent,
    EstimatedPriceComponent,
    RoomsComponent,
    RoomSettingsComponent,
    SummaryComponent,
    ConfirmationModalComponent,
    LoadingComponent,
    BudgetDetailComponent,
    ProductsComponent,
    RoomComponent,
    ArchitectComponent,
    SiteComponent,
    CompanyComponent,
    CustomerComponent,
    CompanyRegisterComponent,
    CompanyProfileComponent,
    CompanySettingsComponent,
    CompaniesComponent,
    ImportProductsComponent,
    BmgComponent
  ],
  imports: [
    NgToastModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    InterceptorsModule,
    NgxSliderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    NgSelectModule,
    // NgbAccordionModule,
  ],
  providers: [
    provideNgxMask(),
    {provide: LOCALE_ID, useValue: 'pt'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
