import { Component, ViewChild } from '@angular/core';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent {
  @ViewChild('appNavigation') appNavigation!: NavigationComponent;

  openRegister() {
    this.appNavigation.registerComponent.open();
  }
}
