import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {State} from "../models/state.model";
import {City} from "../models/city.model";
import {Neighborhood} from "../models/neighborhood.model";

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  constructor(private http: HttpClient) {
  }

  listCountries(): Observable<{}[]> {
    return this.http.get<{}[]>(`${environment.API_URL}/`);
  }

  listStates(): Observable<State[]> {
    return this.http.get<State[]>(`${environment.API_URL}/states/?`);
  }

  listCities(stateId: number): Observable<City[]> {
    return this.http.get<City[]>(`${environment.API_URL}/states/${stateId}/cities/`);
  }

  listNeighborhoods(cityId: number): Observable<Neighborhood[]> {
    return this.http.get<Neighborhood[]>(`${environment.API_URL}/cities/${cityId}/neighborhoods/`);
  }
}
