import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent {
  @Output() onClose = new EventEmitter<any>();

  show = false;

  close() {
    this.show = false;
    this.onClose.emit();
  }

  open() {
    this.show = true;
  }
}
