import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {Account} from "../../models/account.model";
import {SessionManagerService} from "../session-manager.service";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedService implements CanActivate {
  constructor(private sessionManager: SessionManagerService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sessionManager.loggedIn;
  }
}
