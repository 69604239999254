<router-outlet></router-outlet>

<div class="max-w-full bg-alabaster">
  <div class="container mx-auto py-24" id="footer">
    <div class="flex flex-col gap-12 px-[1.6rem]">
      <img
        src="/assets/images/logo.svg"
        alt="Orçamentaria"
        class="w-6/12 md:w-2/12" />
      <p class="text">
        Orçamento descomplicado para mão de obra e material básico.
      </p>

      <div class="wrapper-footer">
        <p class="footer-text">
          © 2023 Orçamentaria. Todos os direitos reservados.
        </p>

        <div class="icon">
          <a
            target="_blank"
            href="https://www.linkedin.com/company/or%C3%A7amentaria/">
            <i class="icon-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
