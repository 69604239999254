<div class="budget execution-type" *ngIf="!loading else loadingTemplate">
  <h2 class="budget__title">Qual é o tipo de execução?</h2>

  <div class="budget__content">
    <div class="flex md:flex-row flex-col gap-8">
      <div class="option cursor-pointer" [class.selected]="selected == 'RENOVATION'" (click)="selected = 'RENOVATION'">
        <i class="icon icon-bricks"></i>
        <div class="option__content">
          <p class="title">Reforma</p>
          <p class="text">
            Vai reformar seu imóvel?<br>
            Descubra como tornar seu projeto de reforma uma realidade!
          </p>
        </div>
      </div>

      <div class="option cursor-default opacity-60" [class.selected]="selected == 'CONSTRUCTION'">
        <i class="icon icon-buildings"></i>
        <div class="option__content">
          <p class="title">Construção</p>
          <p class="text">
            Em breve!
          </p>
        </div>
      </div>
    </div>

    <div class="flex items-center gap-8 text-2xl text-raven" *ngIf="account.role == 'ARCHITECT'">
      <label class="text-left">Deseja incluir RT para mão de obra e marcenaria?</label>

      <label for="technical_retention_yes">
        <input type="radio" id="technical_retention_yes" name="technical_retention" [(ngModel)]="technicalRetention" [value]="true">
        Sim
      </label>

      <label for="technical_retention_no">
        <input type="radio" id="technical_retention_no" name="technical_retention" [(ngModel)]="technicalRetention" [value]="false">
        Não
      </label>
    </div>

    <div class="flex items-center gap-8 text-2xl text-raven">
      <label class="text-left">Estimar valores praticados por empresas de engenharia especializada?</label>

      <label for="company_estimate_yes">
        <input type="radio" id="company_estimate_yes" name="company_estimate" [(ngModel)]="companyEstimate" [value]="true">
        Sim
      </label>

      <label for="company_estimate_no">
        <input type="radio" id="company_estimate_no" name="company_estimate" [(ngModel)]="companyEstimate" [value]="false">
        Não
      </label>
    </div>

    <button class="button bg-prairie-sand text-white w-full" [class.bg-schooner]="!shouldProceed()" [disabled]="!shouldProceed()" (click)="onSubmit()">Avançar</button>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
