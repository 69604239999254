<div class="bg-alabaster min-h-screen p-10 pt-40">
  <div class="container mx-auto md:w-1/3 xl:w-1/4">
    <div class="flex flex-col gap-14">
      <div class="flex flex-col gap-10">
        <img
          class="w-20 mx-auto"
          src="assets/images/icon.svg"
          alt="orçamentaria ícone" />

        <div class="flex flex-col gap-5">
          <h2 class="text-center">Cadastre-se</h2>
          <p class="text-center">
            Por favor, insira seus dados para acessar a ferramenta.
          </p>
        </div>
      </div>

      <form
        class="form flex flex-col gap-8"
        *ngIf="registerFormGroup"
        [formGroup]="registerFormGroup">
        <div class="form-group form-group--row">
          <label class="text-left">Você é</label>

          <div class="flex gap-8">
            <label for="role_architect" class="flex gap-4 align-center">
              <input
                type="radio"
                id="role_architect"
                formControlName="role"
                value="ARCHITECT" />
              Arquiteto
            </label>

            <label for="role_user" class="flex gap-4 align-center">
              <input
                type="radio"
                id="role_user"
                formControlName="role"
                value="CUSTOMER" />
              Cliente
            </label>

            <label for="role_company" class="flex gap-4 align-center">
              <input
                type="radio"
                id="role_company"
                formControlName="role"
                value="COMPANY" />
              Fornecedor
            </label>
          </div>
        </div>

        <ng-container *ngIf="isCompany">
          <div class="form-group" [formGroup]="companyFormGroup">
            <label for="">Nome da empresa</label>
            <input
              type="text"
              formControlName="name"
              placeholder="Digite o nome da empresa" />
            <app-input-error
              [fg]="companyFormGroup"
              controlName="name"></app-input-error>
          </div>

          <div class="form-group" [formGroup]="companyFormGroup">
            <label for="">CNPJ</label>
            <input
              type="text"
              formControlName="document_number"
              mask="00.000.000/0000-00"
              placeholder="00.000.000/0000-00" />
            <app-input-error
              [fg]="companyFormGroup"
              controlName="document_number"></app-input-error>
          </div>
        </ng-container>

        <div class="form-group">
          <label for="name">Seu nome</label>
          <input
            id="name"
            type="text"
            placeholder="Digite seu nome"
            formControlName="name" />
          <app-input-error
            [fg]="registerFormGroup"
            controlName="name"></app-input-error>
        </div>

        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            id="email"
            type="email"
            placeholder="Digite seu email"
            formControlName="email" />
          <app-input-error
            [fg]="registerFormGroup"
            controlName="email"></app-input-error>
        </div>

        <div
          class="form-group"
          [formGroup]="architectFormGroup"
          *ngIf="isArchitect">
          <label for="company">Empresa</label>
          <input
            id="company"
            type="text"
            placeholder="Digite a empresa que trabalha"
            formControlName="name" />
          <app-input-error
            [fg]="architectFormGroup"
            controlName="name"></app-input-error>
        </div>

        <div class="form-group">
          <label for="phone_number">Telefone</label>
          <input
            id="phone_number"
            type="text"
            placeholder="+55 11 90000-0000"
            mask="+00 00 00000-0000"
            [validation]="true"
            formControlName="phone_number" />
          <app-input-error
            [fg]="registerFormGroup"
            controlName="phone_number"></app-input-error>
        </div>

        <div class="form-group">
          <label for="password">Senha</label>
          <input
            id="password"
            type="password"
            placeholder="*****"
            formControlName="password" />
          <app-input-error
            [fg]="registerFormGroup"
            controlName="password"></app-input-error>
        </div>

        <div class="form-group">
          <label for="confirm_password">Confirme sua senha</label>
          <input
            id="confirm_password"
            type="password"
            placeholder="*****"
            formControlName="confirm_password" />
          <app-input-error
            [fg]="registerFormGroup"
            controlName="confirm_password"></app-input-error>
        </div>

        <button
          type="submit"
          class="w-full button bg-prairie-sand text-white"
          *ngIf="!loading"
          (click)="submit()">
          Cadastrar
        </button>
        <button
          type="submit"
          class="w-full button bg-athens-gray text-woodsmoke"
          *ngIf="loading"
          (click)="submit()">
          Cadastrar
        </button>
        <p class="text-center">
          Já tem uma conta?
          <a
            (click)="closeAndGoToLogin()"
            class="text-prairie-sand font-bold whitespace-nowrap"
            >Entrar</a
          >
        </p>
      </form>
    </div>
  </div>
</div>
