import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './public/landing-page/landing-page.component';
import { HistoryComponent } from './logged/history/history.component';
import { LoggedComponent } from './logged/logged.component';
import { IsLoggedService } from './core/services/guards/is-logged.service';
import { ExecutionTypeComponent } from './logged/budget/execution-type/execution-type.component';
import { BudgetComponent } from './logged/budget/budget.component';
import { PropertyTypeComponent } from './logged/budget/property-type/property-type.component';
import { EstimatedPriceComponent } from './logged/budget/estimated-price/estimated-price.component';
import { SummaryComponent } from './logged/budget/summary/summary.component';
import { RoomSettingsComponent } from './logged/budget/room-settings/room-settings.component';
import { RoomsComponent } from './logged/budget/rooms/rooms.component';
import { BudgetDetailComponent } from './logged/budget/budget-detail/budget-detail.component';
import { ProductsComponent } from './logged/budget/products/products.component';
import { CompanyProfileComponent } from './app/logged/company-profile/company-profile.component';
import { CompanySettingsComponent } from './logged/company-settings/company-settings.component';
import { CompaniesComponent } from './logged/staff/companies/companies.component';
import { isStaffGuard } from './core/services/guards/is-staff.guard';
import { ArchitectComponent } from './public/landing-page/architect/architect.component';
import { CompanyComponent } from './public/landing-page/company/company.component';
import { CustomerComponent } from './public/landing-page/customer/customer.component';
import { SiteComponent } from './public/landing-page/site/site.component';
import { ImportProductsComponent } from './logged/import-products/import-products.component';
import { BmgComponent } from './public/landing-page/bmg/bmg.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    children: [
      { path: '', component: SiteComponent },
      { path: 'arquiteto', component: ArchitectComponent },
      { path: 'fornecedor', component: CompanyComponent },
      { path: 'cliente', component: CustomerComponent },
      { path: 'bmg', component: BmgComponent },
    ],
  },
  {
    path: 'd',
    component: LoggedComponent,
    canActivate: [IsLoggedService],
    children: [
      { path: 'meus-orcamentos', component: HistoryComponent },
      {
        path: 'orcamento/:id',
        canActivate: [IsLoggedService],
        component: BudgetComponent,
        children: [
          { path: 'tipo-de-execucao', component: ExecutionTypeComponent },
          { path: 'tipo-do-imovel', component: PropertyTypeComponent },
          { path: 'valor-estimado', component: EstimatedPriceComponent },
          { path: 'ambientes', component: RoomsComponent },
          { path: 'configurar-ambientes', component: RoomSettingsComponent },
          { path: 'configurar-produtos', component: ProductsComponent },
          { path: 'resumo', component: SummaryComponent },
          { path: 'meu-orcamento', component: BudgetDetailComponent },
        ],
      },
      {
        path: 'criar-orcamento',
        canActivate: [IsLoggedService],
        component: BudgetComponent,
        children: [
          { path: 'tipo-de-execucao', component: ExecutionTypeComponent },
        ],
      },
      { path: 'perfil/:type/:id', component: CompanyProfileComponent },
      { path: 'produtos/importar', component: ImportProductsComponent },
      { path: 'configuracoes', component: CompanySettingsComponent },
      {
        path: 'staff',
        canActivate: [isStaffGuard],
        children: [{ path: 'fornecedores', component: CompaniesComponent }],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
