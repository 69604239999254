<div class="container max-w-full pl-8 pr-8 md:pl-24 md:pr-24">
  <div class="flex justify-between mb-10">
    <div class="filter-buttons">
      <!--      <span class="filter">2023 <i class="icon icon-close"></i></span>-->
      <!--      <button class="button border-athens-gray border-2"><i class="icon icon-filter"></i> Filtrar por</button>-->
    </div>

    <div class="hidden md:block search-box">
      <input type="text" placeholder="Pesquisar">
      <div class="icon icon-search"></div>
    </div>
  </div>

  <div class="card" *ngIf="!loading else loadingTemplate">
    <h2 class="card__title">
      Histórico
      <span>{{ response.count }} Estimativas</span>
    </h2>

    <table>
      <thead>
      <tr>
        <th width="50%">Meus Orçamentos</th>
        <th class="hidden md:table-cell">Data</th>
        <th class="hidden md:table-cell">Pagamento</th>
        <th class="whitespace-nowrap">Valor estimado cliente (m²)</th>
        <th class="whitespace-nowrap">Valor estimado Orçamentaria (m²)</th>
        <th class="hidden md:block"></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let budget of budgets">
        <td>
          <i class="icon icon-pencil cursor-pointer" (click)="openModalSetName(budget)"></i>
          {{ budget.name }}
        </td>

        <td class="hidden md:table-cell">{{ budget.created_at | date:'dd/MM/YYYY' }}</td>

        <td class="hidden md:table-cell font-medium cursor-pointer"
            [class.text-prairie-sand]="budget.payment_status != 'PAID'"
            [class.text-chateau-green]="budget.payment_status == 'PAID'" (click)="editBudget(budget)">
          {{ budget.payment_status_display || '-' }}
        </td>

        <td class="whitespace-nowrap">
          {{ budget.minimum_price_estimated_by_user|currency:'R$' }}
          -
          {{ budget.maximum_price_estimated_by_user|currency:'R$' }}
        </td>

        <td>
          <ng-container *ngIf="budget.company_estimate_value">
            {{ budget.company_estimate_value|currency:'R$' }}
          </ng-container>
          <ng-container *ngIf="!budget.company_estimate_value">-</ng-container>
        </td>

        <td class="hidden md:flex gap-2">
          <i class="icon icon-edit cursor-pointer" (click)="editBudget(budget)"></i>
          <!--          <i class="icon icon-arrow-down-rounded"></i>-->
          <i class="icon icon-trash cursor-pointer" (click)="removeBudget(budget)"></i>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="flex justify-between items-center card__footer">
      <div class="block md:hidden pl-6 font-semibold">
        Página 1 de 10
      </div>

      <button class="button" [disabled]="!response.previous" (click)="loadPage(currentPage - 1)">
        <i class="icon icon-arrow-left hidden md:inline-block"></i>
        Anterior
      </button>

      <div class="pagination gap-8 hidden md:flex">
        <span class="cursor-pointer" [class.font-semibold]="i == currentPage" *ngFor="let i of pages"
              (click)="loadPage(i)">{{ i }}</span>
      </div>

      <button class="button" [disabled]="!response.next" (click)="loadPage(currentPage + 1)">
        Próxima
        <i class="icon icon-arrow-right hidden md:inline-block"></i>
      </button>
    </div>
  </div>
</div>

<app-confirmation-modal #setNameModal>
  <div class="flex flex-col gap-10 w-full">
    <div class="flex flex-col gap-2 text-woodsmoke">
      <h3 class="text-[2rem] font-semibold">Nomear projeto</h3>
      <h4 class="text-[1.4rem] font-light text-raven">Você pode nomear seu projeto.</h4>
    </div>

    <div class="flex flex-col gap-2 text-raven text-[1.4rem]" *ngIf="selectedBudget">
      <label for="name" class="font-medium">Nome do projeto</label>
      <input type="text" id="name" class="border border-athens-gray rounded-xl px-6 py-4" #budgetNameInput
             placeholder="ex: Casa 01" [value]="selectedBudget.name">
    </div>

    <button class="button bg-schooner text-white text-[1.6rem]" (click)="setBudgetName()">Confirmar</button>
  </div>
</app-confirmation-modal>

<app-confirmation-modal #budgetRemovalModal>
  <h4 class="modal__title">Atenção</h4>
  <p class="modal__content text-left">Tem certeza que deseja remover este orçamento?</p>

  <div class="modal__controls">
    <button class="button bg-schooner text-white text-[1.4rem]" (click)="budgetRemovalModal.close()">Não</button>
    <button class="button bg-prairie-sand text-white text-[1.4rem]" (click)="confirmBudgetRemoval()">Sim</button>
  </div>
</app-confirmation-modal>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
