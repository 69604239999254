import { Component } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { Budget } from '../../../core/models/budget.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '../../../core/services/budget.service';
import { ProductType } from '../../../core/models/product-type.model';
import { Account } from '../../../core/models/account.model';
import { SessionManagerService } from '../../../core/services/session-manager.service';

@Component({
  selector: 'app-estimated-price',
  templateUrl: './estimated-price.component.html',
  styleUrls: ['./estimated-price.component.scss'],
  host: {
    class: 'w-full',
  },
})
export class EstimatedPriceComponent {
  backButton = 'tipo-do-imovel';
  currentStep = { step: 3, label: 'Valor estimado' };

  account: Account = {} as Account;
  budget: Budget = {} as Budget;

  categoriesCollapsed = true;

  step = 100;
  sliderOptions: Options = {
    floor: 500,
    ceil: 1500000,
    step: this.step,
    translate: (value) => value.toLocaleString(),
  };

  architectureSliderOptions: Options = {
    floor: 60,
    ceil: 100000,
    step: 5,
    translate: (value) => value.toLocaleString(),
  };

  productTypeSliderOptionsMinimumValue = 0;
  productTypeSliderOptionsMaximumValue = 0;

  productTypes: ProductType[] = [];

  loading = false;
  sending = false;

  hasErrorsAtProductType = false;

  visibleBox = 'Mão de obra e material básico';

  constructor(
    private activatedRoute: ActivatedRoute,
    private budgetService: BudgetService,
    private router: Router,
    private sessionManager: SessionManagerService
  ) {
    this.account = this.sessionManager.getSessionData();

    this.activatedRoute.parent?.params.subscribe((params) => {
      if ('id' in params) {
        this.loading = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.budget = response;

            if (!this.budget.minimum_price_estimated_by_user) {
              this.budget.minimum_price_estimated_by_user = 500;
            }

            if (!this.budget.maximum_price_estimated_by_user) {
              this.budget.maximum_price_estimated_by_user = 1500000;
            }

            if (!this.budget.minimum_architecture_value) {
              this.budget.minimum_architecture_value = 60;
            }

            if (!this.budget.maximum_architecture_value) {
              this.budget.maximum_architecture_value = 100000;
            }

            this.fetchProductTypes();
            this.loading = false;
          },
          error: (error) => {
            this.loading = false;
            console.error(error);
          },
        });
      }
    });
  }

  fetchProductTypes() {
    this.budgetService.getProductTypes(this.budget.id).subscribe({
      next: (response) => {
        this.productTypes = response.map((productType) => {
          productType.minimum_value_estimated =
            productType.minimum_value_estimated ??
            this.productTypeSliderOptionsMinimumValue;
          productType.maximum_value_estimated =
            productType.maximum_value_estimated ??
            this.productTypeSliderOptionsMaximumValue;
          return productType;
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setProductTypeMinimumValue(productType: ProductType, ev: any) {
    productType.minimum_value_estimated = ev.target.value;
  }

  setProductTypeMaximumValue(productType: ProductType, ev: any) {
    productType.maximum_value_estimated = ev.target.value;
  }

  setMinValue(ev: any) {
    this.budget.minimum_price_estimated_by_user = ev.target.value;
  }

  setMaxValue(ev: any) {
    this.budget.maximum_price_estimated_by_user = ev.target.value;
  }

  onSubmit() {
    this.hasErrorsAtProductType = false;
    if (
      !this.budget.minimum_price_estimated_by_user ||
      !this.budget.maximum_price_estimated_by_user
    ) {
      return;
    }

    this.sending = true;

    this.productTypes.forEach((productType: ProductType, index: number) => {
      this.budgetService
        .saveProductTypeEstimatedValue(this.budget.id, productType)
        .subscribe({
          next: (response) => {
            if (index == this.productTypes.length - 1) {
              this.budgetService.estimatedPriceStep(this.budget).subscribe({
                next: (response) => {
                  this.sending = false;
                  this.router.navigate([
                    `/d/orcamento/${this.budget.id}/configurar-ambientes`,
                  ]);
                },
                error: (error) => {
                  console.error(error);
                  this.sending = false;
                },
              });
            }
          },
          error: (error) => {
            this.sending = false;
            this.hasErrorsAtProductType = true;
          },
        });
    });
  }

  createSliderOptions(productType: ProductType) {
    return {
      floor: productType.minimum_value,
      ceil: productType.maximum_value,
      step: 500,
      translate: (value: number) => value.toLocaleString(),
    };
  }

  toggleVisibleBox(visibleBox: string) {
    if (this.visibleBox == visibleBox) {
      this.visibleBox = '';
    } else {
      this.visibleBox = visibleBox;
    }
  }

  toggleCategories() {
    this.categoriesCollapsed = !this.categoriesCollapsed;
  }
}
