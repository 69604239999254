<div class="bg-alabaster min-h-screen p-10 pt-40" *ngIf="formGroup">
  <div class="container mx-auto md:w-1/3 xl:w-1/4">
    <div class="flex flex-col gap-14">
      <div class="flex flex-col gap-10">
        <img
          class="w-20 mx-auto"
          src="assets/images/icon.svg"
          alt="orçamentaria ícone" />

        <div class="flex flex-col gap-5">
          <h2 class="text-center">Entre em sua conta</h2>
          <p class="text-center">
            Por favor, insira seus dados para acessar a ferramenta.
          </p>
        </div>
      </div>

      <form
        class="form flex flex-col gap-8"
        [formGroup]="formGroup"
        (submit)="onSubmit()">
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            id="email"
            type="email"
            placeholder="Digite seu email"
            formControlName="email" />
          <app-input-error
            [fg]="formGroup"
            controlName="email"></app-input-error>
        </div>

        <div class="form-group">
          <label for="password">Senha</label>
          <input
            id="password"
            type="password"
            placeholder="*****"
            formControlName="password" />
          <app-input-error
            [fg]="formGroup"
            controlName="password"></app-input-error>
        </div>

        <button
          type="submit"
          class="w-full button bg-prairie-sand text-white"
          *ngIf="!loading">
          Entrar
        </button>
        <button
          type="submit"
          class="w-full button bg-athens-gray text-woodsmoke"
          disabled
          *ngIf="loading">
          Carregando...
        </button>
        <p class="text-center">
          Ainda não possui uma conta?
          <a (click)="closeAndGoToRegister()" class="text-prairie-sand font-bold whitespace-nowrap"
            >Cadastre-se</a
          >
        </p>
      </form>
    </div>
  </div>
</div>
