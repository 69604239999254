import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {SnackbarService} from "../../core/services/snackbar.service";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent {
  backButton = '/d/orcamento';
  currentStep = {step: 1, label: 'Execução'};

  childrenComponent?: any;

  id = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private snackbar: SnackbarService,
              private toastr: NgToastService) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  onActivate($event: any) {
    this.childrenComponent = $event;
    this.backButton = $event.backButton;
    this.currentStep = $event.currentStep;
  }

  generateRouterLink(page: string): string {
    if (page.includes('tipo-de-execucao')) {
      return '/d/criar-orcamento/tipo-de-execucao';
    }
    return `/d/orcamento/${this.id}/${page}`;
  }

  navigateTo(page: string, nextStep: number) {
    if (this.currentStep.step < nextStep) {
      return;
    }

    if (!this.canNavigateTo(page)) {
      return;
    }

    if (!this.id) {
      this.router.navigate(['/d/criar-orcamento/tipo-de-execucao']);
    } else {
      let routerLink = `/d/orcamento/${this.id}/${page}`;
      this.router.navigate([routerLink]);
    }
  }

  canNavigateTo(page: string) {
    if (!this.childrenComponent || !this.childrenComponent.budget) {
      return false;
    } else {
      let budget = this.childrenComponent.budget;
      if (budget.step == 'MY_BUDGET' && budget.payment_status != 'PAID') {
        this.childrenComponent.openPaymentModal();
        this.toastr.error({
          detail: '',
          summary: 'Este orçamento já está finalizado. Você pode editar esta estimativa após efetuar o pagamento.',
          duration: 10_000
        });
        return false;
      }
      return true;
    }
  }
}
