import { Injectable } from '@angular/core';
import { SessionManagerService } from '../services/session-manager.service';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private sessionManager: SessionManagerService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.sessionManager.loggedIn) {
      req = req.clone({
        setHeaders: {
          Authorization: `Token ${this.sessionManager.token}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          this.sessionManager.clearSessionData();
          this.router.navigate(['/login']);
        }
        return throwError(response);
      })
    );
  }
}
