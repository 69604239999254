import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SessionManagerService } from '../../../core/services/session-manager.service';
import { Account } from '../../../core/models/account.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  isFormGroup,
  FormArray,
} from '@angular/forms';
import { AccountService } from '../../../core/services/account.service';
import { Company } from 'src/app/core/models/company.model';
import { CompanyService } from 'src/app/core/services/company.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent {
  @ViewChild('csvFile') csvFile!: ElementRef<HTMLInputElement>;

  companyFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: ['', [Validators.required]],
    document_number: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    country_code: ['+55', [Validators.required]],
    phone_number: ['', [Validators.required, Validators.minLength(10)]],
    store_categories: [[]],
    site: [''],
    instagram: [''],
    tiktok: [''],
    facebook: [''],
  });
  architectFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: ['', [Validators.required]],
    document_number: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    country_code: ['+55', [Validators.required]],
    phone_number: ['', [Validators.required, Validators.minLength(10)]],
    site: [''],
    instagram: [''],
    tiktok: [''],
    facebook: [''],
  });
  userFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone_number: ['', [Validators.required]],
  });

  account?: Account;
  company?: Company;

  fetchingAccount: boolean = false;
  fetchingCompany: boolean = false;
  submittingAccount: boolean = false;

  get loading(): boolean {
    return (
      this.fetchingAccount || this.fetchingCompany || this.submittingAccount
    );
  }

  constructor(
    protected sessionManagerService: SessionManagerService,
    private fb: FormBuilder,
    private accountService: AccountService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        let id = params['id'];
        if (params['type'] == 'arquiteto') {
          this.fetchAccount();
        } else {
          this.fetchCompany(id);
        }
      },
    });
  }

  fetchArchitect(id: number) {
    this.fetchAccount();
  }

  fetchCompany(id: number) {
    this.fetchingCompany = true;
    this.companyService.get(id).subscribe({
      next: (response) => {
        this.fetchingCompany = false;
        this.company = response;
        this.companyFormGroup.patchValue(response);
        if (this.sessionManagerService.isCompany) {
          this.account = this.sessionManagerService.getSessionData() as Account;
          this.fetchAccount();
        }
      },
      error: (error) => {
        this.fetchingCompany = false;
        console.error(error);
      },
    });
  }

  fetchAccount() {
    this.fetchingAccount = true;
    this.account = this.sessionManagerService.getSessionData();
    this.accountService.get(this.account!.id!).subscribe({
      next: (response) => {
        this.account = response;
        this.userFormGroup.patchValue(this.account);
        if (this.account.architect) {
          this.architectFormGroup.patchValue(this.account.architect);
        }
        this.sessionManagerService.setSessionData(response);
        this.fetchingAccount = false;
      },
      error: (error) => {
        console.error(error);
        this.fetchingAccount = false;
      },
    });
  }

  async submitAccount() {
    let isArchitect = this.account?.architect != null;
    this.account = this.userFormGroup.getRawValue() as Account;
    if (isArchitect) {
      this.account.architect = this.architectFormGroup.getRawValue();
    }
    this.submittingAccount = true;
    this.accountService.update(this.account!).subscribe({
      next: (response) => {
        console.log(response);
        this.submittingAccount = false;
      },
      error: (error) => {
        console.error(error);
        this.submittingAccount = false;
      },
    });
  }

  async submit() {
    if (this.account) {
      this.userFormGroup.markAllAsTouched();
      if (this.userFormGroup.invalid) {
        return;
      }
      if (this.account?.architect) {
        this.architectFormGroup.markAllAsTouched();
        if (!this.architectFormGroup.valid) {
          return;
        }
        this.account.architect = this.architectFormGroup.getRawValue();
      }

      await this.submitAccount();
    }

    if (this.company) {
      this.companyFormGroup.markAllAsTouched();
      if (!this.companyFormGroup.valid) {
        return;
      }

      this.company = this.companyFormGroup.getRawValue() as Company;
      this.submittingAccount = true;
      this.companyService.update(this.company!).subscribe({
        next: (response) => {
          this.company = response;
          this.submittingAccount = false;
        },
        error: (error) => {
          console.error(error);
          this.submittingAccount = false;
        },
      });
    }
  }

  onSelectCheckbox(ev: any): void {
    let selectedCategories =
      (this.companyFormGroup.get('store_categories')?.value as string[]) ?? [];

    if (selectedCategories.some((_) => _ == ev.target.value)) {
      selectedCategories = selectedCategories.filter(
        (_) => _ != ev.target.value
      );
    } else {
      selectedCategories.push(ev.target.value);
    }

    this.companyFormGroup.patchValue({ store_categories: selectedCategories });
  }

  isCheckboxSelected(
    formGroup: FormGroup,
    formControlName: string,
    value: string
  ): boolean {
    let control = formGroup.get(formControlName);
    let options = (control?.value as string[]) ?? [];
    return options.some((_) => _ == value);
  }

  selectCsv() {
    this.router.navigate(['/d/produtos/importar']);
  }

  uploadImage(ev: any) {
    let file = ev.target.files[0];
    this.companyService
      .uploadProfileImage(this.sessionManagerService.getSessionData().id!, file)
      .subscribe({
        next: (response) => {
          var reader = new FileReader();
          reader.onloadend = (reader) => {
            this.company!.profile_image =
              reader.target?.result?.toString() ?? '';
          };
          reader.readAsDataURL(file);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
