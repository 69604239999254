<div class="budget summary">
  <h2 class="budget__title max-md:hidden">Resumo</h2>

  <div class="budget__content" *ngIf="!loading; else loadingTemplate">
    <div
      class="box md:rounded-2xl md:ring-1 md:ring-athens-gray flex flex-col gap-10">
      <div class="flex flex-col justify-between items-start gap-2">
        <div class="tag">
          <i class="icon {{ getPropertyTypeIcon(budget.property_type) }}"></i>
          {{ getPropertyTypeName(budget.property_type) }}
        </div>

        <p class="block text-start text-[2rem] font-light text-raven">
          Verifique as configurações antes de finalizar
        </p>
      </div>

      <div class="flex flex-col gap-4">
        <div
          class="rounded-2xl ring-1 ring-athens-gray w-full"
          *ngFor="let group of roomGroups">
          <div
            class="flex max-md:flex-col items-center p-8 justify-between max-md:items-start gap-2">
            <div class="flex gap-3">
              <span
                class="bg-athens-gray w-[3rem] rounded-xl font-semibold text-[1.4rem]/[3rem] text-center"
                >{{ group.amount }}</span
              >
              <span class="text-[2rem] font-medium">{{ group.name }}</span>
            </div>

            <div class="flex gap-3 max-md:flex-col max-md:items-start">
              <div
                class="flex bg-porcelain px-4 py-2 rounded-xl text-raven text-[1.4rem] gap-5">
                <span class="text-center">{{ group.removal }} Remoções</span>
                <span class="text-center">{{ group.install }} Instalações</span>
                <span class="text-center">{{ group.products }} Produtos</span>
              </div>

              <button
                class="px-4 py-2 text-[1.2rem] border border-athens-gray rounded-xl"
                [routerLink]="
                  '/d/orcamento/' + budget.id + '/configurar-ambientes'
                ">
                <i class="icon icon-edit mr-2"></i>
                Editar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <label
      for="want_to_be_contacted"
      class="flex items-center mx-auto gap-2 text-xl text-raven">
      <input
        type="checkbox"
        id="want_to_be_contacted"
        [(ngModel)]="wantToBeContacted" />
      Desejo receber contato de empresas com o perfil desejado
    </label>

    <button
      class="button bg-cocoa-brown text-white w-full"
      (click)="requestMyBudget()">
      Receber minha estimativa
      <i class="icon icon-arrow-right"></i>
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
