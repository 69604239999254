import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BudgetRoom } from '../../../core/models/budget-room.model';
import { BudgetRoomItem } from '../../../core/models/budget-room-item.model';
import { BudgetService } from '../../../core/services/budget.service';
import { NgToastService } from 'ng-angular-popup';
import { Budget } from '../../../core/models/budget.model';
import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { ViewportScroller } from '@angular/common';
import { Product } from '../../../core/models/product.model';
import { ProductType } from '../../../core/models/product-type.model';
import { max, Subscription } from 'rxjs';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements AfterViewInit {
  @ViewChild('budgetRoomsContainer') budgetRoomsContainer!: ElementRef;
  @ViewChild('editRoomNameModal')
  editRoomNameModal!: ConfirmationModalComponent;
  @ViewChild('confirmationModal')
  confirmationModal!: ConfirmationModalComponent;

  @Input() budget!: Budget;
  @Input() budgetRooms: BudgetRoom[] = [];

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveBudgetRoom: EventEmitter<BudgetRoom> =
    new EventEmitter<BudgetRoom>();

  selectedRoom?: BudgetRoom;
  budgetRoomToBeRemoved?: BudgetRoom;

  scrollActivation: { [key: string]: { start: number; end: number } } = {};

  activeTab: 'products' | 'rooms' = 'rooms';

  products: Product[] = [];

  selectedProductTypeIdentificator?: string;
  selectedProductType?: ProductType;

  loadingProducts: { [key: number]: boolean } = {};

  listBudgetRoomProducts$: { [key: number]: Subscription } = {};

  get isLoading(): boolean {
    return Object.entries(this.loadingProducts).length > 0;
  }

  constructor(
    private budgetService: BudgetService,
    private toastService: NgToastService
  ) {}

  ngAfterViewInit() {
    this.fetchBudgetProducts();
    this.budgetRooms.forEach((el) => {
      let htmlElement = document.getElementById(`budget-room-${el.id}`);
      if (htmlElement) {
        this.scrollActivation[htmlElement.id] = {
          start: htmlElement.offsetTop - 309,
          end: htmlElement.offsetTop - 309 + htmlElement.offsetHeight + 25,
        };
      }
    });
  }

  fetchBudgetProducts() {
    this.budgetRooms.forEach((budgetRoom) => {
      if (budgetRoom.id in this.listBudgetRoomProducts$) {
        this.listBudgetRoomProducts$[budgetRoom.id].unsubscribe();
      }
      this.listBudgetRoomProducts$[budgetRoom.id] = this.budgetService
        .listBudgetRoomProducts(budgetRoom.id)
        .subscribe({
          next: (response) => {
            budgetRoom.product_types = response.product_types;
            if (
              budgetRoom.product_types.length > 0 &&
              !this.selectedProductTypeIdentificator
            ) {
              this.setSelectedProductType(
                budgetRoom,
                budgetRoom.product_types[0],
                0
              );
            }
            // this.fetchProducts();
          },
          error: (error) => {
            console.log(error);
          },
        });
    });
  }

  fetchProducts(reload: boolean = false) {
    this.products = [];
    this.budgetRooms.forEach((budgetRoom) => {
      budgetRoom.product_types.forEach((productType) => {
        this.products.push(...productType.products);
      });
    });
  }

  fetchProductOptions(product: Product): void {
    console.log(`pegar opções de produto do produto ${product.id}`);
    let minimumValueEstimated = (product.minimum_value_estimated || '')
      .toString()
      .replace(',', '.');
    let maximumValueEstimated = (product.maximum_value_estimated || '')
      .toString()
      .replace(',', '.');
    console.log(product, minimumValueEstimated, maximumValueEstimated);
    this.budgetService
      .listProductOptions(
        product.id,
        minimumValueEstimated,
        maximumValueEstimated
      )
      .subscribe({
        next: (response) => {
          product.options = response;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  closeModal() {
    this.onClose.emit();
  }

  goAhead() {
    if (this.activeTab == 'rooms') {
      this.activeTab = 'products';
    } else {
      this.closeModal();
    }
  }

  openModalRoomName(budgetRoom: BudgetRoom) {
    this.selectedRoom = budgetRoom;
    this.editRoomNameModal.open();
  }

  isBudgetRoomSelected(budgetRoom: BudgetRoom): boolean {
    if (!this.budgetRoomsContainer) {
      return false;
    }
    let scrollTop = this.budgetRoomsContainer.nativeElement.scrollTop;
    let scrollActivation =
      this.scrollActivation[`budget-room-${budgetRoom.id}`];
    return (
      scrollTop > scrollActivation.start && scrollTop < scrollActivation.end
    );
  }

  editRoomSquareMeter(budgetRoom: BudgetRoom) {
    let data = {
      id: budgetRoom.id,
      square_meter: budgetRoom.square_meter.toString(),
    };
    this.budgetService
      .editBudgetRoom(this.budget.id, data as BudgetRoom)
      .subscribe({
        next: (response) => {
          // this.editRoomNameModal.close();
        },
        error: (error) => {
          console.log(error);
          this.toastService.error(error['detail']);
        },
      });
  }

  editRoomName(budgetRoom: BudgetRoom) {
    let data = { id: budgetRoom.id, name: budgetRoom.name };
    this.budgetService
      .editBudgetRoom(this.budget.id, data as BudgetRoom)
      .subscribe({
        next: (response) => {
          this.editRoomNameModal.close();
        },
        error: (error) => {
          console.log(error);
          this.toastService.error(error['detail']);
        },
      });
  }

  hasRemoval(budgetRoomItem: BudgetRoomItem) {
    return budgetRoomItem.is_to_remove;
  }

  hasInstall(budgetRoomItem: BudgetRoomItem) {
    return budgetRoomItem.is_to_install;
  }

  toggleRemoval(budgetRoom: BudgetRoom, budgetRoomItem: BudgetRoomItem) {
    budgetRoomItem.is_to_remove = !budgetRoomItem.is_to_remove;
    this.saveBudgetRoomItem(budgetRoom, budgetRoomItem);
  }

  toggleInstall(budgetRoom: BudgetRoom, budgetRoomItem: BudgetRoomItem) {
    budgetRoomItem.is_to_install = !budgetRoomItem.is_to_install;
    if (this.products.length > 0) {
      let product = this.products.at(0);
      this.loadingProducts[product!.id] = true;
    }
    this.saveBudgetRoomItem(budgetRoom, budgetRoomItem);
  }

  saveBudgetRoomItem(budgetRoom: BudgetRoom, budgetRoomItem: BudgetRoomItem) {
    this.budgetService
      .saveBudgetRoomItem(this.budget.id, budgetRoom.id, budgetRoomItem)
      .subscribe({
        next: (response) => {
          this.fetchBudgetProducts();
        },
        error: (error) => {
          this.toastService.error(error['detail']);
        },
      });
  }

  updateProductEstimatedPrice(budgetRoom: BudgetRoom, product: Product) {
    if (product.minimum_value_estimated === '') {
      product.minimum_value_estimated = null;
    }

    if (product.maximum_value_estimated === '') {
      product.maximum_value_estimated = null;
    }

    this.budgetService
      .updateProductEstimateValue(budgetRoom.id, product)
      .subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error) => {
          console.log(error);
        },
      });
    this.fetchProductOptions(product);
  }

  confirmRemoval(budgetRoom: BudgetRoom) {
    this.budgetRoomToBeRemoved = budgetRoom;
    this.confirmationModal.open();
  }

  removeBudgetItem() {
    if (this.budgetRoomToBeRemoved) {
      this.budgetService
        .removeBudgetRoom(this.budget.id, this.budgetRoomToBeRemoved!.id)
        .subscribe({
          next: () => {
            this.toastService.success({ detail: 'Ambiente removido!' });
            this.budgetRooms = this.budgetRooms.filter(
              (el) => el.id != this.budgetRoomToBeRemoved?.id
            );
            this.onRemoveBudgetRoom.emit(this.budgetRoomToBeRemoved);
            this.selectedRoom = undefined;
            this.confirmationModal.close();

            if (this.budgetRooms.length == 0) {
              this.closeModal();
            }
          },
          error: (error) => {
            this.toastService.error({ detail: error['detail'] });
          },
        });
    }
  }

  scrollToBudgetRoom(budgetRoom: BudgetRoom) {
    let destination = document.getElementById(`budget-room-${budgetRoom.id}`);
    if (destination) {
      this.budgetRoomsContainer.nativeElement.scroll({
        top: destination.offsetTop - 309 + 25,
        behavior: 'smooth',
      });
    }
  }

  onScroll(ev: any) {
    let scrollTop = ev.target.scrollTop;
  }

  convertUnitOfMeasurement(unitOfMeasurement: string) {
    if (unitOfMeasurement === 'LINEAR_METER') {
      return 'm linear';
    } else if (unitOfMeasurement === 'SQUARE_METER') {
      return 'm²';
    } else if (unitOfMeasurement === 'UNIT') {
      return 'unidade';
    }

    return 'm';
  }

  isNotEmpty(data: any): boolean {
    return data && data.toString().length > 0;
  }

  nIsVisible(product: Product) {
    let isFilled =
      this.isNotEmpty(product.minimum_value_estimated) ||
      this.isNotEmpty(product.maximum_value_estimated);
    return isFilled;
  }

  isVisible(budgetRoom: BudgetRoom, product: Product) {
    let isFilled =
      this.isNotEmpty(product.minimum_value_estimated) ||
      this.isNotEmpty(product.maximum_value_estimated);
    let isCurrentProductType =
      this.selectedProductTypeIdentificator?.startsWith(
        budgetRoom.id.toString()
      );
    return isFilled && isCurrentProductType;
  }

  setSelectedProductType(
    budgetRoom: BudgetRoom,
    productType: ProductType,
    index: number
  ) {
    let products = budgetRoom.product_types[index].products;
    products.forEach((product: Product) => this.fetchProductOptions(product));
    this.scrollToId(`budget-room-item-${budgetRoom.id}-${index}`);
    let identifier = `${budgetRoom.id}_${productType.name}`;
    if (this.selectedProductTypeIdentificator == identifier) {
      this.selectedProductType = undefined;
      this.selectedProductTypeIdentificator = undefined;
    } else {
      this.selectedProductType = productType;
      this.selectedProductTypeIdentificator = identifier;
    }
  }

  scrollToId(id: string) {
    setTimeout(() => {
      let destination = document.getElementById(id);
      if (destination) {
        this.budgetRoomsContainer.nativeElement.scroll({
          top: destination.offsetTop - 309 + 25,
          behavior: 'smooth',
        });
      }
    }, 200);
  }

  isSelectedProductType(budgetRoom: BudgetRoom, productType: ProductType) {
    return (
      this.selectedProductTypeIdentificator ==
      `${budgetRoom.id}_${productType.name}`
    );
  }
}
