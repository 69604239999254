<div
  class="budget property-type"
  *ngIf="!loading && !sending; else loadingTemplate">
  <h2 class="budget__title">
    <button
      class="max-md:hidden flex p-4 bg-athens-gray text-woodsmoke rounded-3xl aspect-square"
      [routerLink]="backButton">
      <i class="icon icon-caret-left text-[1.4rem] mt-[-.3rem]"></i>
    </button>
    Qual o tipo do imóvel?
  </h2>

  <div class="budget__content" *ngIf="!loading">
    <div class="options">
      <div
        class="option cursor-default opacity-60"
        [class.selected]="budget.property_type == 'CORPORATE'">
        <i class="icon icon-cones"></i>
        <div class="flex flex-col items-start">
          <span class="title">Corporativo</span>
          <span class="subtitle">Em breve!</span>
        </div>
      </div>

      <div
        class="option cursor-default opacity-60"
        [class.selected]="budget.property_type == 'RETAIL'">
        <i class="icon icon-tend"></i>
        <div class="flex flex-col items-start">
          <span class="title">Varejo</span>
          <span class="subtitle">Em breve!</span>
        </div>
      </div>

      <div
        class="option cursor-pointer"
        [class.selected]="budget.property_type == 'RESIDENTIAL'"
        (click)="onSelect('RESIDENTIAL')">
        <i class="icon icon-house"></i>
        <div class="flex flex-col items-start">
          <span class="title">Residencial</span>
          <span class="subtitle">Apartamentos</span>
        </div>
      </div>

      <div
        class="option cursor-default opacity-60"
        [class.selected]="budget.property_type == 'CONDOMINIUM'">
        <i class="icon icon-building"></i>
        <div class="flex flex-col items-start">
          <span class="title">Condominial</span>
          <span class="subtitle">Em breve!</span>
        </div>
      </div>
    </div>

    <div
      class="property-details hidden"
      [class.hidden]="!budget.property_type"
      [class.flex]="budget.property_type"
      [formGroup]="formGroup">
      <h3 class="text-[2rem] font-semibold text-woodsmoke">
        Detalhes do imóvel
        <span class="text-cadet-blue text-[1.6rem]"
          >(<span class="text-cardinal">*</span>)</span
        >
      </h3>

      <div class="flex flex-col md:flex-row gap-10 form w-full">
        <div
          class="form-group form-group--inner-label"
          [class.error]="
            formGroup.get('zipcode')?.invalid &&
            formGroup.get('zipcode')?.touched
          ">
          <label for="">Região do imóvel</label>
          <input
            type="text"
            placeholder="Digite o CEP"
            mask="00000-000"
            formControlName="zipcode" />
          <app-input-error
            [fg]="formGroup"
            controlName="zipcode"></app-input-error>
        </div>
      </div>

      <div class="flex flex-col md:flex-row gap-10 form w-full m-auto">
        <div
          class="form-group form-group--inner-label"
          [class.error]="
            formGroup.get('property_year')?.invalid &&
            formGroup.get('property_year')?.touched
          ">
          <label for="">Ano do imóvel</label>
          <input type="text" mask="0000" formControlName="property_year" />
        </div>

        <div
          class="form-group form-group--inner-label"
          [class.error]="
            formGroup.get('property_size')?.invalid &&
            formGroup.get('property_size')?.touched
          ">
          <label for="">Tamanho do imóvel (m²)</label>
          <input type="text" mask="9*" formControlName="property_size" />
          <app-input-error
            [fg]="formGroup"
            controlName="property_size"></app-input-error>
        </div>
      </div>
    </div>

    <button
      [disabled]="sending || !shouldProceed()"
      [class.bg-schooner]="!shouldProceed()"
      class="button bg-prairie-sand text-white w-full"
      (click)="onSubmit()">
      <span>Avançar</span>
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
