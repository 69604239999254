import { Component, ViewChild } from '@angular/core';
import { Budget } from '../../../core/models/budget.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '../../../core/services/budget.service';
import { NgToastService } from 'ng-angular-popup';
import { BudgetRoom } from '../../../core/models/budget-room.model';
import { ConfirmationModalComponent } from '../../../components/confirmation-modal/confirmation-modal.component';
import { Room } from '../../../core/models/room.model';

@Component({
  selector: 'app-room-settings',
  templateUrl: './room-settings.component.html',
  styleUrls: ['./room-settings.component.scss'],
  host: {
    class: 'w-full',
  },
})
export class RoomSettingsComponent {
  @ViewChild('confirmationModal')
  confirmationModal!: ConfirmationModalComponent;
  @ViewChild('newRoomModal') newRoomModal!: ConfirmationModalComponent;
  @ViewChild('editRoomNameModal')
  editRoomNameModal!: ConfirmationModalComponent;

  backButton = 'valor-estimado';
  currentStep = { step: 4, label: 'Execuções e Produtos' };

  budget: Budget = {} as Budget;
  budgetRooms: BudgetRoom[] = [];
  roomNames: string[] = [];
  selectedRoomName?: string;
  selectedRoom?: BudgetRoom | undefined;

  fetchingBudgetRooms = false;
  fetchingBudget = false;
  fetchingRooms = false;

  budgetRoomToBeRemoved?: BudgetRoom;
  rooms: Room[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private budgetService: BudgetService,
    private toastService: NgToastService
  ) {
    this.activatedRoute.parent?.params.subscribe((params) => {
      if ('id' in params) {
        this.fetchingBudget = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.fetchingBudget = false;
            this.budget = response;
            this.fetchBudgetRooms(this.budget.id);
            this.fetchRooms();
          },
          error: (error) => {
            this.fetchingBudget = false;
            console.error(error);
          },
        });
      }
    });
  }

  get loading() {
    return (
      this.fetchingBudgetRooms || this.fetchingRooms || this.fetchingBudget
    );
  }

  get completedRoomNames() {
    let completed = 0;
    this.budgetRooms.forEach((_) => {
      if (this.budgetRoomIsValid(_)) {
        completed += 1;
      }
    });
    return completed;
  }

  fetchRooms() {
    this.fetchingRooms = true;
    this.budgetService.listRooms(this.budget.property_type).subscribe({
      next: (response) => {
        this.rooms = response;
        this.rooms.forEach((el) => (el.quantity = el.quantity ?? 0));
        this.fetchingRooms = false;
      },
      error: (error) => {
        this.toastService.error({ detail: error['detail'] });
        this.fetchingRooms = false;
      },
    });
  }

  fetchBudgetRooms(budgetId: number) {
    this.fetchingBudgetRooms = true;
    this.budgetService.listBudgetRooms(budgetId).subscribe({
      next: (response) => {
        this.budgetRooms = response;
        this.roomNames = [];
        this.budgetRooms.forEach((el) => {
          if (!this.roomNames.includes(el.room__name)) {
            this.roomNames.push(el.room__name);
          }
        });
        this.fetchingBudgetRooms = false;
      },
      error: (error) => {
        this.toastService.error({
          detail:
            'Não conseguimos listar os ambientes do seu orçamento. Tente de novo.',
        });
        console.error(error);
        this.fetchingBudgetRooms = false;
      },
    });
  }

  getPropertyTypeIcon(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'icon-cones';
    } else if (propertyType == 'RETAIL') {
      return 'icon-tend';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'icon-house';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'icon-building';
    }
    return '';
  }

  getPropertyTypeName(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'Corporativo';
    } else if (propertyType == 'RETAIL') {
      return 'Varejo';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'Residencial';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'Condominial';
    }
    return '';
  }

  toggleSelectedBudgetRoomName(roomName: string) {
    if (this.selectedRoomName != roomName) {
      this.selectedRoomName = roomName;
    } else {
      this.selectedRoomName = undefined;
    }
  }

  filterByRoomName(name?: string) {
    return this.budgetRooms.filter((el) => el.room__name == name);
  }

  @ViewChild('budgetRoomNameInput') budgetRoomNameInput: any;
  @ViewChild('budgetRoomSquareMeterInput') budgetRoomSquareMeterInput: any;

  add(room: Room) {
    room.quantity = (room.quantity ?? 0) + 1;
  }

  remove(room: Room) {
    if ((room.quantity ?? 0) > 0) {
      room.quantity = (room.quantity ?? 0) - 1;
    }
  }

  addRoom(room: Room) {
    room.quantity = (room.quantity ?? 0) + 1;
    let roomsAmount = 0;
    this.rooms.forEach((el) => (roomsAmount += el.quantity ?? 0));
    this.budget.rooms = this.rooms;
    this.budgetService.roomsStep(this.budget).subscribe({
      next: (response) => {
        this.rooms.forEach((el) => (el.quantity = 0));
        this.fetchBudgetRooms(this.budget.id);
      },
      error: (error) => {
        this.toastService.error({
          detail: 'Tivemos um erro ao salvar seus dados.',
        });
      },
    });
  }

  addRooms() {
    let roomsAmount = 0;
    this.rooms.forEach((el) => (roomsAmount += el.quantity ?? 0));

    if (roomsAmount < 1) {
      this.toastService.error({ detail: 'Adicione pelo menos um ambiente' });
      return;
    }

    this.budget.rooms = this.rooms;
    this.budgetService.roomsStep(this.budget).subscribe({
      next: (response) => {
        this.newRoomModal.close();
        this.rooms.forEach((el) => (el.quantity = 0));
        this.fetchBudgetRooms(this.budget.id);
      },
      error: (error) => {
        this.toastService.error({
          detail: 'Tivemos um erro ao salvar seus dados.',
        });
      },
    });
  }

  removeBudgetRoom(budgetRoom: BudgetRoom) {
    this.budgetRooms = this.budgetRooms.filter((el) => el != budgetRoom);
    if (this.budgetRooms.length == 0) {
      this.roomNames = this.roomNames.filter(
        (el) => el != budgetRoom.room__name
      );
    }
  }

  budgetRoomIsValid(budgetRoom: BudgetRoom): boolean {
    return budgetRoom.square_meter != undefined;
  }

  shouldGoNext() {
    return !this.budgetRooms.some((room) => !room.square_meter);
  }

  goNext() {
    if (this.completedRoomNames < this.roomNames.length) {
      this.toastService.error({
        detail: 'Preencha a metragem de todos os ambientes',
      });
      return;
    }
    this.budgetService.changeStepToSummary(this.budget.id).subscribe({
      next: (response) => {
        this.router.navigate([`/d/orcamento/${this.budget.id}/resumo`]);
      },
      error: (error) => {
        this.toastService.error({ detail: error.error['detail'] });
      },
    });
  }
}
