<div class="navbar">
  <div class="logo">
    <img src="assets/images/new_logo.png" />

    <span>orçamentaria</span>
  </div>

  <div class="content">
    <div class="left-side" [class]="{ '!flex': isMenuOpened }">
      <a href="/cliente/#como-funciona"><span>Como funciona</span></a>
      <a href="/cliente/#o-que-inclui"><span>O que inclui</span></a>
    </div>

    <div class="right-side">
      <i class="menu-navbar icon icon-menu" (click)="toggleNavbar()"></i>

      <button
        class="!bg-crater-brown lg:block hidden"
        (click)="loginOrStartBudget()">
        Comece um orçamento
      </button>
    </div>
  </div>
</div>

<div class="presentation">
  <h3>
    Monte o orçamento para sua obra de forma fácil, completa e personalizada
  </h3>

  <button (click)="loginOrStartBudget()">Comece um orçamento</button>

  <img
    class="absolute top-0 left-0 hidden lg:block"
    src="assets/images/background_header_biglogo_left_blue.png" />
  <img
    class="absolute top-0 right-0 hidden lg:block"
    src="assets/images/background_header_biglogo_right_blue.png" />
</div>

<div class="how-works" id="como-funciona">
  <h2 class="pt-[5rem]">Como funciona</h2>

  <div class="explanation-container">
    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Preencha os dados da sua obra</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Detalhe os ambientes e as execuções</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Escolha os produtos</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Pronto! Orçamento realizado</span>
    </div>
  </div>
</div>

<div class="wrapper-summary">
  <div>
    <img
      class="block lg:hidden"
      src="../../../../assets/images/summary_bg_mobile.png" />
    <img
      class="hidden lg:block"
      src="../../../../assets/images/summary_bg_desk.png" />
  </div>
</div>

<div class="max-w-full bg-prairie-sand">
  <div
    class="pb-[6rem] container mx-auto text-center functionalities items-center flex flex-col gap-[4rem]">
    <h3 class="what-included" id="o-que-inclui">
      O que está incluso no orçamento dos arquitetos e seus clientes?
    </h3>

    <div class="tabs" #tabs>
      <div class="items" [class.show]="currentTab == 1">
        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Mão de obra e material básico</h6>
          <p>
            De acordo com os detalhes dos seus ambientes, tamanhos e produtos
            escolhidos, te oferecemos a melhor estimativa para realizar sua obra
          </p>
        </div>

        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Equipe de Engenharia</h6>
          <p>
            Temos suporte de equipe de engenharia experiente no mercado e os
            orçamentos são baseados em valores reais
          </p>
        </div>

        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Acesso aos Fornecedores</h6>
          <p>
            Te conectamos com os melhores fornecedores para realizar suas
            necessidades
          </p>
        </div>
      </div>
    </div>

    <div class="justify-center">
      <button
        class="!text-prairie-sand !bg-white"
        (click)="loginOrStartBudget()">
        Comece um orçamento
      </button>
    </div>
  </div>
</div>

<app-bottom-sheet #registerComponent>
  <app-register
    [loginComponent]="loginComponent"
    [registerComponent]="registerComponent"></app-register>
</app-bottom-sheet>

<app-bottom-sheet #loginComponent>
  <app-login
    [loginComponent]="loginComponent"
    [registerComponent]="registerComponent"></app-login>
</app-bottom-sheet>
