import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, Form, FormControl, FormControlName, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input({required: true}) fg!: FormGroup;
  @Input({required: true}) controlName!: string;

  get formControl(): AbstractControl | null {
    return this.fg.get(this.controlName);
  }

  hasErrors(): boolean {
    if (!this.formControl)
      return false;

    if (!this.formControl!.touched)
      return false;

    return this.formControl!.invalid;
  }
}
