<app-navigation #appNavigation></app-navigation>

<div class="presentation">
    <h2>
      Composição de custos completa de maneira clara, objetiva e
      em minutos
    </h2>

    <a target="_blank" href="https://wa.me/5511948182998?text=Ol%C3%A1%2C%20tenho%20interesse%20em%20saber%20mais%20sobre%20os%20planos%20para%20arquiteto.">Contratar um plano<i class="icon icon-whatsapp"></i></a>
</div>

<div class="greetings">
  <div class="image-container">
    <img class="w-full block lg:hidden" src="../../../../assets/images/image_greetings.png" alt="imagem_mobile">
  </div>

  <div class="text2">
    <p class="title">Conheça os motivos para contratar</p>

    <div class="text-container">
      <i class="icon icon-house"></i>

      <div class="box">
        <span>Ganhe rapidez</span>

        <p>Crie e dispare rapidamente suas composições de custo para seus clientes </p>
      </div>
    </div>

    <div class="text-container">
      <i class="icon icon-house"></i>

      <div class="box">
        <span>Capte novos clientes</span>

        <p>Encontre clientes que estão orçando e procurando por serviços de arquitetura na sua região</p>
      </div>
    </div>

    <div class="text-container">
      <i class="icon icon-house"></i>

      <div class="box">
        <span>Construa parcerias</span>

        <p>Te conectamos a fornecedores de produtos e serviços, qualificados de acordo com os suas exigências</p>
      </div>
    </div>
  </div>
</div>

<div class="packages-wrapper">
  <p class="mb-[6.4rem] tracking-tight text-[3.2rem] text-center font-semibold">
    Conheça nossos Planos
  </p>

  <div class="packages">

    <div class="package-container">
      <span
        class="my-[1.2rem] px-[1.2rem] py-[0.6rem] text-[1.2rem]/[1.33] rounded-[9rem] font-semibold bg-athens-gray">
        Recomendado
      </span>

      <p class="title"> Plano 01</p>

      <p class="price">Por apenas <b>R$ 170,00 por mês</b> ou <b>R$ 1.540,00 por ANO</b>, você terá:</p>

      <div class="package-body">
        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Perfil do cliente ideal no seu e-mail!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Conexão direta com novos fornecedores filtrados de acordo com suas exigências!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Composição de custos em minutos!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Acesso livre para editar seus orçamentos a qualquer instante!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Histórico de todas as composições de custo!</p>
        </div>

        <div class="composition border-none">
          <i class="icon-check-rounded"></i>
          <p>Quantidade ilimitada de orçamentos</p>
        </div>
      </div>
    </div>

    <div class="package-container">
      <p class="title"> Plano 02</p>

      <p class="price">Por apenas <b>R$ 55,00 por mês</b> ou <b>R$ 550,00 por ANO</b>, você terá:</p>

      <div class="package-body">
        <div class="composition not-include">
          <i class="icon-minus"></i>
          <p>Perfil do cliente ideal no seu e-mail!</p>
        </div>

        <div class="composition not-include">
          <i class="icon-minus"></i>
          <p>Conexão direta com novos fornecedores filtrados de acordo com suas exigências!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Composição de custos em minutos!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Acesso livre para editar seus orçamentos a qualquer instante!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Histórico de todas as composições de custo!</p>
        </div>

        <div class="composition border-none">
          <i class="icon-check-rounded"></i>
          <p>Quantidade ilimitada de orçamentos</p>
        </div>
      </div>
    </div>

    <div class="package-container">
      <p class="title"> Plano 03</p>

      <p class="price">Ou <b>R$ 10,00 por orçamento</b> você terá:</p>

      <div class="package-body">
        <div class="composition not-include">
          <i class="icon-minus"></i>
          <p>Perfil do cliente ideal no seu e-mail!</p>
        </div>

        <div class="composition not-include">
          <i class="icon-minus"></i>
          <p>Conexão direta com novos fornecedores filtrados de acordo com suas exigências!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Composição de custos em minutos!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Acesso livre para editar seu orçamento a qualquer instante!</p>
        </div>

        <div class="composition">
          <i class="icon-check-rounded"></i>
          <p>Histórico de todas as composições de custo!</p>
        </div>

        <div class="composition not-include border-none">
          <i class="icon-minus"></i>
          <p>Quantidade ilimitada de orçamentos</p>
        </div>
      </div>
    </div>
  </div>
</div>


