import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProductType } from '../models/product-type.model';
import { Observable } from 'rxjs';
import { Company } from '../models/company.model';
import { Pagination } from '../models/pagination.model';
import { ProductOption } from '../models/product-option.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  get(companyId: number): Observable<Company> {
    return this.http.get<Company>(
      `${environment.API_URL}/companies/${companyId}/`
    );
  }

  update(company: Company): Observable<Company> {
    return this.http.patch<Company>(
      `${environment.API_URL}/companies/${company.id}/`,
      company
    );
  }

  listCompanies(): Observable<Pagination<Company>> {
    return this.http.get<Pagination<Company>>(
      `${environment.API_URL}/companies/`
    );
  }

  listProductTypes(companyId: number): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(
      `${environment.API_URL}/companies/${companyId}/product_types/`
    );
  }

  updateProductTypes(companyId: number, productType: ProductType) {
    return this.http.patch(
      `${environment.API_URL}/companies/${companyId}/product_types/${productType.id}/`,
      productType
    );
  }

  listCompanyProducts(companyId: number): Observable<ProductOption[]> {
    return this.http.get<ProductOption[]>(
      `${environment.API_URL}/companies/${companyId}/products/`
    );
  }

  uploadProfileImage(accountId: number, image: File): Observable<Company> {
    let data = new FormData();
    data.append('image', image);
    return this.http.patch<Company>(
      `${environment.API_URL}/accounts/${accountId}/company_profile_image/`,
      data
    );
  }
}
