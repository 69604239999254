<div class="container flex flex-col md:flex-row">
  <aside class="flex flex-col gap-[4rem] hidden sticky top-0 left-0 w-80 h-screen p-4 px-6">
    <ul>
      <li [class]="{ active: currentStep.step == 1, past: currentStep.step > 1 }"
        (click)="navigateTo('tipo-de-execucao', 1)">
        <span class="bullet" *ngIf="currentStep.step <= 1">1</span>
        <span class="bullet" *ngIf="currentStep.step > 1">
          <i class="icon icon-check"></i>
        </span>
        Execução
      </li>

      <li [class]="{ active: currentStep.step == 2, past: currentStep.step > 2 }"
        (click)="navigateTo('tipo-do-imovel', 2)">
        <span class="bullet" *ngIf="currentStep.step <= 2">2</span>
        <span class="bullet" *ngIf="currentStep.step > 2">
          <i class="icon icon-check"></i>
        </span>
        Imóvel
      </li>

      <li [class]="{ active: currentStep.step == 3, past: currentStep.step > 3 }"
        (click)="navigateTo('valor-estimado', 3)">
        <span class="bullet" *ngIf="currentStep.step <= 3">3</span>
        <span class="bullet" *ngIf="currentStep.step > 3">
          <i class="icon icon-check"></i>
        </span>
        Valor estimado
      </li>

      <li [class]="{ active: currentStep.step == 4, past: currentStep.step > 4 }"
        (click)="navigateTo('configurar-ambientes', 4)">
        <span class="bullet" *ngIf="currentStep.step <= 4">4</span>
        <span class="bullet" *ngIf="currentStep.step > 4">
          <i class="icon icon-check"></i>
        </span>
        Execuções e Produtos
      </li>

      <li [class]="{ active: currentStep.step == 5, past: currentStep.step > 5 }" (click)="navigateTo('resumo', 5)">
        <span class="bullet" *ngIf="currentStep.step <= 5">5</span>
        <span class="bullet" *ngIf="currentStep.step > 5">
          <i class="icon icon-check"></i>
        </span>
        Resumo
      </li>

      <li [class]="{ active: currentStep.step == 6, past: currentStep.step > 6 }"
        (click)="navigateTo('meu-orcamento', 6)">
        <span class="bullet" *ngIf="currentStep.step <= 6">6</span>
        <span class="bullet" *ngIf="currentStep.step > 6">
          <i class="icon icon-check"></i>
        </span>
        Meu orçamento
      </li>
    </ul>

    <div class="flex flex-col justify-center">
      <p class="text-center text-[1.2rem]">Precisando de seguro para sua reforma?</p>

      <a class="tortato-logo" href="https://orcamentaria.com.br/bmg" target="_blank">
        <img src="assets/images/bmg_logo.png" alt="bmg_logo"/>
      </a>

    </div>

    <div class="flex flex-col justify-center">
      <p class="text-center text-[1.2rem]">Precisando de crédito?</p>

      <a href="https://wa.me/send?phone=554196207856" target="_blank">
        <div class="tortato-button">
          <i class="icon icon-whatsapp whatsapp"></i>

          <p>Fale conosco</p>
        </div>
        <a class="tortato-logo" href="https://www.tortatoconsorcio.com.br/" target="_blank">
          <img src="assets/images/tortato_logo.png" alt="tortato_logo" />
        </a>
      </a>
    </div>
  </aside>

  <div class="mobile-step">
    <i class="icon icon-caret-left text-cadet-blue p-5" (click)="navigateTo(backButton, currentStep.step - 1)"></i>

    <div class="step">
      <span class="bullet">{{ currentStep.step }}</span>
      <span class="step__label">{{ currentStep.label }}</span>
      <span class="step__progress">{{ currentStep.step }}/7</span>
    </div>
  </div>

  <router-outlet (activate)="onActivate($event)" #routerOutlet></router-outlet>
</div>
