import { Component } from '@angular/core';
import { BudgetRoom } from '../../../core/models/budget-room.model';
import { BudgetService } from '../../../core/services/budget.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';
import { Budget } from '../../../core/models/budget.model';
import { ProductType } from '../../../core/models/product-type.model';
import { Product } from '../../../core/models/product.model';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent {
  backButton = 'ambientes';
  currentStep = { step: 5, label: 'Configurar produtos' };

  roomNames: string[] = [];
  selectedRoom?: BudgetRoom | undefined;
  productTypes: ProductType[] = [];

  budgetRooms: BudgetRoom[] = [];

  budget!: Budget;

  fetchingBudget = false;
  fetchingBudgetRooms = false;

  constructor(
    private router: Router,
    private budgetService: BudgetService,
    private toastService: NgToastService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.parent?.params.subscribe((params) => {
      if ('id' in params) {
        this.fetchingBudget = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.fetchingBudget = false;
            this.budget = response;
            console.log(this.budget);
            this.fetchBudgetRooms(this.budget.id);
            // this.fetchRooms();
          },
          error: (error) => {
            this.fetchingBudget = false;
            console.error(error);
          },
        });
      }
    });
  }

  get loading(): boolean {
    return this.fetchingBudget || this.fetchingBudgetRooms;
  }

  fetchBudgetRooms(budgetId: number) {
    this.fetchingBudgetRooms = true;
    this.budgetService.listBudgetRooms(budgetId).subscribe({
      next: (response) => {
        this.budgetRooms = response;
        this.roomNames = [];
        this.budgetRooms.forEach((el) => {
          if (!this.roomNames.includes(el.room__name)) {
            this.roomNames.push(el.room__name);
          }
        });
        this.fetchingBudgetRooms = false;
      },
      error: (error) => {
        this.toastService.error({
          detail:
            'Não conseguimos listar os ambientes do seu orçamento. Tente de novo.',
        });
        console.error(error);
        this.fetchingBudgetRooms = false;
      },
    });
  }

  getPropertyTypeIcon(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'icon-cones';
    } else if (propertyType == 'RETAIL') {
      return 'icon-tend';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'icon-house';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'icon-building';
    }
    return '';
  }

  getPropertyTypeName(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'Corporativo';
    } else if (propertyType == 'RETAIL') {
      return 'Varejo';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'Residencial';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'Condominial';
    }
    return '';
  }

  filterByRoomName(name: string) {
    return this.budgetRooms.filter((el) => el.room__name == name);
  }

  toggleSelectedBudgetRoom(budgetRoom: BudgetRoom) {
    this.budgetService.listBudgetRoomProducts(budgetRoom.id).subscribe({
      next: (response) => {
        if (this.selectedRoom != budgetRoom) {
          this.selectedRoom = budgetRoom;
          this.productTypes = response.product_types;
          window.scroll({ top: 0, behavior: 'smooth' });
        } else {
          this.selectedRoom = undefined;
        }
      },
      error: (error) => {
        this.toastService.error({
          detail:
            'Não conseguimos listar os produtos deste ambiente. Tente novamente.',
        });
      },
    });
  }

  toggleProduct(product: Product): void {
    product.selected = !product.selected;
  }

  updateProduct(product: Product): void {
    if (typeof product.minimum_value_estimated == 'string') {
      product.minimum_value_estimated = parseFloat(
        product.minimum_value_estimated
      );
    }

    if (typeof product.maximum_value_estimated == 'string') {
      product.maximum_value_estimated = parseFloat(
        product.maximum_value_estimated
      );
    }

    this.budgetService
      .updateProductEstimateValue(this.selectedRoom!.id!, product)
      .subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  goNext() {
    this.budgetService.changeStepToSummary(this.budget.id).subscribe({
      next: (response) => {
        this.router.navigate([`/d/orcamento/${this.budget.id}/resumo`]);
      },
      error: (error) => {
        this.toastService.error({ detail: error.error['detail'] });
      },
    });
  }
}
