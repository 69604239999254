import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private http: HttpClient) { }

  list(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.API_URL}/feedbacks/`);
  }

  myFeedbacks(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.API_URL}/feedbacks/mine/`);
  }

  create(feedback: {}): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}/feedbacks/`, feedback);
  }
}
