import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { SessionManagerService } from '../../core/services/session-manager.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../../public/login/login.component';
import { RegisterComponent } from '../../public/register/register.component';
import { ProductsService } from 'src/app/core/services/products.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @ViewChild('loginComponent') loginComponent!: BottomSheetComponent;
  @ViewChild('registerComponent') registerComponent!: BottomSheetComponent;

  constructor(
    public sessionManager: SessionManagerService,
    private router: Router,
    private productsService: ProductsService,
    private toastService: NgToastService
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      let stringifiedStoredNotifications =
        localStorage.getItem('storedNotifications') ?? '[]';
      let storedNotifications: { id: number; status: string }[] = JSON.parse(
        stringifiedStoredNotifications
      );

      storedNotifications.forEach((notification) => {
        this.productsService.getImportResult(notification.id).subscribe({
          next: (response) => {
            if (response.status == 'DONE_WITH_ERRORS') {
              storedNotifications = storedNotifications.filter(
                (el) => el.id != notification.id
              );
              this.toastService.warning({
                detail: 'Atenção',
                summary: 'Alguns produtos não foram importados',
                sticky: true,
              });
            } else if (response.status == 'DONE') {
              storedNotifications = storedNotifications.filter(
                (el) => el.id != notification.id
              );
              this.toastService.success({
                detail: 'Sucesso',
                summary: 'Todos os produtos foram importados',
              });
            } else if (response.status == 'ERROR') {
              storedNotifications = storedNotifications.filter(
                (el) => el.id != notification.id
              );
              this.toastService.error({
                detail: 'Erro',
                summary: 'Seus produtos não foram importados',
              });
            }

            localStorage.setItem(
              'storedNotifications',
              JSON.stringify(storedNotifications)
            );
          },
          error: (error) => {
            console.log(error);
          },
        });
      });
    }, 5000);
  }

  openBottomSheet(component: BottomSheetComponent) {
    component.open();
  }

  get logged(): boolean {
    return this.sessionManager.loggedIn;
  }

  logout(): void {
    this.sessionManager.clearSessionData();
    this.router.navigate(['/']);
  }

  get profileLink(): string {
    let url = '/d/perfil/';
    let account = this.sessionManager.getSessionData();
    if (account.company) {
      return url + account.company.id;
    } else if (account.architect) {
      return url + account.architect.id;
    } else {
      return url;
    }
  }
}
