<div class="budget product-settings">
  <h2 class="budget__title">Configurar produtos</h2>

  <div class="budget__content" *ngIf="!loading; else loadingTemplate">
    <div class="flex gap-10 w-11/12 mx-auto">
      <div
        class="box bg-alabaster md:rounded-2xl md:border md:border-athens-gray flex flex-col gap-10 mx-auto h-[90vh] sticky top-[3vh]"
        [class]="{'w-full': selectedRoom, 'w-65': !selectedRoom, 'max-md:hidden': selectedRoom}">
        <div class="flex flex-col items-start gap-2">
          <div class="flex flex-row w-full justify-between items-center">
            <div class="tag">
              <i class="icon {{ getPropertyTypeIcon(budget.property_type) }}"></i>
              {{ getPropertyTypeName(budget.property_type) }}
            </div>
          </div>

          <p class="block text-start text-[2rem] font-light text-raven">Selecione para configurar os produtos</p>
        </div>

        <div class="flex flex-col gap-8 h-[90vh] overflow-y-auto">
          <div class="rounded-2xl border border-athens-gray w-full bg-white" *ngFor="let room of roomNames">
            <div class="flex flex-col p-8 gap-5">
              <div class="flex justify-between items-center">
                <div class="flex gap-3">
                  <span
                    class="bg-athens-gray w-[3rem] rounded-xl font-semibold text-[1.4rem]/[3rem]">{{ filterByRoomName(room).length }}</span>
                  <span class="text-[2rem] font-medium">{{ room }}</span>
                </div>

                <!--                <i class="icon icon-chevron-up text-[2.4rem]"></i>-->
              </div>

              <div
                class="flex justify-between items-center bg-porcelain rounded-xl px-6 py-8 cursor-pointer"
                (click)="toggleSelectedBudgetRoom(budgetRoom)" [class.border]="selectedRoom == budgetRoom"
                *ngFor="let budgetRoom of filterByRoomName(room)">
                <span class="font-medium text-[2rem] flex flex-col items-start">{{ budgetRoom.name }}</span>

                <i class="icon icon-arrow-right-rounded text-[2rem] text-cadet-blue"
                   [class.text-woodsmoke]="selectedRoom == budgetRoom"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="box md:rounded-2xl md:border md:border-woodsmoke flex flex-col gap-5 w-full sticky top-[2.4vh] overflow-hidden"
        *ngIf="selectedRoom">
        <div class="flex justify-between items-start">
          <div class="flex flex-col items-start">
            <button class="border border-athens-gray py-1 px-3 rounded-xl text-[1.4rem] md:hidden"
                    (click)="selectedRoom = undefined">
              <i class="icon icon-arrow-left"></i>
              Voltar
            </button>

            <h3 class="text-[3.2rem] font-medium items-center">{{ selectedRoom?.name }}</h3>

            <p class="text-raven text-[2rem] text-left">Informe o valor estimado para cada item</p>
          </div>
        </div>

        <div class="flex flex-col items-start border border-athens-gray rounded-xl p-5 gap-5"
             *ngFor="let productType of productTypes">
          <div class="flex flex-col items-start">
            <h4 class="font-medium text-[2rem] text-woodsmoke">{{ productType.name }}</h4>
            <h6 class="text-[1.4rem] text-schooner">{{ productType.description }}</h6>
          </div>

          <div class="option-box" *ngFor="let product of productType.products">
            <div class="flex flex-row justify-between items-center">
              <span class="text-left flex-1 text-[1.4rem]">{{ product.name }}</span>

              <div class="flex flex-1 gap-2">
                <div class="form">
                  <div class="form-group form-group--small">
                    <label class="text-left">Mínimo</label>
                    <input type="text" [(ngModel)]="product.minimum_value_estimated" (change)="updateProduct(product)"
                           mask="separator.2" thousandSeparator="." prefix="R$ ">
                  </div>
                </div>

                <div class="form">
                  <div class="form-group form-group--small">
                    <label class="text-left">Máximo</label>
                    <input type="text" [(ngModel)]="product.maximum_value_estimated" (change)="updateProduct(product)"
                           mask="separator.2" thousandSeparator="." prefix="R$ ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex bg-white w-full py-4 sticky bottom-0">
      <button class="button button-sized bg-prairie-sand text-white" (click)="goNext()">
        Avançar
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
