import {Injectable} from '@angular/core';
import {Account} from "../models/account.model";

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  accountData: Account = {};
  loggedIn: boolean = false;

  constructor() {
    this.getSessionData();
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get isArchitect(): boolean {
    return this.loggedIn && this.getSessionData().role == 'ARCHITECT';
  }

  get isCompany(): boolean {
    return this.loggedIn && this.getSessionData().role == 'COMPANY';
  }

  get isCustomer(): boolean {
    return this.loggedIn && this.getSessionData().role == 'CUSTOMER';
  }

  get isStaff(): boolean {
    return this.loggedIn && this.getSessionData().role == 'STAFF';
  }

  getSessionData() {
    const storedAccount = localStorage.getItem('account');

    if (storedAccount != undefined) {
      this.loggedIn = true;
      this.accountData = JSON.parse(storedAccount) as Account;
    } else {
      this.loggedIn = false;
      this.accountData = {} as Account;
    }

    return JSON.parse(storedAccount!) as Account;
  }

  setSessionData(data: Account): void {
    this.loggedIn = true;
    this.accountData = data;
    localStorage.setItem('account', JSON.stringify(data));
    if (data['token']) {
      localStorage.setItem('token', data['token']);
    }
  }

  clearAccountData() {
    localStorage.removeItem('account');
  }

  clearSessionData(): void {
    this.loggedIn = false;
    localStorage.removeItem('account');
    localStorage.removeItem('token');
  }
}
