<app-navigation #appNavigation></app-navigation>



<div class="logo-container">
  <img src="/assets/images/bmg_logo.png" alt="bmg_logo">
</div>

<div class="text-container">
  <div>
    <div class="title">
      <h1>
        Previna-se contra imprevistos e assegure sua obra
      </h1>

      <p>
        Esteja protegido contra perdas financeiras causadas por acidentes, furtos, avarias de materiais, falhas de
        execução em sua reforma.
      </p>
    </div>

    <div class="contact">
      <h2>
        Contate nosso parceiro e garanta o melhor preço
      </h2>

      <a href="https://api.whatsapp.com/send/?phone=5511999670108" target="_blank">
        Enviar mensagem
      </a>

      <img src="/assets/images/rm_logo.png" alt="rm">

      <span>Victor Rodrigues <br> Cel: (11) 99967-0108</span>
    </div>
  </div>

  <div class="img-container">
    <img src="/assets/images/bmg_img.png" class="img-woodhouse">
  </div>
</div>
