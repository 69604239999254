<div class="bg-alabaster min-h-screen p-10 pt-40">
  <div class="container mx-auto md:w-1/3 xl:w-1/4">
    <div class="flex flex-col gap-14">
      <div class="flex flex-col gap-10">
        <img class="w-20 mx-auto" src="assets/images/icon.svg" alt="orçamentaria ícone">

        <div class="flex flex-col gap-5">
          <h2 class="text-center">Cadastre-se</h2>
          <p class="text-center">Por favor, insira seus dados para acessar a ferramenta.</p>
        </div>
      </div>

      <form class="form flex flex-col gap-8" *ngIf="registerFormGroup" [formGroup]="registerFormGroup">
        <div class="form-group" [formGroup]="companyFormGroup">
          <label for="company">Nome da Empresa</label>
          <input id="company" type="text" placeholder="Digite o nome da empresa" formControlName="name">
          <app-input-error [fg]="companyFormGroup" controlName="name"></app-input-error>
        </div>

        <div class="form-group" [formGroup]="companyFormGroup">
          <label for="document_number">CNPJ</label>
          <input id="document_number" type="text" mask="00.000.000/0000-00" placeholder="00.000.000/0000-00" formControlName="document_number">
          <app-input-error [fg]="companyFormGroup" controlName="document_number"></app-input-error>
        </div>

        <div class="form-group">
          <label for="name">Responsável</label>
          <input id="name" type="text" placeholder="Digite seu nome" formControlName="name">
          <app-input-error [fg]="registerFormGroup" controlName="name"></app-input-error>
        </div>

        <div class="form-group">
          <label for="email">E-mail</label>
          <input id="email" type="email" placeholder="Digite seu email" formControlName="email">
          <app-input-error [fg]="registerFormGroup" controlName="email"></app-input-error>
        </div>

        <div class="form-group">
          <label for="phone_number">Telefone</label>
          <input id="phone_number" type="text" placeholder="+55 11 90000-0000" mask="+00 00 00000-0000" [validation]="true" formControlName="phone_number">
          <app-input-error [fg]="registerFormGroup" controlName="phone_number"></app-input-error>
        </div>

        <div class="form-group">
          <label for="password">Senha</label>
          <input id="password" type="password" placeholder="*****" formControlName="password">
          <app-input-error [fg]="registerFormGroup" controlName="password"></app-input-error>
        </div>

        <div class="form-group">
          <label for="confirm_password">Confirme sua senha</label>
          <input id="confirm_password" type="password" placeholder="*****" formControlName="confirm_password">
          <app-input-error [fg]="registerFormGroup" controlName="confirm_password"></app-input-error>
        </div>

        <button type="submit" class="w-full button bg-prairie-sand text-white" *ngIf="!loading" (click)="submit()">Cadastrar</button>
        <button type="submit" class="w-full button bg-athens-gray text-woodsmoke" *ngIf="loading" (click)="submit()">Cadastrar</button>
<!--        <p class="text-center">Já tem uma conta? <a class="text-prairie-sand font-bold whitespace-nowrap">Entrar</a></p>-->
      </form>
    </div>
  </div>
</div>
