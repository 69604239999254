import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '../../../core/services/budget.service';
import { Budget } from '../../../core/models/budget.model';
import { NgToastService } from 'ng-angular-popup';
import { BudgetRoom } from '../../../core/models/budget-room.model';
import { Product } from '../../../core/models/product.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  host: {
    class: 'w-full',
  },
})
export class SummaryComponent {
  backButton = 'configurar-produtos';
  currentStep = { step: 6, label: 'Resumo' };

  budget: Budget = {} as Budget;
  budgetRooms: BudgetRoom[] = [];
  products: { [key: string]: Product[] } = {};

  fetchingBudget = false;
  fetchingRooms = false;
  fetchingProducts = false;

  wantToBeContacted = true;

  get loading() {
    return this.fetchingBudget || this.fetchingRooms;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private budgetService: BudgetService,
    private toastService: NgToastService,
    private router: Router
  ) {
    this.activatedRoute.parent?.params.subscribe((params) => {
      if ('id' in params) {
        this.fetchingBudget = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.fetchingBudget = false;
            this.budget = response;
            this.fetchRooms();
          },
          error: (error) => {
            this.fetchingBudget = false;
            console.error(error);
          },
        });
      }
    });
  }

  get roomGroups(): {
    name: string;
    amount: number;
    removal: number;
    install: number;
    products: number;
  }[] {
    let groups: {
      name: string;
      amount: number;
      removal: number;
      install: number;
      products: number;
    }[] = [];
    this.budgetRooms.forEach((el) => {
      let group = groups.find((room) => el.room__name === room.name);
      if (!group) {
        group = {
          name: el.room__name,
          amount: 0,
          removal: 0,
          install: 0,
          products: 0,
        };
      }
      group['amount'] = group['amount'] + 1;
      let installs = el.items
        .map((item) => item.is_to_install)
        .filter((_) => _).length;
      let removals = el.items
        .map((item) => item.is_to_remove)
        .filter((_) => _).length;
      group['install'] = group['install'] + installs;
      group['removal'] = group['removal'] + removals;

      let productsData = this.products[el.room__name] || [];
      let products =
        productsData.filter(
          (product) =>
            product.maximum_value_estimated || product.minimum_value_estimated
        ).length || 0;
      group.products = products;

      groups = groups.filter((room) => el.room__name != room.name);
      groups.push(group);
    });
    return groups;
  }

  fetchRooms() {
    this.fetchingRooms = true;
    this.budgetService.listBudgetRooms(this.budget.id).subscribe({
      next: (response) => {
        this.budgetRooms = response;
        this.fetchingRooms = false;
        this.fetchProducts();
      },
      error: (error) => {
        this.toastService.error(error);
        this.fetchingRooms = false;
      },
    });
  }

  fetchProducts() {
    this.budgetRooms.forEach((budgetRoom) => {
      this.fetchingProducts = true;
      this.budgetService.listBudgetRoomProducts(budgetRoom.id).subscribe({
        next: (response) => {
          response.product_types.forEach((productType) => {
            this.fillProducts(budgetRoom.room__name, productType.products);
          });
          this.fetchingProducts = false;
        },
      });
    });
  }

  fillProducts(roomName: string, products: Product[]) {
    products.forEach((product) => {
      if (product.minimum_value_estimated || product.maximum_value_estimated) {
        let data = this.products[roomName] || [];
        data.push(product);
        this.products[roomName] = data;
      }
    });
  }

  getPropertyTypeIcon(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'icon-cones';
    } else if (propertyType == 'RETAIL') {
      return 'icon-tend';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'icon-house';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'icon-building';
    }
    return '';
  }

  getPropertyTypeName(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'Corporativo';
    } else if (propertyType == 'RETAIL') {
      return 'Varejo';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'Residencial';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'Condominial';
    }
    return '';
  }

  requestMyBudget() {
    this.budgetService
      .getMyBudget(this.budget.id, this.wantToBeContacted)
      .subscribe({
        next: (response) => {
          this.router.navigate([
            `/d/orcamento/${this.budget.id}/meu-orcamento`,
          ]);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
