<nav [class.logged]="logged">
  <div class="container">
    <a routerLink="/">
      <img src="assets/images/logo-negative.svg" alt="orçamentaria" *ngIf="!logged" />
      <img src="assets/images/logo.svg" alt="orçamentaria" *ngIf="logged" />
    </a>

    <a class="block md:hidden" href="https://orcamentaria.com.br/bmg" target="_blank">
      <img class="max-w-[8rem]" src="assets/images/bmg_logo.png" alt="bmg_logo" />
    </a>

    <div class="wrapper-menu">
      <i class="icon icon-menu menu" [class]="{ '!text-woodsmoke': logged }"></i>

      <div class="links">
        <ul class="flex flex-col md:flex-row">
          <li class="item-list" *ngIf="!logged">
            <a href="/#como-funciona">Como funciona</a>
          </li>

          <li class="item-list" *ngIf="!logged">
            <a href="/#o-que-inclui">O que inclui</a>
          </li>

          <li class="item-list" *ngIf="!logged">
            <a href="/#oportunidade">Oportunidade</a>
          </li>

          <li class="item-list" *ngIf="logged && !sessionManager.isCompany">
            <a routerLink="/d/meus-orcamentos">Meus orçamentos</a>
          </li>

          <li class="item-list" *ngIf="logged && sessionManager.isStaff">
            <a routerLink="/d/staff/fornecedores">Fornecedores</a>
          </li>

          <li class="item-list" *ngIf="!logged">
            <a href="/#quem-somos">
              Quem somos
            </a>
          </li>
        </ul>

        <ul class="flex flex-col md:flex-row">
          <li class="hidden md:flex flex-row gap-[1rem]">
            <span class="flex items-center text-[1.4rem] text-end  mix-blend-normal">Precisando de seguro para sua reforma?</span>

            <i class="flex items-center icon icon-arrow-right"></i>

            <a href="https://orcamentaria.com.br/bmg" target="_blank">
              <img class="max-w-[8rem]" src="assets/images/bmg_logo.png" alt="bmg_logo" />
            </a>
          </li>

          <li class="item-list" *ngIf="!logged">
            <button class="button text-white" (click)="openBottomSheet(loginComponent)">
              Entrar
            </button>
          </li>

          <li class="item-list" *ngIf="!logged">
            <button class="button bg-tabasco" (click)="openBottomSheet(registerComponent)">
              Cadastre-se
            </button>
          </li>

          <li class="item-list" *ngIf="logged && sessionManager.isCompany">
            <a routerLink="/d/configuracoes">Configurações</a>
          </li>

          <li class="item-list" *ngIf="logged && sessionManager.isCompany">
            <a [routerLink]="
                '/d/perfil/empresa/' +
                sessionManager.getSessionData()!.company!.id
              ">Perfil</a>
          </li>

          <li class="item-list" *ngIf="
              logged &&
              sessionManager.isArchitect &&
              sessionManager.getSessionData()?.architect?.id
            ">
            <a [routerLink]="
                '/d/perfil/arquiteto/' +
                sessionManager.getSessionData()!.architect!.id
              ">Perfil</a>
          </li>

          <li class="item-list" *ngIf="logged">
            <button (click)="logout()">Sair</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<app-bottom-sheet #loginComponent>
  <app-login *ngIf="loginComponent.show" [appNavigation]="this"></app-login>
</app-bottom-sheet>

<app-bottom-sheet #registerComponent>
  <app-register *ngIf="registerComponent.show" [appNavigation]="this"></app-register>
</app-bottom-sheet>
