import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Budget } from '../models/budget.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Room } from '../models/room.model';
import { BudgetRoomItem } from '../models/budget-room-item.model';
import { BudgetRoom } from '../models/budget-room.model';
import { Pagination } from '../models/pagination.model';
import { ProductType } from '../models/product-type.model';
import { Product } from '../models/product.model';
import { ProductOption } from '../models/product-option.model';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  constructor(private http: HttpClient) {}

  get(id: string): Observable<Budget> {
    return this.http.get<Budget>(`${environment.API_URL}/budgets/${id}/`);
  }

  list(page = 1, isActive = true): Observable<Pagination<Budget>> {
    return this.http.get<Pagination<Budget>>(
      `${environment.API_URL}/budgets/?is_active=${isActive}&page=${page}`
    );
  }

  create(budget: Budget) {
    return this.http.post<Budget>(`${environment.API_URL}/budgets/`, budget);
  }

  edit(budget: Budget) {
    return this.http.patch<Budget>(
      `${environment.API_URL}/budgets/${budget.id}/`,
      budget
    );
  }

  remove(budget: Budget) {
    return this.http.delete(`${environment.API_URL}/budgets/${budget.id}/`);
  }

  editName(budget: Budget) {
    return this.http.patch<Budget>(
      `${environment.API_URL}/budgets/${budget.id}/change_name/`,
      budget
    );
  }

  propertyStep(budget: Budget): Observable<Budget> {
    return this.http.patch<Budget>(
      `${environment.API_URL}/budgets/${budget.id}/property/`,
      budget
    );
  }

  estimatedPriceStep(budget: Budget): Observable<Budget> {
    return this.http.patch<Budget>(
      `${environment.API_URL}/budgets/${budget.id}/estimated_price/`,
      budget
    );
  }

  roomsStep(budget: Budget): Observable<Budget> {
    return this.http.post<Budget>(
      `${environment.API_URL}/budgets/${budget.id}/rooms/`,
      budget
    );
  }

  listBudgetRooms(budgetId: number): Observable<BudgetRoom[]> {
    return this.http.get<BudgetRoom[]>(
      `${environment.API_URL}/budgets/${budgetId}/rooms/`
    );
  }

  listRooms(propertyType: string): Observable<Room[]> {
    return this.http.get<Room[]>(
      `${environment.API_URL}/rooms/?property_type=${propertyType}`
    );
  }

  saveBudgetRoomItem(
    budgetId: number,
    roomId: number,
    budgetRoomItem: BudgetRoomItem
  ): Observable<BudgetRoomItem> {
    return this.http.patch<BudgetRoomItem>(
      `${environment.API_URL}/budgets/${budgetId}/rooms/${roomId}/items/${budgetRoomItem.id}/`,
      budgetRoomItem
    );
  }

  editBudgetRoom(
    budgetId: number,
    budgetRoom: BudgetRoom
  ): Observable<BudgetRoom> {
    return this.http.patch<BudgetRoom>(
      `${environment.API_URL}/budgets/${budgetId}/rooms/${budgetRoom.id}/`,
      budgetRoom
    );
  }

  removeBudgetRoom(budgetId: number, budgetRoomId: number) {
    return this.http.delete(
      `${environment.API_URL}/budgets/${budgetId}/rooms/${budgetRoomId}/`
    );
  }

  changeStepToProductsSettings(budgetId: number): Observable<any> {
    return this.http.patch(
      `${environment.API_URL}/budgets/${budgetId}/products_settings/`,
      null
    );
  }

  changeStepToSummary(budgetId: number): Observable<any> {
    return this.http.patch(
      `${environment.API_URL}/budgets/${budgetId}/summary/`,
      null
    );
  }

  getMyBudget(budgetId: number, wantToBeContacted: boolean): Observable<any> {
    return this.http.patch(
      `${environment.API_URL}/budgets/${budgetId}/my_budget/`,
      { want_to_be_contacted: wantToBeContacted }
    );
  }

  listBudgetRoomProducts(
    budgetRoomId: number
  ): Observable<{ product_types: ProductType[] }> {
    return this.http.get<{
      product_types: ProductType[];
    }>(`${environment.API_URL}/budget_rooms/${budgetRoomId}/products/`);
  }

  updateProductEstimateValue(
    budgetRoomId: number,
    product: Product
  ): Observable<any> {
    return this.http.patch<any>(
      `${environment.API_URL}/budget_rooms/${budgetRoomId}/products/${product.id}/`,
      product
    );
  }

  downloadPDF(budgetId: number): Observable<{ file_url: string }> {
    return this.http.get<{ file_url: string }>(
      `${environment.API_URL}/budgets/${budgetId}/pdf_url/`
    );
  }

  getProductTypes(budgetId: number): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(
      `${environment.API_URL}/budgets/${budgetId}/product_types/`
    );
  }

  saveProductTypeEstimatedValue(
    budgetId: number,
    productType: ProductType
  ): Observable<ProductType> {
    return this.http.patch<ProductType>(
      `${environment.API_URL}/budgets/${budgetId}/product_types/${productType.id}/`,
      productType
    );
  }

  listProductOptions(
    productId: number,
    valueGte: string = '',
    valueLte: string = ''
  ): Observable<ProductOption[]> {
    return this.http.get<ProductOption[]>(
      `${environment.API_URL}/products/${productId}/options/?value__gte=${valueGte}&value__lte=${valueLte}`
    );
  }

  sendPdfToEmail(budgetId: number, email: string) {
    return this.http.post(
      `${environment.API_URL}/budgets/${budgetId}/send_email/`,
      { email }
    );
  }

  generatePayment(budgetId: number, email: string): Observable<any> {
    return this.http.post(
      `${environment.API_URL}/budgets/${budgetId}/generate_payment/`,
      { email }
    );
  }
}
