import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BudgetComponent} from "../budget.component";
import {Budget} from "../../../core/models/budget.model";
import {BudgetService} from "../../../core/services/budget.service";
import {NgToastService} from "ng-angular-popup";
import {Account} from "../../../core/models/account.model";
import {SessionManagerService} from "../../../core/services/session-manager.service";

@Component({
  selector: 'app-execution-type',
  templateUrl: './execution-type.component.html',
  styleUrls: ['./execution-type.component.scss'],
  host: {
    class: 'w-full'
  }
})
export class ExecutionTypeComponent {
  backButton = '/d/orcamento/tipo-de-execucao';
  currentStep = {step: 1, label: 'Execução'};

  selected: string = '';

  companyEstimate: boolean = false;
  technicalRetention: boolean = false;
  loading: boolean = false;

  account!: Account;

  constructor(private budgetService: BudgetService, private toastService: NgToastService, private router: Router,
              private sessionManager: SessionManagerService) {
    this.account = this.sessionManager.getSessionData();
  }

  shouldProceed(): boolean {
    return this.selected != '';
  }

  onSubmit() {
    if (this.selected == '') {
      this.toastService.error({detail: 'Selecione qual é o tipo de execução para continuar.'});
      return;
    }

    this.loading = true;

    let budget: Budget = {
      execution_type: this.selected,
      technical_retention: this.technicalRetention,
      company_estimate: this.companyEstimate
    } as Budget;
    this.budgetService.create(budget).subscribe({
      next: (response) => {
        this.router.navigate([`/d/orcamento/${response.id}/tipo-do-imovel`]);
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.toastService.error({detail: 'Erro ao salvar'});
      }
    });
  }
}
