<div class="budget estimated-price">
  <h2 class="budget__title">
    <button
      class="max-md:hidden flex p-4 bg-athens-gray text-woodsmoke rounded-3xl aspect-square"
      [routerLink]="'/d/orcamento/' + budget.id + '/tipo-do-imovel'">
      <i class="icon icon-caret-left text-[1.4rem] mt-[-.3rem]"></i>
    </button>
    Valor estimado
  </h2>
  <h3 class="budget__subtitle">
    Qual é o seu valor estimado para mão de obra e material básico?
  </h3>

  <div class="budget__content" *ngIf="!loading">
    <div
      class="box flex flex-col ring-1 ring-athens-gray text-raven rounded-2xl"
      *ngIf="account.role != 'ARCHITECT'">
      <h3 class="text-woodsmoke text-[1.8rem] font-semibold text-start mb-3">
        Arquitetura
      </h3>
      <p class="text-[1.2rem]">
        Exemplo: se deseja gastar um total de R$ 5.000,00 em seu projeto de
        arquitetura, basta inserir o valor na ferramenta abaixo
      </p>

      <ngx-slider
        [options]="architectureSliderOptions"
        [(value)]="budget.minimum_architecture_value"
        [(highValue)]="budget.maximum_architecture_value"></ngx-slider>
    </div>

    <div
      class="box flex flex-col ring-1 ring-athens-gray text-raven rounded-2xl">
      <div
        class="flex w-full justify-between items-center cursor-pointer"
        (click)="visibleBox = 'Mão de obra e material básico'">
        <div class="flex flex-col">
          <h3 class="text-woodsmoke text-[1.8rem] font-semibold text-start">
            Mão de obra e material básico
          </h3>
          <p class="text-[1.2rem] mb-3">
            Exemplo: se deseja gastar um total de R$ 50.000,00 em mão de obra e
            material básico, basta inserir o valor na ferramenta abaixo
          </p>
        </div>
        <i
          class="icon icon-chevron-up text-[2.4rem] transition-all"
          [class.rotate-180]="
            visibleBox != 'Mão de obra e material básico'
          "></i>
      </div>

      <div
        class="transition-all overflow-hidden w-full"
        [class.max-h-[0vh]]="visibleBox != 'Mão de obra e material básico'"
        [class.max-h-[100vh]]="visibleBox == 'Mão de obra e material básico'">
        <div class="w-full relative h-4">
          <p class="absolute left-0">Mínimo</p>
          <p class="absolute right-0">Máximo</p>
        </div>

        <ngx-slider
          [options]="sliderOptions"
          [(value)]="budget.minimum_price_estimated_by_user"
          [(highValue)]="budget.maximum_price_estimated_by_user"></ngx-slider>

        <div class="form flex flex-row flex-wrap gap-8">
          <div class="form-group form-group--inner-label">
            <label for="">Mínimo desejado</label>
            <div class="flex">
              <span class="text-[1.6rem] mr-1">R$</span>
              <input
                type="number"
                [valueAsNumber]="budget.minimum_price_estimated_by_user"
                (change)="setMinValue($event)"
                [step]="step" />
            </div>
          </div>

          <div class="form-group form-group--inner-label">
            <label for="">Máximo desejado</label>
            <div class="flex">
              <span class="text-[1.6rem] mr-1">R$</span>
              <input
                type="number"
                [valueAsNumber]="budget.maximum_price_estimated_by_user"
                (change)="setMaxValue($event)"
                [step]="step" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="line-separator"
      [class.line-separator--active]="categoriesCollapsed"
      (click)="toggleCategories()">
      <span>Sua estimativa por categoria</span>
      <i class="cursor-pointer icon icon-caret-right"></i>
    </div>

    <div class="collapsible" [class.collapsed]="categoriesCollapsed">
      <ng-container *ngFor="let productType of productTypes">
        <div
          class="box flex flex-col ring-1 ring-athens-gray text-raven rounded-2xl items-start mb-5"
          [class.ring-raven]="
            productType.minimum_value_estimated ||
            productType.maximum_value_estimated
          ">
          <div
            class="flex w-full justify-between items-center cursor-pointer"
            (click)="toggleVisibleBox(productType.name)">
            <div class="flex flex-col">
              <h3 class="text-woodsmoke text-[1.8rem] font-semibold text-start">
                {{ productType.name }}
              </h3>
              <p class="text-[1.2rem] mb-3">{{ productType.description }}</p>
            </div>
            <i
              class="icon icon-chevron-up text-[2.4rem] transition-all"
              [class.rotate-180]="visibleBox != productType.name"></i>
          </div>

          <div
            class="transition-all overflow-hidden w-full"
            [class.opacity-1]="visibleBox == productType.name"
            [class.opacity-0]="visibleBox != productType.name"
            [class.max-h-[0vh]]="visibleBox != productType.name"
            [class.max-h-[100vh]]="visibleBox == productType.name">
            <ngx-slider
              [options]="createSliderOptions(productType)"
              [(value)]="productType.minimum_value_estimated"
              [(highValue)]="productType.maximum_value_estimated">
            </ngx-slider>

            <div class="form flex flex-row flex-wrap gap-8 w-full">
              <div class="form-group form-group--inner-label">
                <label for="">Mínimo desejado</label>
                <div class="flex">
                  <span class="text-[1.6rem] mr-1">R$</span>
                  <input
                    type="number"
                    [valueAsNumber]="productType.minimum_value_estimated"
                    (change)="setProductTypeMinimumValue(productType, $event)"
                    [step]="step" />
                </div>
              </div>

              <div class="form-group form-group--inner-label">
                <label for="">Máximo desejado</label>
                <div class="flex">
                  <span class="text-[1.6rem] mr-1">R$</span>
                  <input
                    type="number"
                    [valueAsNumber]="productType.maximum_value_estimated"
                    (change)="setProductTypeMaximumValue(productType, $event)"
                    [step]="step" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <button
      class="button bg-prairie-sand text-white w-full"
      [disabled]="sending"
      (click)="onSubmit()">
      <span *ngIf="sending">carregando...</span>
      <span *ngIf="!sending">Avançar</span>
    </button>
  </div>
</div>
