import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { CustomerReview } from 'src/models/customer-review.model';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent {
  @ViewChild('modal') modal!: ConfirmationModalComponent;
  @ViewChild('appNavigation') appNavigation!: NavigationComponent;
  @ViewChild('tabs') tabs!: ElementRef;

  currentTab = 1;

  currentCustomerReview = 0;
  customerReviews: CustomerReview[] = [
    {
      id: 1,
      text: 'Temos usado a Orçamentaria para iniciar cada novo projeto e não podemos imaginar trabalhar sem a ferramenta',
      name: 'Lori Bryson',
      rating: 4,
      job: 'COO, Command+R',
      company: 'Web Development Agency',
    },
    {
      id: 2,
      text: 'Esta plataforma é incrível. Me ajudou demais para planejar minha obra.',
      name: 'Rodrigo Souza',
      rating: 5,
      job: 'CEO',
      company: 'Buy a Coffee',
    },
  ];

  constructor(
    private router: Router,
    private sessionManager: SessionManagerService
  ) {}

  nextCustomerReview() {
    this.currentCustomerReview += 1;
    if (this.currentCustomerReview > this.customerReviews.length - 1) {
      this.currentCustomerReview = 0;
    }
  }

  previousCustomerReview() {
    this.currentCustomerReview -= 1;
    if (this.currentCustomerReview < 0) {
      this.currentCustomerReview = this.customerReviews.length - 1;
    }
  }

  openInformationModal(): void {
    this.modal.open();
  }

  loginOrStartBudget() {
    let account = this.sessionManager.getSessionData();
    if (!account) {
      let bottomSheet = this.appNavigation.registerComponent;
      this.appNavigation.openBottomSheet(bottomSheet);
    } else {
      this.router.navigate(['/d/criar-orcamento/tipo-de-execucao']);
    }
  }

  goTo(page: number) {
    this.currentTab = page;
    // console.log(this.tabs.nativeElement);
    // let width = this.tabs.nativeElement.clientWidth;
    // let left = width * (page - 1);
    // console.log(left);
    // this.tabs.nativeElement.scroll({left});
  }
}
