import { SessionManagerService } from './../session-manager.service';
import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";

export const isStaffGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const sessionManagerService = inject(SessionManagerService);

  if (!sessionManagerService.isStaff) {
    router.navigate(['/']);
  }
  return sessionManagerService.isStaff;
};
