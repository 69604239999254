import {Component} from '@angular/core';
import {Budget} from "../../../core/models/budget.model";
import {BudgetService} from "../../../core/services/budget.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Room} from "../../../core/models/room.model";
import {NgToastService} from "ng-angular-popup";

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
  host: {
    class: 'w-full'
  }
})
export class RoomsComponent {
  backButton = 'valor-estimado';
  currentStep = {step: 4, label: 'Ambientes'};

  budget: Budget = {} as Budget;
  rooms: Room[] = [];

  fetchingBudget = false;
  fetchingRooms = false;
  fetchingBudgetRooms = false;

  hasBudgetRooms = false;

  get loading() {
    return this.fetchingBudget || this.fetchingRooms || this.fetchingBudgetRooms;
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private budgetService: BudgetService,
              private toastService: NgToastService) {
    this.activatedRoute.parent?.params.subscribe(params => {
      if ('id' in params) {
        this.fetchingBudget = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.fetchingBudget = false;
            this.budget = response;
            this.fetchBudgetRooms();
            this.fetchRooms(this.budget.property_type);
          },
          error: (error) => {
            this.fetchingBudget = false;
            console.error(error);
          }
        });
      }
    });
  }

  fetchBudgetRooms() {
    this.fetchingBudgetRooms = true;
    this.budgetService.listBudgetRooms(this.budget.id).subscribe({
      next: response => {
        this.fetchingBudgetRooms = false;
        if (response.length > 0) {
          this.hasBudgetRooms = true;
        }
      }, error: error => {
        this.fetchingBudgetRooms = false;
      }
    });
  }

  shouldProceed(): boolean {
    let roomsAmount = 0;
    this.rooms.forEach(el => roomsAmount += el.quantity ?? 0);

    return roomsAmount > 0 || this.hasBudgetRooms;
  }

  fetchRooms(propertyType: string) {
    this.fetchingRooms = true;
    this.budgetService.listRooms(propertyType).subscribe({
      next: (response) => {
        this.fetchingRooms = false;
        this.rooms = response;
        this.rooms.forEach(el => el.quantity = el.quantity ?? 0);
      },
      error: (error) => {
        this.fetchingRooms = false;
        console.error(error);
      }
    });
  }

  getPropertyTypeIcon(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'icon-cones';
    } else if (propertyType == 'RETAIL') {
      return 'icon-tend';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'icon-house';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'icon-building';
    }
    return '';
  }

  getPropertyTypeName(propertyType: string) {
    if (propertyType == 'CORPORATE') {
      return 'Corporativo';
    } else if (propertyType == 'RETAIL') {
      return 'Varejo';
    } else if (propertyType == 'RESIDENTIAL') {
      return 'Residencial';
    } else if (propertyType == 'CONDOMINIUM') {
      return 'Condominial';
    }
    return '';
  }

  add(room: Room) {
    room.quantity = (room.quantity ?? 0) + 1;
  }

  remove(room: Room) {
    if ((room.quantity ?? 0) > 0) {
      room.quantity = (room.quantity ?? 0) - 1;
    }
  }

  onSubmit() {
    // if (this.hasBudgetRooms) {
    //   this.router.navigate([`/d/orcamento/${this.budget.id}/configurar-ambientes`]);
    //   return;
    // }

    let roomsAmount = 0;
    this.rooms.forEach(el => roomsAmount += el.quantity ?? 0);

    if (roomsAmount < 1 && !this.hasBudgetRooms) {
      this.toastService.error({detail: 'Adicione pelo menos um ambiente'});
      return;
    }

    this.budget.rooms = this.rooms;
    this.budgetService.roomsStep(this.budget).subscribe({
      next: (response) => {
        this.router.navigate([`/d/orcamento/${this.budget.id}/configurar-ambientes`]);
      },
      error: (error) => {
        this.toastService.error({detail: 'Tivemos um erro ao salvar seus dados.'});
      }
    });
  }
}
