import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Budget } from '../../../core/models/budget.model';
import { BudgetService } from '../../../core/services/budget.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgToastService } from 'ng-angular-popup';
import { CepAbertoService } from 'src/app/core/services/cep-aberto.service';
import { lastValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-property-type',
  templateUrl: './property-type.component.html',
  styleUrls: ['./property-type.component.scss'],
  host: {
    class: 'w-full',
  },
})
export class PropertyTypeComponent {
  backButton = '/d/criar-orcamento/tipo-de-execucao';
  currentStep = { step: 2, label: 'Imóvel' };

  budget: Budget = {} as Budget;

  loading = false;
  sending = false;

  formGroup: FormGroup = this.fb.group({
    id: [null, [Validators.required]],
    property_type: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    property_year: [null, [Validators.required, Validators.minLength(4)]],
    property_size: [null, [Validators.required, Validators.min(20)]],
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private budgetService: BudgetService,
    private fb: FormBuilder,
    private toastService: NgToastService,
    private cepAbertoService: CepAbertoService
  ) {
    this.activatedRoute.parent?.params.subscribe((params) => {
      if ('id' in params) {
        this.loading = true;
        this.budgetService.get(params['id']).subscribe({
          next: (response) => {
            this.loading = false;
            this.budget = response;
            this.formGroup.patchValue(this.budget);
          },
          error: (error) => {
            this.loading = false;
            console.error(error);
          },
        });
      }
    });
  }

  shouldProceed(): boolean {
    return this.budget.property_type != '' && this.formGroup.valid;
  }

  onSelect(option: string) {
    this.budget.property_type = option;
    this.formGroup.patchValue({ property_type: option });
  }

  async onSubmit() {
    if (!(await this.zipcodeIsValid())) {
      this.toastService.error({
        detail: 'Erro',
        summary: 'Cep não encontrado',
      });
      return;
    }
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      this.toastService.error({
        detail: 'Selecione um tipo de imível e preencha todos os campos',
      });
      return;
    }

    let budget: Budget = this.formGroup.getRawValue() as Budget;
    this.sending = true;
    this.budgetService.propertyStep(budget).subscribe({
      next: (response) => {
        this.sending = false;
        this.router.navigate([`/d/orcamento/${budget.id}/valor-estimado`]);
      },
      error: (error) => {
        this.sending = false;
      },
    });
  }

  async zipcodeIsValid(): Promise<boolean> {
    let zipcode = this.formGroup.get('zipcode')?.value;
    this.sending = true;

    try {
      let response = await lastValueFrom(
        this.cepAbertoService.getAddressByZipcode(zipcode)
      );
      return true;
    } catch (error: any) {
      this.sending = false;
      this.formGroup
        .get('zipcode')
        ?.setErrors({ response: error.error.zipcode ?? 'Cep não encontrado' });
      return false;
    }
  }
}
