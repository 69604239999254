import { AccountService } from './../../../core/services/account.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/core/models/account.model';
import { Company } from 'src/app/core/models/company.model';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  accounts: Account[] = [];
  companies: Company[] = [];
  count: number = 0;
  pageCount: number = 1;
  isLoading: boolean = false;
  currentPage: number = 1;
  firstPageLength: number = 0;
  filteredRole: string = '';


  constructor(private companyService: CompanyService,
    private router: Router,
    private accountService: AccountService) { }

  ngOnInit(): void {
    this.fetchAccounts();
  }

  fetchAccounts(): void {
    this.isLoading = true;
    this.accountService.list(this.currentPage, this.filteredRole).subscribe({
      next: response => {
        this.isLoading = false;
        this.count = response.count;
        this.accounts = response.results;
        if (this.firstPageLength == 0) {
          this.firstPageLength = response.results.length;
        }
        this.pageCount = Math.ceil(this.count / this.firstPageLength);
      }, error: error => {
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  getDisplayActive(value: boolean | undefined): string {
    if (value)
      return 'Ativo';
    return 'Inativo';
  }

  getDisplayRole(value: string | undefined): string {
    if (value == 'ARCHITECT')
      return 'Arquiteto';
    else if (value == 'COMPANY')
      return 'Fornecedor';
    else if (value == 'CUSTOMER')
      return 'Cliente';
    else
      return 'Admin'
  }

  goToCompanyProfile(companyId?: number): void {
    this.router.navigate([`/d/perfil/empresa/`, companyId]);
  }

  getPage(page: number): void {
    this.currentPage = page;
    this.fetchAccounts();
  }

  filterByRole(role: string): void {
    this.filteredRole = role;
    this.currentPage = 1;
    this.fetchAccounts();
  }
}
