import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {LabelType, Options} from "@angular-slider/ngx-slider";
import {BudgetService} from "../../../core/services/budget.service";
import {ActivatedRoute} from "@angular/router";
import {Budget} from "../../../core/models/budget.model";
import {ConfirmationModalComponent} from "../../../components/confirmation-modal/confirmation-modal.component";
import {FeedbackService} from "../../../core/services/feedback.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgToastService} from "ng-angular-popup";
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {Account} from "../../../core/models/account.model";
import {CurrencyPipe, formatCurrency} from "@angular/common";

@Component({
  selector: 'app-budget-detail',
  templateUrl: './budget-detail.component.html',
  styleUrls: ['./budget-detail.component.scss'],
  host: {
    class: 'w-full'
  }
})
export class BudgetDetailComponent implements AfterViewInit {
  backButton = 'tipo-do-imovel';
  currentStep = {step: 8, label: 'Meu orçamento'};

  @ViewChild('yourEstimate') yourEstimate!: ElementRef;
  @ViewChild('orcamentariaEstimate') orcamentariaEstimate!: ElementRef;
  @ViewChild('togetherEstimate') togetherEstimate!: ElementRef;

  @ViewChild('feedbackModal') feedbackModal!: ConfirmationModalComponent;
  @ViewChild('paymentModal') paymentModal!: ConfirmationModalComponent;

  budget!: Budget;
  budgetId!: number;

  yourEstimateIsGreater = false;

  sliderOptions: Options = {
    floor: 0,
    ceil: 1500000,
    step: 0.01,
    disabled: true,
    translate: (value, label) => {
      let stringifiedValue = formatCurrency(value, 'pt-br', 'R$');
      return stringifiedValue;
    }
  };

  value = 0;
  highValue = 0;

  fetchingBudget = false;
  generatingPayment: boolean = false;
  showEstimateLabels = false;

  feedbackFormGroup: FormGroup = this.fb.group({
    account_id: ['', [Validators.required]],
    rating: [0, [Validators.required, Validators.min(0)]],
    text: [''],
  });

  account!: Account;

  downloadingPDF = false;

  constructor(private elements: ElementRef, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
              private budgetService: BudgetService, private feedbackService: FeedbackService,
              private toastr: NgToastService, private sessionManager: SessionManagerService) {
    this.account = this.sessionManager.getSessionData();
    this.activatedRoute.parent?.params.subscribe({
      next: params => {
        this.budgetId = params['id'];
        this.fetchBudget(this.budgetId);
      }
    });
  }

  ngAfterViewInit() {
    this.feedbackService.myFeedbacks().subscribe({
      next: response => {
        if (response.length == 0) {
          this.feedbackModal.open();
        }
      }
    });
  }

  get isLoading(): boolean {
    return this.fetchingBudget;
  }

  fetchBudget(id: number) {
    this.fetchingBudget = true;
    this.budgetService.get(id.toString()).subscribe({
      next: budget => {
        this.budget = budget;
        this.value =  budget.user_estimate_value
        this.highValue = budget.value;
        this.yourEstimateIsGreater = this.value > this.highValue;
        setTimeout((_: any) => {
          this.setEstimateLabels();
          this.showEstimateLabels = true;
        }, 500);
        this.fetchingBudget = false;
      }, error: error => {
        console.log(error);
        this.fetchingBudget = false;
      }
    });
  }

  setEstimateLabels() {
    let combinedPointer = this.elements.nativeElement.querySelector('.ngx-slider-combined');
    this.togetherEstimate.nativeElement.style.opacity = combinedPointer.style.opacity;
    this.togetherEstimate.nativeElement.style.left = combinedPointer.style.left;
    let minPointer = this.elements.nativeElement.querySelector('.ngx-slider-model-value');
    let maxPointer = this.elements.nativeElement.querySelector('.ngx-slider-model-high');
    if (this.yourEstimateIsGreater) {
      this.yourEstimate.nativeElement.style.left = maxPointer.style.left;
      this.yourEstimate.nativeElement.style.opacity = maxPointer.style.opacity;
      this.orcamentariaEstimate.nativeElement.style.left = minPointer.style.left;
      this.orcamentariaEstimate.nativeElement.style.opacity = minPointer.style.opacity;
    } else {
      this.yourEstimate.nativeElement.style.left = minPointer.style.left;
      this.yourEstimate.nativeElement.style.opacity = minPointer.style.opacity;
      this.orcamentariaEstimate.nativeElement.style.left = maxPointer.style.left;
      this.orcamentariaEstimate.nativeElement.style.opacity = maxPointer.style.opacity;
    }
    this.showEstimateLabels = true;
  }

  setRating(rating: number) {
    this.feedbackFormGroup.get('rating')?.setValue(rating);
  }

  sendFeedback() {
    this.feedbackFormGroup.get('account_id')?.setValue(this.account.id);
    let data = this.feedbackFormGroup.getRawValue();
    this.feedbackService.create(data).subscribe({
      next: response => {
        this.feedbackFormGroup.reset();
        this.feedbackModal.close();
        this.toastr.success({detail: 'Obrigado por enviar seu feedback.'});
      }, error: error => {
        this.toastr.error({detail: 'Não foi possível enviar seu feedback.'});
      }
    });
  }

  sendPDFToEmail() {
    if (this.budget.payment_status != 'PAID') {
      this.paymentModal.open();
      return;
    }

    this.budgetService.sendPdfToEmail(this.budgetId, this.account.email!).subscribe({
      next: response => {
        this.toastr.success({detail: 'O PDF foi enviado para o seu e-mail'});
      }, error: error => {
        this.toastr.error({detail: 'Houve um erro ao enviar o PDF. Tente novamente.'});
      }
    });
  }

  downloadPDF() {
    if (this.budget.payment_status != 'PAID') {
      this.paymentModal.open();
      return;
    }

    this.downloadingPDF = true;
    this.budgetService.downloadPDF(this.budgetId).subscribe({
      next: response => {
        window.open(response.file_url, '_blank');
        this.downloadingPDF = false;
      }, error: error => {
        this.toastr.error({detail: 'Não conseguimos gerar o PDF. Tente novamente.'});
        this.downloadingPDF = false;
      }
    });
  }

  openPaymentModal() {
    console.log('pagamento');
    this.paymentModal.open();
  }

  copyToClipboard(text: string) {
    try {
      navigator.clipboard.writeText(text);
      this.toastr.success({detail: 'QR Code copiado'});
    } catch (err) {
      this.toastr.error({detail: 'QR Code não foi copiado'});
    }
  }

  isExpired(expiresAt: string | Date): boolean {
    let expiresDate = Date.parse('0');
    let now = new Date().getTime();

    if (typeof expiresAt == 'string') {
      expiresDate = Date.parse(expiresAt);
    }

    return (expiresDate - now) < 0;
  }

  checkPaymentStatus(budget: Budget) {
    this.budgetService.get(budget.id.toString()).subscribe({
      next: response => {
        this.budget = response;

        if (response.payment_status == 'PAID') {
          this.toastr.success({detail: 'Pagamento realizado com sucesso'});
          this.paymentModal.close();
        } else {
          this.toastr.warning({detail: 'Ainda não identificamos seu pagamento'});
        }
      }
    });
  }

  generatePayment(budget: Budget) {
    this.generatingPayment = true;
    this.budgetService.generatePayment(budget.id, this.account.email!).subscribe({
      next: response => {
        this.generatingPayment = true;
        Object.assign(this.budget, response);
      }, error: error => {
        this.generatingPayment = true;
        this.toastr.error({detail: 'Erro ao gerar a sua fatura'});
      }
    });
  }

  applyBlurIfNeeded() {
    if (this.budget.payment_status == 'PAID') {
      let elements = document.getElementsByClassName('ngx-slider-bubble');
      for (let index = 0; index < elements.length; index++) {
        elements.item(index)?.classList.add('no-blur');
      }
    } else {
      let elements = document.getElementsByClassName('ngx-slider-bubble');
      for (let index = 0; index < elements.length; index++) {
        elements.item(index)?.classList.remove('no-blur');
      }
    }
  }

  parseFloat(value: string): number {
    value = value.replaceAll('.', '');
    value = value.split(',')[0];
    return parseFloat(value);
  }

  get userEstimatedValue(): number {
    if (this.yourEstimateIsGreater) {
      return this.highValue;
    }
    return this.value;
  }
}
