import {Component, Injectable, Input} from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
@Injectable()
export class ConfirmationModalComponent {
  showModal = false;

  open() {
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }
}
