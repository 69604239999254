<div class="budget room-settings">
  <h2 class="budget__title">Execuções e Produtos</h2>
  <h3 class="budget__subtitle">
    Configure os ambientes e produtos residenciais
  </h3>

  <div
    class="box w-full md:rounded-2xl md:ring-1 md:ring-athens-gray flex flex-col gap-10">
    <div class="flex justify-between items-center">
      <div class="tag">
        <i class="icon {{ getPropertyTypeIcon(budget.property_type) }}"></i>
        {{ getPropertyTypeName(budget.property_type) }}
      </div>
    </div>

    <div class="flex flex-col">
      <ng-container *ngFor="let room of rooms; let index = index">
        <div class="flex gap-5">
          <div
            class="room-list"
            [class.first]="index == 0"
            [class.last]="index == rooms.length - 1">
            <div
              class="flex items-center p-8 justify-between border-athens-gray"
              [class.border-b]="index != rooms.length - 1">
              <span class="font-semibold text-[2rem]">{{ room.name }}</span>

              <div class="flex text-raven text-[1.4rem] gap-5">
                <div
                  class="icon icon-plus ringed-icon border-raven cursor-pointer"
                  (click)="addRoom(room)"></div>
              </div>
            </div>
          </div>

          <div
            class="w-2/3 items-center flex gap-3 overflow-auto custom-scrollbar">
            <ng-container *ngFor="let _ of filterByRoomName(room.name)">
              <div
                class="border border-athens-gray rounded-xl px-8 py-4 hover:bg-porcelain cursor-pointer flex gap-3 justify-between items-center"
                (click)="toggleSelectedBudgetRoomName(room.name)">
                <span class="text-[2rem] font-medium text-nowrap">{{
                  _.name
                }}</span>
                <i
                  class="icon icon-check-rounded text-chateau-green text-[2.4rem]"
                  *ngIf="budgetRoomIsValid(_)"></i>
                <i
                  class="icon icon-alert-rounded text-cardinal text-[2.4rem]"
                  *ngIf="!budgetRoomIsValid(_)"></i>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <button
    class="mt-6 w-full button bg-prairie-sand text-white"
    (click)="goNext()">
    Próximo
  </button>
</div>

<app-confirmation-modal #newRoomModal>
  <h4 class="modal__title">Adicionar novo ambiente</h4>

  <div class="modal__content">
    <div class="flex flex-col border border-athens-gray rounded-2xl">
      <ng-container *ngFor="let room of rooms">
        <div
          class="flex items-center p-8 justify-between border-b border-athens-gray">
          <span class="font-semibold text-[2rem]">{{ room.name }}</span>

          <div class="flex text-raven text-[1.4rem] gap-5">
            <span
              class="icon icon-minus ringed-icon border-raven"
              [class]="{ 'cursor-pointer': room.quantity > 0 }"
              (click)="remove(room)"></span>
            <span>{{ room.quantity }}</span>
            <div
              class="icon icon-plus ringed-icon border-raven cursor-pointer"
              (click)="add(room)"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="modal__controls">
    <button
      class="button w-full bg-schooner text-white font-medium text-[1.4rem]"
      (click)="addRooms()">
      Adicionar
    </button>
  </div>
</app-confirmation-modal>

<app-room
  *ngIf="selectedRoomName"
  (onClose)="selectedRoomName = undefined"
  (onRemoveBudgetRoom)="removeBudgetRoom($event)"
  [budgetRooms]="filterByRoomName(selectedRoomName)"
  [budget]="budget"></app-room>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
