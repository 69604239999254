<ng-container *ngIf="hasErrors()">
  <p class="error">
    <span *ngIf="formControl!.getError('required')">
      Este campo é obrigatório
    </span>

    <span *ngIf="formControl!.getError('email')">
      E-mail inválido
    </span>

    <span *ngIf="formControl!.getError('differentPassword')">
      As senhas não coincidem
    </span>

    <span *ngIf="formControl!.getError('mask')">
      Campo incompleto
    </span>

    <span *ngIf="formControl!.getError('response')">
      {{ formControl!.getError('response') }}
    </span>

    <span *ngIf="formControl!.getError('min')">
      O valor mínimo deste campo é {{ formControl!.getError('min')?.min }}
    </span>
  </p>
</ng-container>
