<div class="budget budget-detail" *ngIf="!isLoading; else loadingTemplate">
  <h2 class="budget__title">
    <span class="text-center m-auto"
      >Valor de mão de obra e material básico</span
    >
  </h2>

  <div class="card mb-16">
    <table class="w-full table table-fixed text-left watermark">
      <thead class="text-woodsmoke">
        <tr class="table__heading">
          <td class="text-prairie-sand">Resumo</td>
        </tr>

        <tr class="table__title">
          <th colspan="2"></th>
          <th colspan="1" class="text-prairie-sand">Estimativa ORÇAMENTARIA</th>
          <th colspan="1" class="text-prairie-sand">Estimativa CLIENTE</th>
        </tr>
      </thead>

      <tbody class="text-tuna text-2xl">
        <tr>
          <td colspan="2">Mão de obra e material básico</td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            {{ budget.summary.total_detailed_values || "0" | currency : "R$" }}
          </td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            {{
              budget.average_price_estimated_by_user || "0" | currency : "R$"
            }}
          </td>
        </tr>

        <tr *ngFor="let productType of budget.summary.product_types">
          <td colspan="2">{{ productType.name }}</td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            <span *ngIf="productType.total_value">{{
              productType.total_value || 0 | currency : "R$"
            }}</span>
            <span *ngIf="!productType.total_value">-</span>
          </td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            {{ productType.user_estimated_value || 0 | currency : "R$" }}
          </td>
        </tr>
      </tbody>

      <tfoot class="text-tuna text-2xl font-semibold">
        <tr>
          <td colspan="2">Total</td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            {{ budget.value || 0 | currency : "R$" }}
          </td>
          <td colspan="1" [class.blur]="budget.payment_status !== 'PAID'">
            {{ userEstimatedValue || 0 | currency : "R$" }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="card mb-16">
    <table class="w-full table table-fixed text-left watermark">
      <thead class="text-woodsmoke">
        <tr class="table__heading">
          <td class="text-prairie-sand">Estimativa</td>
          <td class="flex justify-end gap-4">
            <button class="button" (click)="sendPDFToEmail()">
              Enviar PDF por e-mail
            </button>

            <button
              class="button flex gap-2 items-center"
              (click)="downloadPDF()"
              [disabled]="downloadingPDF">
              <i class="icon icon-arrow-down-rounded"></i>
              <span *ngIf="downloadingPDF">Baixando</span>
              <span *ngIf="!downloadingPDF">Baixar PDF</span>
            </button>
          </td>
        </tr>

        <tr class="table__title" *ngIf="budget.summary.architecture_value">
          <th class="text-prairie-sand">
            <small>Categoria:</small> Arquitetura
          </th>
          <th class="value" [class.blur]="budget.payment_status !== 'PAID'">
            {{ budget.summary.architecture_value || "0" | currency : "R$" }}
          </th>
        </tr>

        <tr class="table__title">
          <th class="text-prairie-sand">
            <small>Categoria:</small> mão de obra e material básico
          </th>
          <th>Valor</th>
        </tr>
      </thead>

      <tbody class="text-tuna text-2xl" *ngIf="budget">
        <tr *ngFor="let item of budget.summary.detailed_values">
          <td>{{ item.name }}</td>
          <td [class.blur]="budget.payment_status !== 'PAID'">
            {{ item.value || "0" | currency : "R$" }}
          </td>
        </tr>
      </tbody>

      <tfoot class="text-tuna text-2xl font-semibold" *ngIf="budget">
        <tr>
          <td>Total</td>
          <td>{{ budget.summary.total_detailed_values | currency : "R$" }}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="card mb-32">
    <table class="w-full table table-fixed text-left watermark">
      <thead>
        <tr class="table__heading">
          <td class="text-prairie-sand">Produtos</td>
        </tr>
      </thead>
      <tbody class="text-tuna text-2xl">
        <ng-container *ngFor="let productType of budget.summary.product_types">
          <tr class="table__title">
            <th class="text-prairie-sand">
              <small>Categoria:</small> {{ productType.name }}
            </th>
            <th
              class="select-none value"
              [class.text-pairie-sand]="budget.payment_status !== 'PAID'"
              [class.blur]="budget.payment_status !== 'PAID'">
              {{ productType.total_value || 0 | currency : "R$" }}
            </th>
          </tr>

          <tr *ngFor="let product of productType.products" class="product">
            <td>{{ product.name }}</td>
            <td
              class="select-none product-value"
              [class.text-pairie-sand]="budget.payment_status !== 'PAID'"
              [class.blur]="budget.payment_status !== 'PAID'">
              {{ product.value || 0 | currency : "R$" }}
            </td>
          </tr>
        </ng-container>
      </tbody>

      <tfoot class="text-tuna text-2xl font-semibold">
        <tr>
          <td>Total</td>
          <td>{{ budget.summary.total_products_value | currency : "R$" }}</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div
    class="sticky bottom-0 bg-white py-6"
    *ngIf="budget.payment_status !== 'PAID'">
    <div class="flex flex-col md:flex-row gap-6 items-center">
      <p class="w-full text-woodsmoke text-[1.4rem] font-medium text-center">
        Receba sua estimativa completa e editável
      </p>
      <button
        (click)="openPaymentModal()"
        class="button bg-prairie-sand text-white mx-auto w-full">
        Ver minha estimativa completa
      </button>
    </div>
  </div>

  <!--  <section class="bg-porcelain">-->
  <!--    <div class="container flex justify-between">-->
  <!--      <div class="flex flex-col justify-start">-->
  <!--        <h4 class="text-left">Enviar por email</h4>-->
  <!--        <p class="text-left">Estamos muito felizes em te ajudar. Aproveite o conteúdo da planilha, e volte sempre!</p>-->
  <!--      </div>-->

  <!--      <div class="flex flex-col form">-->
  <!--        <div class="form-group form-group&#45;&#45;row w-100">-->
  <!--          <input class="w-100 bg-white" type="email" placeholder="Digite seu email">-->
  <!--          <button class="button bg-kenyan-copper text-white">Enviar</button>-->
  <!--        </div>-->

  <!--        <p>Nós nos preocupamos com seus dados em nossa política de privacidade.</p>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
</div>

<app-confirmation-modal #paymentModal>
  <h4 class="modal__title">Pagamento via PIX</h4>
  <p class="modal__content text-left">
    Faça o pagamento e tenha acesso a sua estimativa completa.
  </p>

  <div
    class="mt-12 flex flex-col gap-3 w-full"
    *ngIf="
      budget.qr_code_base64 && !isExpired(budget.payment_expiration_datetime)
    ">
    <p
      class="text-[1.4rem] text-woodstock text-center w-11/12 md:w-6/12 mx-auto">
      Aponte a câmera do seu celular para o QR Code no aplicativo do seu banco
    </p>

    <div
      class="flex flex-col border overflow-hidden rounded-2xl w-8/12 md:w-6/12 mx-auto relative">
      <img
        class=""
        [src]="'data:image/png;base64,' + budget.qr_code_base64"
        alt="QRCode PIX" />
      <div
        class="bg-dawn-pink w-full mx-auto h-2 rounded-full overflow-hidden absolute bottom-0">
        <div class="bg-prairie-sand h-full" [style.width.%]="100"></div>
      </div>
    </div>

    <p
      class="text-[1.4rem] whitespace-nowrap overflow-x-auto w-11/12 md:w-6/12 mx-auto text-woodsmoke text-center">
      Você tem até
      {{ budget.payment_expiration_datetime | date : "HH:mm" }} para pagar
    </p>

    <p
      (click)="copyToClipboard(budget.qr_code)"
      class="cursor-pointer text-[1.2rem] bg-dawn-pink rounded-full whitespace-nowrap overflow-x-auto w-11/12 md:w-6/12 mx-auto text-woodsmoke px-4 py-2">
      {{ budget.qr_code }}
    </p>

    <button
      class="button bg-prairie-sand text-white w-full text-[1.4rem]"
      (click)="checkPaymentStatus(budget)">
      Já efetuei o pagamento
    </button>
  </div>

  <div
    class="mt-12 flex flex-col w-full"
    *ngIf="
      !budget.qr_code_base64 || isExpired(budget.payment_expiration_datetime)
    ">
    <button
      class="button bg-prairie-sand text-white w-full text-[1.4rem]"
      (click)="generatePayment(budget)"
      [disabled]="generatingPayment">
      <span *ngIf="generatingPayment">Carregando...</span>
      <span *ngIf="!generatingPayment">Gerar QR Code de pagamento</span>
    </button>
  </div>
</app-confirmation-modal>

<app-confirmation-modal #feedbackModal>
  <h4 class="modal__title">Feedback</h4>
  <p class="modal__content text-left">O que você achou da Orçamentaria?</p>

  <div class="flex my-6 gap-2 text-[1.6rem] text-cadet-blue">
    <i
      class="icon icon-star cursor-pointer"
      [class.text-selective-yellow]="
        (feedbackFormGroup.get('rating')?.value ?? 0) > 0
      "
      (click)="setRating(1)"></i>
    <i
      class="icon icon-star cursor-pointer"
      [class.text-selective-yellow]="
        (feedbackFormGroup.get('rating')?.value ?? 0) > 1
      "
      (click)="setRating(2)"></i>
    <i
      class="icon icon-star cursor-pointer"
      [class.text-selective-yellow]="
        (feedbackFormGroup.get('rating')?.value ?? 0) > 2
      "
      (click)="setRating(3)"></i>
    <i
      class="icon icon-star cursor-pointer"
      [class.text-selective-yellow]="
        (feedbackFormGroup.get('rating')?.value ?? 0) > 3
      "
      (click)="setRating(4)"></i>
    <i
      class="icon icon-star cursor-pointer"
      [class.text-selective-yellow]="
        (feedbackFormGroup.get('rating')?.value ?? 0) > 4
      "
      (click)="setRating(5)"></i>
  </div>

  <div class="form w-full" [formGroup]="feedbackFormGroup">
    <div class="form-group">
      <label for="message" class="text-left"
        >Mensagem
        <span class="font-normal text-cadet-blue">(Opcional)</span></label
      >
      <textarea
        name="message"
        id="message"
        rows="4"
        formControlName="text"
        placeholder="Deixe uma mensagem"></textarea>
    </div>
  </div>

  <div class="modal__controls">
    <button
      class="button border border-cadet-blue bg-white text-cadet-blue text-[1.4rem]"
      (click)="feedbackModal.close()">
      Cancelar
    </button>
    <button
      class="button bg-prairie-sand text-white text-[1.4rem]"
      (click)="sendFeedback()">
      Enviar
    </button>
  </div>
</app-confirmation-modal>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
