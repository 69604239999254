import {Component, ViewChild} from '@angular/core';
import {BudgetService} from "../../core/services/budget.service";
import {NgToastService} from "ng-angular-popup";
import {SessionManagerService} from "../../core/services/session-manager.service";
import {Router} from "@angular/router";
import {Account} from "../../core/models/account.model";
import {Budget} from "../../core/models/budget.model";
import {ConfirmationModalComponent} from "../../components/confirmation-modal/confirmation-modal.component";
import {Pagination} from "../../core/models/pagination.model";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
  @ViewChild('setNameModal') setNameModal!: ConfirmationModalComponent;
  @ViewChild('budgetRemovalModal') budgetRemovalModal!: ConfirmationModalComponent;
  @ViewChild('budgetNameInput') input!: any;

  loggedUser?: Account;
  fetchingBudgets = false;
  savingBudget = false;

  budgets: Budget[] = [];
  activeBudget?: Budget;

  selectedBudget?: Budget;
  response: Pagination<Budget> = {} as Pagination<Budget>;
  currentPage = 1;
  pageSize = 20;

  constructor(private budgetService: BudgetService, private toastService: NgToastService, private router: Router,
              private sessionManager: SessionManagerService) {
    this.loggedUser = sessionManager.accountData;
    if (!this.loggedUser) {
      this.toastService.error({detail: 'Você não tem permissão para visualizar esta página'});
      this.router.navigate(['/']);
    }
    this.fetchBudgets();
  }

  get loading() {
    return this.fetchingBudgets || this.savingBudget;
  }

  get pages(): number[] {
    let pageNumbers = [];
    let pages = Math.ceil(this.response.count / this.pageSize);
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  fetchBudgets() {
    this.fetchingBudgets = true;
    this.budgetService.list(this.currentPage).subscribe({
      next: (response) => {
        this.response = response;
        this.budgets = response.results;
        this.fetchingBudgets = false;
      },
      error: (error) => {
        this.toastService.error(error);
        this.fetchingBudgets = false;
      }
    })
  }

  openModalSetName(budget: Budget) {
    this.selectedBudget = budget;
    this.setNameModal.open();
  }

  setBudgetName() {
    this.setNameModal.close();
    this.savingBudget = true;
    this.selectedBudget!.name = this.input.nativeElement.value;
    this.budgetService.editName(this.selectedBudget!).subscribe({
      next: (response) => {
        this.savingBudget = false;
      },
      error: (error) => {
        this.toastService.error(error);
        this.savingBudget = false;
      }
    });
  }

  loadPage(page: number) {
    this.currentPage = page;
    this.fetchBudgets();
  }

  editBudget(budget: Budget) {
    let statusMapping: { [key: string]: string } = {
      'PROPERTY_TYPE': 'tipo-do-imovel',
      'ESTIMATED_PRICE': 'valor-estimado',
      'ROOMS': 'ambientes',
      'ROOMS_SETTINGS': 'configurar-ambientes',
      'SUMMARY': 'resumo',
      'MY_BUDGET': 'meu-orcamento',
    };
    this.router.navigate([`/d/orcamento/${budget.id}/${statusMapping[budget.step]}`]);
  }

  removeBudget(budget: Budget) {
    this.activeBudget = budget;
    this.budgetRemovalModal.open();
  }

  confirmBudgetRemoval() {
    this.budgetService.remove(this.activeBudget!).subscribe({
      next: () => {
        this.budgets = this.budgets.filter(_ => _ != this.activeBudget);
        this.budgetRemovalModal.close();
        this.toastService.success({detail: 'Orçamento removido com sucesso.'});
        this.activeBudget = undefined;
      }, error: error => {
        this.toastService.error({detail: 'Não foi possível remover este orçamento.'});
      }
    });
  }
}
