import { Architect } from './../../core/models/architect.model';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../core/services/account.service';
import { Account } from '../../core/models/account.model';
import { NgToastService } from 'ng-angular-popup';
import { SessionManagerService } from '../../core/services/session-manager.service';
import { BottomSheetComponent } from '../../components/bottom-sheet/bottom-sheet.component';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() appNavigation?: NavigationComponent;
  @Input() registerComponent?: BottomSheetComponent;
  @Input() loginComponent?: BottomSheetComponent;

  formGroup!: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastService: NgToastService,
    private sessionManager: SessionManagerService,
    private bottomSheet: BottomSheetComponent,
    private router: Router
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) return;
    let data: Account = this.formGroup.getRawValue();

    this.loading = true;

    this.accountService.login(data).subscribe({
      next: (response) => {
        this.loading = false;
        this.sessionManager.setSessionData(response);
        this.formGroup.reset();
        this.bottomSheet.close();

        if (this.sessionManager.isArchitect || this.sessionManager.isCustomer) {
          this.router.navigate(['/d/criar-orcamento/tipo-de-execucao']);
        }
      },
      error: (error) => {
        this.loading = false;
        if ('detail' in error.error) {
          this.toastService.error({
            detail: 'Erro!',
            summary: error.error.detail,
          });
        }
      },
    });
  }

  closeAndGoToRegister() {
    this.appNavigation?.loginComponent.close();
    this.loginComponent?.close();
    this.appNavigation?.registerComponent.open();
    this.registerComponent?.open();
  }
}
