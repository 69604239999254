import { Injectable } from '@angular/core';
import Snackbar from "awesome-snackbar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  success(message: string) {
    this.throwSnackbar(message, 'var(--green)', 'var(--base-100)')
  }

  info(message: string) {
    this.throwSnackbar(message, 'var(--yellow)', 'var(--base-100)');
  }

  danger(message: string) {
    this.throwSnackbar(message, 'var(--red)', 'var(--base-100)');
  }

  throwSnackbar(message: string, backgroundColor: string, color: string, position = 'bottom-center') {
    new Snackbar(message, {
      position: position,
      style: {
        container: [
          ['background-color', backgroundColor],
          ['color', color],
        ],
        message: [
          ['font-size', '1.4rem'],
          ['line-height', '1.2']
        ]
      }
    });
  }
}
