import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationComponent } from 'src/app/components/navigation/navigation.component';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { RegisterComponent } from '../../register/register.component';
import { BottomSheetComponent } from 'src/app/components/bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  @ViewChild('registerComponent') registerComponent!: BottomSheetComponent;

  currentTab = 1;

  selectedProduct = { title: '', image: '', description: '', background: '' };
  products = [
    {
      title: 'Materiais básicos',
      image: 'assets/images/materiais_basicos_mobile.png',
      background: 'assets/images/materiais_basicos_desk.png',
      description:
        'Cimento, areia, tijolos, fios, conduítes elétricos, encanamentos hidráulicos.',
    },
    {
      title: 'Acabamentos',
      image: 'assets/images/acabamentos_mobile.png',
      background: 'assets/images/acabamentos_desk.png',
      description:
        'Pisos e revestimentos (porcelanato, laminados, vinílicos, madeira), louças e metais.',
    },
    {
      title: 'Iluminação',
      image: 'assets/images/iluminacao_mobile.png',
      background: 'assets/images/iluminacao_desk.png',
      description: 'Luminárias, lustres, LEDs.',
    },
    {
      title: 'Marcenaria',
      image: 'assets/images/marcenaria_mobile.png',
      background: 'assets/images/marcenaria_desk.png',
      description: 'Móveis planejados e personalizados.',
    },
    {
      title: 'Marmoraria',
      image: 'assets/images/marmoraria_mobile.png',
      background: 'assets/images/marmoraria_desk.png',
      description: 'Bancadas, pias, revestimentos.',
    },
    {
      title: 'Caixilharia',
      image: 'assets/images/caixilharia_mobile.png',
      background: 'assets/images/caixilharia_desk.png',
      description: 'Portas, janelas.',
    },
    {
      title: 'Vidraçaria',
      image: 'assets/images/vidracaria_mobile.png',
      background: 'assets/images/vidracaria_desk.png',
      description: 'Boxes, espelhos, fechamentos de sacada.',
    },
    {
      title: 'Eletrodomésticos',
      image: 'assets/images/eletrodomesticos_mobile.png',
      background: 'assets/images/eletrodomesticos_desk.png',
      description: 'Geladeiras, fogões, televisores e etc..',
    },
    {
      title: 'Mobiliário',
      image: 'assets/images/mobiliario_mobile.png',
      background: 'assets/images/mobiliario_desk.png',
      description: 'Sofás, poltronas, mesas, cadeiras e etc...',
    },
  ];

  constructor(
    private sessionManager: SessionManagerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedProduct = this.products[0];
  }

  onSelectProduct(product: any, ev: any): void {
    this.selectedProduct = product;
    if (window.innerWidth < 1024) {
      setTimeout(() => window.scrollTo({ top: ev.target.offsetTop - 70 }), 300);
    }
  }

  loginOrStartBudget() {
    let account = this.sessionManager.getSessionData();
    if (!account) {
      this.registerComponent.open();
    } else {
      this.router.navigate(['/d/criar-orcamento/tipo-de-execucao']);
    }
  }

  isMenuOpened = false;

  toggleNavbar(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }
}
