import {Component, ElementRef, ViewChild} from '@angular/core';
import {CustomerReview} from "../../../models/customer-review.model";
import {ConfirmationModalComponent} from "../../components/confirmation-modal/confirmation-modal.component";
import {Router} from "@angular/router";
import {SessionManagerService} from "../../core/services/session-manager.service";
import {NavigationComponent} from "../../components/navigation/navigation.component";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
}
