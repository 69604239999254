import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Account} from "../../core/models/account.model";
import {AccountService} from "../../core/services/account.service";
import {NgToastService} from "ng-angular-popup";
import {SessionManagerService} from "../../core/services/session-manager.service";
import {BottomSheetComponent} from "../../components/bottom-sheet/bottom-sheet.component";

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent {
  companyFormGroup: FormGroup = this.fb.group({
    id: [null],
    name: ['', [Validators.required]],
    document_number: ['', [Validators.required]],
  });
  registerFormGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    company: this.companyFormGroup,
    phone_number: ['', [Validators.required, Validators.minLength(13)]],
    password: ['', [Validators.required]],
    confirm_password: ['', [Validators.required]],
    role: ['COMPANY', [Validators.required]]
  });

  loading = false;

  constructor(private fb: FormBuilder, private accountService: AccountService, private toastService: NgToastService,
              private sessionManager: SessionManagerService, private bottomSheet: BottomSheetComponent) {
  }

  checkPassword() {
    let passwordControl = this.registerFormGroup.get('password');
    let passwordConfirmControl = this.registerFormGroup.get('confirm_password');

    if (passwordConfirmControl?.value != passwordControl?.value)
      passwordConfirmControl?.setErrors({'differentPassword': true});
  }

  submit(): void {
    this.registerFormGroup.clearValidators();
    this.checkPassword();
    this.registerFormGroup.markAllAsTouched();
    if (this.registerFormGroup.invalid)
      return;

    let data: Account = this.registerFormGroup.getRawValue();
    this.loading = true;
    this.accountService.register(data).subscribe({
      next: (response) => {
        this.loading = false;
        this.sessionManager.setSessionData(response);
        this.registerFormGroup.reset();
        this.bottomSheet.close();
      },
      error: (error) => {
        this.loading = false;
        if ('detail' in error.error) {
          this.toastService.error({
            detail: 'Erro!',
            summary: error.error.detail,
          });
        } else {
          for (let key of Object.keys(error.error)) {
            this.registerFormGroup.get(key)?.setErrors({response: error.error[key]});
          }
        }
      }
    });
  }
}
