import { Component, OnInit } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';
import { ProductImportError } from 'src/app/core/models/product-import-error.model';
import { ProductImport } from 'src/app/core/models/product-import.model';
import { ProductOption } from 'src/app/core/models/product-option.model';
import { CompanyService } from 'src/app/core/services/company.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-import-products',
  templateUrl: './import-products.component.html',
  styleUrls: ['./import-products.component.scss'],
})
export class ImportProductsComponent implements OnInit {
  products: ProductOption[] = [];
  sendingFile: boolean = false;

  productImports: ProductImport[] = [];

  showHistory = false;

  constructor(
    private productsService: ProductsService,
    private companyService: CompanyService,
    private sessionManagerService: SessionManagerService,
    private toastService: NgToastService
  ) {}

  ngOnInit() {
    this.fetchCompanyProducts();
    this.fetchProductImports();
  }

  fetchCompanyProducts(): void {
    let account = this.sessionManagerService.getSessionData();
    if (account.company) {
      this.companyService.listCompanyProducts(account.company.id!).subscribe({
        next: (response) => {
          this.products = response;
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  fetchProductImports(): void {
    let account = this.sessionManagerService.getSessionData();
    if (account.company) {
      this.productsService.getImportErrors(account.company.id!).subscribe({
        next: (response) => {
          this.productImports = response;
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  csvFileSelected(ev: Event) {
    const target = ev.currentTarget as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      let file = target.files[0];
      this.sendFile(file);
      this.fetchProductImports();
      this.showHistory = true;
    }
  }

  sendFile(file: File): void {
    this.sendingFile = true;
    this.productsService.importCsv(file).subscribe({
      next: (response) => {
        this.sendingFile = false;
        this.toastService.info({
          detail: 'Aguarde',
          summary: 'Estamos importando seu arquivo',
        });
        let stringifiedStoredNotifications: string =
          localStorage.getItem('storedNotifications') ?? '[]';
        let storedNotifications: { id: number; status: string }[] = JSON.parse(
          stringifiedStoredNotifications
        );
        storedNotifications.push({ id: response['id'], status: '' });
        localStorage.setItem(
          'storedNotifications',
          JSON.stringify(storedNotifications)
        );
      },
      error: (error) => {
        this.sendingFile = false;
        console.error(error);
      },
    });
  }

  getProductImportStatus(status: string) {
    let mapping: { [key: string]: string } = {
      DONE_WITH_ERRORS: 'Concluído (com erros)',
      DONE: 'Concluído',
      ERROR: 'Erro',
      PROCESSING: 'Em andamento',
      AWAITING_PROCESSING: 'Na fila de importação',
    };
    if (status in mapping) return mapping[status];

    return '-';
  }

  showErrors(errors: ProductImportError[]) {
    console.log(errors);
    return '-';
  }
}
