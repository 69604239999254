<div class="budget rooms">
  <h2 class="budget__title">Ambientes</h2>

  <div class="budget__content" *ngIf="!loading; else loadingTemplate">
    <div class="box md:rounded-2xl md:ring-1 md:ring-athens-gray flex flex-col gap-10" *ngIf="!hasBudgetRooms else cantEditRoomsTemplate">
      <div class="flex justify-between items-center">
        <div class="tag">
          <i class="icon {{ getPropertyTypeIcon(budget.property_type) }}"></i>
          {{ getPropertyTypeName(budget.property_type) }}
        </div>
      </div>

      <div class="flex">
        <div class="rounded-2xl ring-1 ring-athens-gray w-full">
          <ng-container *ngFor="let room of rooms">
            <div class="flex items-center p-8 justify-between border-b border-athens-gray">
              <span class="font-semibold text-[2rem]">{{ room.name }}</span>

              <div class="flex text-raven text-[1.4rem] gap-5">
                <span class="icon icon-minus ringed-icon border-raven" [class]="{'cursor-pointer': room.quantity > 0}" (click)="remove(room)"></span>
                <span [class.text-woodsmoke]="room.quantity > 0" [class.font-bold]="room.quantity > 0">{{ room.quantity }}</span>
                <div class="icon icon-plus ringed-icon border-raven cursor-pointer" (click)="add(room)"></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <button class="button bg-prairie-sand text-white w-full" [disabled]="!shouldProceed()" [class.bg-schooner]="!shouldProceed()" (click)="onSubmit()">Avançar</button>
  </div>
</div>

<ng-template #cantEditRoomsTemplate>
  <p class="text-2xl text-left">Para adicionar ou remover ambientes clique em Avançar</p>
</ng-template>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
