<ng-container *ngIf="!loading; else loadingTemplate">
  <div class="profile">
    <div class="flex justify-end" *ngIf="sessionManagerService.isCompany">
      <button
        (click)="selectCsv()"
        class="button border border-prairie-sand text-prairie-sand font-semibold text-xl">
        Importar produtos
      </button>
    </div>
    <div class="form">
      <h2 class="text-3xl font-medium mb-5" *ngIf="account">Dados pessoais</h2>

      <div class="flex flex-col gap-8" *ngIf="account">
        <div
          class="flex flex-col gap-8 md:flex-row"
          [formGroup]="userFormGroup">
          <div class="form-group w-full">
            <label for="name">Nome completo</label>
            <input type="text" id="name" formControlName="name" />
            <app-input-error
              [fg]="userFormGroup"
              controlName="name"></app-input-error>
          </div>

          <div class="form-group w-full">
            <label for="email">E-mail</label>
            <input type="email" id="email" formControlName="email" />
            <app-input-error
              [fg]="userFormGroup"
              controlName="email"></app-input-error>
          </div>

          <div class="form-group w-full">
            <label for="phone_number">Telefone</label>
            <input
              type="text"
              id="phone_number"
              mask="+00 00 00000-0000"
              placeholder="+00 00 00000-0000"
              formControlName="phone_number" />
          </div>
        </div>
      </div>

      <ng-container *ngIf="account?.architect">
        <h2 class="text-3xl font-medium mt-10 mb-5">Dados da empresa</h2>

        <div class="flex gap-8 flex-col" [formGroup]="architectFormGroup">
          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="form-group w-full">
              <label for="">Razão social</label>
              <input type="text" formControlName="name" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="name"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">CNPJ</label>
              <input
                type="text"
                formControlName="document_number"
                mask="00.000.000/0000-00" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="document_number"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">E-mail</label>
              <input type="text" formControlName="email" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="email"></app-input-error>
            </div>
          </div>

          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="flex flex-row w-full gap-2">
              <div class="form-group w-4/12">
                <label for="">DDI</label>
                <input type="text" formControlName="country_code" mask="+0*" />
                <app-input-error
                  [fg]="architectFormGroup"
                  controlName="country_code"></app-input-error>
              </div>

              <div class="form-group w-full">
                <label for="">Telefone</label>
                <input
                  type="text"
                  formControlName="phone_number"
                  mask="(00) 0000-0000||(00) 00000-0000" />
                <app-input-error
                  [fg]="architectFormGroup"
                  controlName="phone_number"></app-input-error>
              </div>
            </div>

            <div class="form-group w-full">
              <label for="">Instagram</label>
              <input type="text" formControlName="instagram" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="instagram"></app-input-error>
            </div>
          </div>

          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="form-group w-full">
              <label for="">Tiktok</label>
              <input type="text" formControlName="tiktok" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="tiktok"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">Facebook</label>
              <input type="text" formControlName="facebook" />
              <app-input-error
                [fg]="architectFormGroup"
                controlName="facebook"></app-input-error>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="company">
        <h2 class="text-3xl font-medium mt-10 mb-5">Dados da empresa</h2>

        <div class="flex gap-8 flex-col" [formGroup]="companyFormGroup">
          <div class="flex flex-col md:flex-row items-center gap-8">
            <div
              class="profile-image w-full md:w-2/12"
              (click)="fileInput.click()">
              <input type="file" class="hidden" />
              <img
                *ngIf="company.profile_image"
                [src]="company.profile_image"
                alt="" />
              <img
                *ngIf="!company.profile_image"
                class="p-5 opacity-40"
                src="assets/images/icon.svg"
                alt="" />
            </div>

            <input
              #fileInput
              (change)="uploadImage($event)"
              accept="image/*"
              type="file"
              class="w-full file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-xl file:bg-prairie-sand file:text-white hover:file:cursor-pointer" />
          </div>

          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="form-group w-full">
              <label for="">Razão social</label>
              <input type="text" formControlName="name" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="name"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">CNPJ</label>
              <input
                type="text"
                formControlName="document_number"
                mask="00.000.000/0000-00" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="document_number"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">E-mail</label>
              <input type="text" formControlName="email" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="email"></app-input-error>
            </div>
          </div>

          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="flex flex-row w-full gap-2">
              <div class="form-group w-4/12">
                <label for="">DDI</label>
                <input type="text" formControlName="country_code" mask="+0*" />
                <app-input-error
                  [fg]="companyFormGroup"
                  controlName="country_code"></app-input-error>
              </div>

              <div class="form-group w-full">
                <label for="">Telefone</label>
                <input
                  type="text"
                  formControlName="phone_number"
                  mask="(00) 0000-0000||(00) 00000-0000" />
                <app-input-error
                  [fg]="companyFormGroup"
                  controlName="phone_number"></app-input-error>
              </div>
            </div>

            <div class="form-group w-full">
              <label for="">Categoria da loja</label>

              <div class="flex flex-col md:flex-row h-full">
                <label
                  for="store_categories_ecommerce"
                  class="flex items-center gap-2 w-full">
                  <input
                    class="!w-min"
                    type="checkbox"
                    id="store_categories_ecommerce"
                    value="E_COMMERCE"
                    (change)="onSelectCheckbox($event)"
                    [checked]="
                      isCheckboxSelected(
                        companyFormGroup,
                        'store_categories',
                        'E_COMMERCE'
                      )
                    " />
                  <span>E-commerce</span>
                </label>

                <label
                  for="store_categories_brick_and_mortar"
                  class="flex items-center gap-2 w-full">
                  <input
                    class="!w-min"
                    type="checkbox"
                    id="store_categories_brick_and_mortar"
                    value="BRICK_AND_MORTAR_STORE"
                    (change)="onSelectCheckbox($event)"
                    [checked]="
                      isCheckboxSelected(
                        companyFormGroup,
                        'store_categories',
                        'BRICK_AND_MORTAR_STORE'
                      )
                    " />
                  <span>Loja física</span>
                </label>
              </div>
            </div>

            <div class="form-group w-full">
              <label for="">Site</label>
              <input type="text" formControlName="site" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="site"></app-input-error>
            </div>
          </div>

          <div class="flex flex-col gap-6 md:flex-row w-full">
            <div class="form-group w-full">
              <label for="">Instagram</label>
              <input type="text" formControlName="instagram" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="instagram"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">Tiktok</label>
              <input type="text" formControlName="tiktok" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="tiktok"></app-input-error>
            </div>

            <div class="form-group w-full">
              <label for="">Facebook</label>
              <input type="text" formControlName="facebook" />
              <app-input-error
                [fg]="companyFormGroup"
                controlName="facebook"></app-input-error>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="flex gap-8 justify-end mt-10">
        <!--<button class="button border border-prairie-sand text-prairie-sand">Cancelar</button>-->
        <button class="button bg-prairie-sand text-white" (click)="submit()">
          Salvar
        </button>
      </div>

      <!--    <h2 class="text-3xl font-medium mt-10 mb-5">Configurações</h2>-->
      <!--    <h3 class="text-2xl font-normal mb-5 text-raven">Preencha todos os dados abaixo para podermos filtrar melhor os seus leads</h3>-->
    </div>
  </div>

  <div class="h-24"></div>
</ng-container>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>
