<div class="container mx-auto pt-10 p-[3.6rem]">
  <h2 class="text-3xl font-semibold">Importar produtos</h2>

  <p class="text-2xl text-raven pt-3">
    Para importar produtos basta clicar no botão "Importar" e selecionar seu
    arquivo csv.<br />
    É obrigatório que o seu arquivo tenha os campos exatamente como estão
    escritos a seguir: sku, nome_do_produto, categoria, imagem_url,
    link_do_produto e preco.
  </p>

  <div class="flex flex-col mt-8">
    <div class="flex justify-between">
      <input
        type="file"
        class="hidden"
        accept="text/csv"
        (change)="csvFileSelected($event)"
        #csvFile />
      <button
        (click)="csvFile.click()"
        class="button border border-prairie-sand text-prairie-sand text-xl font-medium">
        Importar
      </button>

      <button
        (click)="showHistory = !showHistory"
        class="button border border-raven text-xl font-medium"
        [class]="{
          'bg-raven': showHistory,
          'text-white': showHistory,
          'bg-white': !showHistory,
          'text-raven': !showHistory
        }">
        Histórico
      </button>
    </div>

    <table class="table" *ngIf="showHistory">
      <thead>
        <tr>
          <th>#</th>
          <th>Status</th>
          <th>Erros</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let productImport of productImports">
          <td>{{ productImport.id }}</td>
          <td>{{ getProductImportStatus(productImport.status) }}</td>
          <td>
            <p *ngIf="productImport.errors.length == 0">-</p>
            <p *ngFor="let error of productImport.errors">
              {{ error.error }}
            </p>
            <!-- {{ showErrors(productImport.errors) }} -->
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table" *ngIf="!showHistory">
      <thead>
        <tr>
          <th></th>
          <th>SKU</th>
          <th>Produto</th>
          <th>Categoria</th>
          <th>Valor</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let product of products">
          <td><img [src]="product.file_url" /></td>
          <td>{{ product.sku }}</td>
          <td>{{ product.name }}</td>
          <td>{{ product.product__name }}</td>
          <td>{{ product.value | currency : "R$" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
