<app-navigation #appNavigation></app-navigation>

<div class="max-w-full relative bg-prairie-sand flex justify-center overflow-hidden">
  <div class="container header ">
    <h2>Orçamento fácil para o seu produto ou serviço, arquitetos e clientes qualificados</h2>

    <div class="flex flex-col md:flex-row-reverse md:justify-end gap-6">
      <button class="button bg-white text-prairie-sand" (click)="loginOrStartBudget()">
        Comece um orçamento
      </button>
    </div>

    <img class="absolute left-0 top-2 hidden md:block" src="assets/images/background_header_biglogo_left.png" alt="bglogo">
    <img class="absolute right-0 top-0 hidden md:block" src="assets/images/background_header_biglogo_right.png" alt="bglogo">
  </div>
</div>

<div class="how-works" id="como-funciona">
  <h2>Como funciona</h2>

  <div class="explanation-container">
    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Preencha os dados da sua obra</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Detalhe os ambientes e as execuções</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Escolha os produtos</span>
    </div>

    <div class="box">
      <i class="icon icon-check-rounded"></i>

      <span>Pronto! Orçamento realizado</span>
    </div>
  </div>
</div>

<div class="max-w-full bg-prairie-sand" id="o-que-inclui">
  <div class="container mx-auto text-center functionalities flex flex-col gap-[4rem]">
    <h3>O que está incluso no orçamento dos arquitetos e seus clientes? </h3>

    <div class="tabs" #tabs>
      <div class="items" [class.show]="currentTab == 1">
        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Mão de obra e material básico</h6>
          <p>
            De acordo com os detalhes dos seus ambientes, tamanhos e produtos escolhidos, te oferecemos a melhor
            estimativa para realizar sua obra
          </p>
        </div>

        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Equipe de Engenharia</h6>
          <p>
            Temos suporte de equipe de engenharia experiente no mercado e os orçamentos são baseados em valores reais
          </p>
        </div>

        <div class="item">
          <i class="icon icon-check-rounded"></i>
          <h6>Acesso aos Fornecedores</h6>
          <p>
            Te conectamos com os melhores fornecedores para realizar suas necessidades
          </p>
        </div>
      </div>
    </div>

    <div class="justify-center">
      <button class="button bg-white text-prairie-sand" (click)="loginOrStartBudget()">
        Comece um orçamento
      </button>
    </div>
  </div>
</div>

<div class="oportunity-container" id="oportunidade">
  <div class="oportunity-text archtect">
    <h3>Oportunidade para arquitetos</h3>

    <p>
      Encontre clientes que procuram por serviços de arquitetura na sua região
    </p>

    <a target="_blank" href="https://wa.me/5511948182998?text=Ol%C3%A1%2C%20tenho%20interesse%20em%20saber%20mais%20sobre%20os%20planos%20para%20arquiteto.">Contratar um plano <i class="icon icon-whatsapp"></i></a>
  </div>

  <div class="oportunity-text company">
    <h3>Oportunidade para fornecedores</h3>

    <p>
      Oferte produtos e serviços para clientes selecionados
    </p>

    <a target="_blank" href="https://wa.me/5511948182998?text=Ol%C3%A1%2C%20tenho%20interesse%20em%20saber%20mais%20sobre%20contrato%20para%20fornecedores.">Contratar um plano <i class="icon icon-whatsapp"></i></a>

  </div>
</div>

<div class="max-w-[153.6rem] lg:pt-[4.8rem] mx-auto bg-alabaster" id="quem-somos">
  <div class="flex flex-col md:w-[50%] py-[2.4rem] px-[1.6rem] md:px-[3.2rem]">
    <h2>Quem somos</h2>
    <h3>Sobre nós</h3>
    <p>
      Fundada por Bruno Mena Barreto, nossa missão é fornecer uma solução
      completa, rápida e conveniente para todas as necessidades de reforma,
      nos tornando um marketplace que conecta clientes a produtos e serviços
      de fornecedores confiáveis em sua região. Seja uma pequena loja de
      materiais de construção, ou uma marcenaria de alto padrão, nós te
      conectamos com o fornecedor ideal para o seu bolso!
    </p>
  </div>

  <div class="team">
    <div class="member">
      <img src="/assets/images/bruno_mena.jpeg" alt="Bruno Mena" />

      <div class="info-box">
        <h4>Bruno Mena</h4>
        <p class="job">CEO Fundador</p>
        <p class="founder-comment">
          “A ORÇAMENTARIA nasceu da necessidade de simplificar e transformar a
          maneira de orçar reformas na construção civil em todo Brasil.’’
        </p>

        <div class="flex">
          <a target="_blank" href="https://www.linkedin.com/in/bruno-mena-barreto-42b468138/">
            <i class="icon-linkedin"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="member">
      <img src="/assets/images/gustavo_abrell.jpeg" alt="Gustavo Abrell" />

      <div class="info-box">
        <h4>Gustavo Abrell</h4>
        <p class="job">Sócio Diretor</p>
        <p class="founder-comment"></p>

        <div class="flex">
          <a target="_blank" href="https://www.linkedin.com/in/gustavoabrell/">
            <i class="icon-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirmation-modal #demoVideoModal>
  <video src="https://orcamentaria-cdn.s3.us-east-2.amazonaws.com/demo-ai.mp4" controls></video>
</app-confirmation-modal>

<app-confirmation-modal #modal>
  <h4 class="modal__title mb-5">
    <img src="assets/images/logo.svg" alt="Orçamentaria" />
  </h4>

  <p class="modal__content text-left">
    O jeito rápido e prático de obter seu orçamento em mãos.
  </p>
  <p class="modal__content text-left">
    Chega de ficar dias ou semanas atrás daquele orçamento ou estimativa de
    custo para sua reforma.
  </p>
  <p class="modal__content text-left">
    Do estimado ao detalhado, aqui você encontra valores reais ao de mercado em
    minutos, de forma descomplicada e dinâmica.
  </p>
  <p class="modal__content text-left">
    Preencha o formulário com os dados de seu projeto e tenha em mãos o arquivo
    necessário para planejar sua obra.
  </p>
</app-confirmation-modal>
